import axios from 'axios'
import {
  getProfessions,
  getMaritalStatus,
  addClient,
  getPublicAreas,
  addClientImage,
  updateClientRoute
} from '../services/requests'

export const getInitialClientData = async () => {
  let data
  try {
    let promises = await axios.all([getProfessions(), getMaritalStatus(), getPublicAreas()])

    data = {
      professions: promises[0].data,
      marital_status_options: promises[1].data,
      public_area_options: promises[2].data
    }
  } catch (err) {
    console.log(err.response)
  }
  return data
}

export const addNewClient = async state => {
  let obj = { ...state }

  delete obj.currentStep
  delete obj.professions
  delete obj.marital_status_options
  delete obj.public_area_options
  delete obj.updated_photo
  delete obj.id
  delete obj.photo
  delete obj.cpf_incorrect
  delete obj.cpf_duplicated
  delete obj.cpf_complete
  delete obj.cnpj_incorrect
  delete obj.cnpj_duplicated
  delete obj.cnpj_complete
  delete obj.districts
  delete obj.phone_types

  obj = {
    ...obj,
    birth_date: `${state.birth_date.substr(6, 4)}-${state.birth_date.substr(3,2)}-${state.birth_date.substr(0, 2)}`,
    district: state.districts.find(elem => elem.id === state.district).name
  }

  if (obj.nickname === '') {
    let name = obj.name.split(' ')
    name.length === 1 
    ? obj.nickname = name[0].slice(0, 3) + Math.random().toString().slice(2, 6)
    : obj.nickname = obj.nickname = name[0].slice(0, 3) + name[1].slice(0, 3) + Math.random().toString().slice(2, 6)
  }

  try {
    let res = await addClient(obj)
    if (state.photo) {
      state.photo.append('client_id', res.data)
      await addClientImage(state.photo)
    }
    return {
      status: true, 
      error: ''
    }
  } catch (error) {
    let error_message = 'Erro ao cadastrar imóvel!'
    if(error.response.status === 409)
      error_message = 'Já existe um cliente com o mesmo apelido! Modifique-o e tente novamente'
    return {
      status: false, 
      error: error_message
    }
  }
}

export const updateClient = async state => {
  let obj = { ...state }

  delete obj.currentStep
  delete obj.professions
  delete obj.marital_status_options
  delete obj.public_area_options
  delete obj.updated_photo
  delete obj.id
  delete obj.photo
  delete obj.cpf_incorrect
  delete obj.cpf_duplicated
  delete obj.cpf_complete
  delete obj.cnpj_incorrect
  delete obj.cnpj_duplicated
  delete obj.cnpj_complete
  delete obj.nickname
  delete obj.password
  delete obj.districts
  delete obj.phone_types

  obj = {
    ...obj,
    birth_date: `${state.birth_date.substr(6, 4)}-${state.birth_date.substr(3,2)}-${state.birth_date.substr(0, 2)}`,
    district: state.districts.find(elem => elem.id === state.district).name
  }

  try {
    let res = await updateClientRoute(obj, state.id)
    if (state.photo && state.updated_photo) {
      state.photo.append(state.id, res.data)
      await addClientImage(state.photo)
    }
    return true
  } catch (error) {
    console.log(error)
    return false
  }
}

export const ClientState = {
  currentStep: 1,
  id: null,

  //Basic
  name: '',
  nickname: '',
  is_legal_person: false,
  cpf_cnpj: '',
  rg_ie: '',
  birth_date: '',
  gender: null,
  professions_id: null,
  marital_status_list_id: null,
  spouse: '',
  email: '',
  whatsapp: '',
  phones: [],
  nationality: '',
  status: true,
  is_problematic: false,
  password: '55555555',

  //Address
  cep: '',
  city: '',
  district: null,
  address: '',
  complement: '',
  state: '',
  number: '',
  public_areas_id: null,

  references: '',
  photo: null,
  income: '',

  employee_id: 9999,

  //Options
  public_area_options: null,
  professions: null,
  marital_status_options: null,
  updated_photo: false,
  districts: null,
  phone_types: [
    'Telefone Pessoal',
    'Telefone Comercial',
    'Celular Pessoal',
    'Celular Comercial'
  ],

  //Field Control
  cpf_incorrect: false,
  cpf_duplicated: false,
  cpf_complete: false,
  cnpj_incorrect: false,
  cnpj_duplicated: false,
  cnpj_complete: false
}
