export const handleSelection = (name, selection, dispatch) =>
  dispatch({ type: 'set_data', fieldName: name, payload: selection })

export const handleEvent = (event, dispatch) =>
  dispatch({ type: 'set_data', fieldName: event.target.name, payload: event.target.value })

export const handleCheckBox = (name, dispatch) => {
  dispatch({ type: 'checkbox', fieldName: name })
}

export const handleNumber = (event, dispatch) =>
  dispatch({ type: 'numeric_input', fieldName: event.target.name, payload: event.target.value })

export const clearField = (name, dispatch, type) => {
  type === 1
    ? dispatch({ type: 'clear', fieldName: name })
    : dispatch({ type: 'clear_select', fieldName: name })
}

/**
 * Check if all fields are filled. Return true if there is an error on any field and false if everything is ok
 *
 * @param {object} state object with all fields state.
 * @param {array} fields list of fields to be checked (array of strings).
 * @param {function} callback callback function executed if all fields are filled.
 */
export const fieldErrorCheck = (state, fields) => {
  let error = false

  if (fields.length !== 0)
    fields.forEach(field => {
      if (state[field] === '' || state[field] === null) {
        error = true
      }
    })

  return error
}
