import moment from 'moment'

export const helper = (data, dispatch) => {
  dispatch({
    type: 'store_data',
    payload: {
      id_property: data.id,
      property_types_id: data.property_types_id,
      featured: data.featured,
      is_publishable: data.is_publishable,
      status: data.status,
      bedrooms: data.bedrooms,
      suites: data.suites,
      bathrooms: data.bathrooms,
      parking_space: data.parking_space,
      is_furnished: data.is_furnished,
      solar_position: data.solar_position,
      building_area: data.building_area,
      land_area: data.land_area,
      front_area: data.front_area,
      back_area: data.back_area,
      field_orientation: data.field_orientation,
      description: data.description,

      cep: data.cep,
      state: data.district.city.state.name,
      city: data.district.city.name,
      district_id: data.district.id,
      address: data.address,
      number: data.number,
      complement: data.complement,
      public_areas_id: data.public_areas_id,
      latitude: data.latitude,
      longitude: data.longitude,

      owner_id: data.client.id,
      condominiums_id: data.condominiums_id,
      listing_broker_id: data.listing_broker,
      responsable_employee_id: data.employees_id,
      is_exclusive: data.is_exclusive,
      has_board: data.has_board,
      favorite_image: data.images.length ? data.images.some(elem => !!elem.is_favorite) : false,
      expired: moment(data.expiration_date).isBefore(moment().add(1, 'days')),
      videos: data.videos
    }
  })
}
