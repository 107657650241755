import React, { useEffect, useState } from 'react'
import { ContainerFlex, BreadCrumb, Title, Button, Table, RegularButton } from '../../../components'
import { RiArrowDropRightLine } from 'react-icons/ri'
import { getEmployees } from '../../../services/requests'
import { useHistory } from 'react-router-dom'

export const Employees = () => {
  const [data, setData] = useState(null)
  const [status, setStatus] = useState(false)
  let navigate = useHistory()

  const getData = async () => {
    try {
      let res = await getEmployees()
      setData(res.data)
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    getData()
  }, [])

  return (
    <ContainerFlex margin='20px 0 25px' gap='20px' direction='column'>
      <BreadCrumb>
        <label>Gerenciar Funcionários</label>
        <RiArrowDropRightLine />
      </BreadCrumb>

      <Title>Funcionários Cadastrados</Title>
      <ContainerFlex margin='15px 0' gap='20px' align='center'>
        <Button onClick={() => navigate.push('/admin/funcionarios/novo')}>Novo Funcionário</Button>
        <RegularButton onClick={() => setStatus(!status)}>
          Mostrar {status ? 'A' : 'Ina'}tivos
        </RegularButton>
      </ContainerFlex>

      <ContainerFlex>
        <Table>
          <thead>
            <tr>
              <th>ID</th>
              <th>Nome</th>
              <th>Nickname</th>
              <th>Nível de Acesso</th>
              <th>Tipo</th>
            </tr>
          </thead>
          <tbody>
            {data &&
              data
                .filter(employee => !employee.status === status)
                .map(employee => {
                  return (
                    <tr
                      key={employee.id}
                      onClick={() => navigate.push(`/admin/funcionario/${employee.id}`)}
                    >
                      <td>{employee.id}</td>
                      <td>{employee.name}</td>
                      <td>{employee.nickname}</td>
                      <td>{employee.accessLevel.id}</td>
                      <td>{employee.accessLevel.type}</td>
                    </tr>
                  )
                })}
          </tbody>
        </Table>
      </ContainerFlex>
    </ContainerFlex>
  )
}
