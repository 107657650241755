import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router'
import axios from 'axios'
import { RiArrowDropRightLine } from 'react-icons/ri'
import {
  BreadCrumb,
  Title,
  ContainerFlexResponsive,
  Table,
  BreadCrumbLabel,
  BreadCrumbLink,
  RegularButton,
  Loader,
  ContainerFlex,
  Pagination
} from '../../components'
import { getChangesHistory, getEmployees } from '../../services/requests'
import moment from 'moment'

export const PropertyChanges = props => {
  let { id } = useParams()

  const [history, setHistory] = useState(null)
  const [employees, setEmployees] = useState(null)
  const [details, setDetails] = useState(null)
  const [loading, setLoading] = useState(true)

  const page_items = 30
  const [page, setPage] = useState(0)
  const [total_pages, setTotalPages] = useState(0)

  const initialData = async () => {
    try {
      let promises = await axios.all([getChangesHistory(id, { page_items, page }), getEmployees()])
      setHistory(promises[0].data.history)
      setTotalPages(promises[0].data.total_pages)
      setEmployees(promises[1].data)
      setLoading(false)
    } catch (e) {
      alert('Erro ao carregar os dados')
      console.log(e)
    }
  }

  const handleChangePage = async type => {
    setLoading(true)
    setHistory(null)

    if (type === 'next') {
      if (page !== total_pages) {
        setPage(page + 1)
      }
    } else if (type === 'prev') {
      if (page !== 0) {
        setPage(page - 1)
      }
    } else {
      setPage(type)
    }

    try {
      let prop_changes = await getChangesHistory(id, { page_items, page })
      setHistory(prop_changes.data.history)
      setLoading(false)
    } catch (e) {
      alert('Erro ao carregar os dados')
      console.log(e)
    }
  }

  const correctInput = (input, key) => {
    if (typeof input === 'boolean') {
      return input ? 'Sim' : 'Não'
    }

    if (input === null) {
      return '-'
    }

    if (key === 'employee_id' || key === 'responsable_employee_id' || key === 'listing_broker_id') {
      return employees.find(emp => emp.id === input).name
    }

    return input
  }

  useEffect(() => {
    initialData()
    // eslint-disable-next-line
  }, [id])

  return (
    <ContainerFlexResponsive margin='20px 0' direction='column'>
      <BreadCrumb>
        <BreadCrumbLink to={`/imovel/${id}`}>Imóvel {id}</BreadCrumbLink>
        <RiArrowDropRightLine />
        <BreadCrumbLabel>Alterações</BreadCrumbLabel>
      </BreadCrumb>

      <Title margin='20px 0 10px'>Histórico de Alterações</Title>

      {history && employees && !details && history.length !== 0 && (
        <ContainerFlexResponsive direction='column' width='100%' margin='0 auto'>
          <Table>
            <thead>
              <tr>
                <th>Alterado Por</th>
                <th>Data de Alteração</th>
              </tr>
            </thead>
            <tbody>
              {history.map(item => {
                return (
                  <tr key={item.id} onClick={() => setDetails(parseData(item))}>
                    <td>{employees.find(emp => emp.id === item.altered_by).name}</td>
                    <td>{moment(item.altered_at).format('DD/MM/YYYY')}</td>
                  </tr>
                )
              })}
            </tbody>
          </Table>
        </ContainerFlexResponsive>
      )}

      {details && (
        <ContainerFlexResponsive direction='column' width='100%' margin='0 auto'>
          <RegularButton style={{ margin: '10px 0' }} onClick={() => setDetails(null)}>
            Voltar
          </RegularButton>
          <Table>
            <thead>
              <tr>
                <th style={{ textAlign: 'center' }}>Campos</th>
                <th style={{ textAlign: 'center' }}>Dados Anteriores</th>
                <th style={{ textAlign: 'center' }}>Alterações</th>
              </tr>
            </thead>

            <tbody>
              {Object.keys(details[1]).map(key => {
                if (!Array.isArray(details[1][key])) {
                  return (
                    <tr key={key}>
                      <th>{dataTranslation[key]}</th>
                      <td style={{ textAlign: 'center' }}>
                        {details[0] ? correctInput(details[0][key], key) : '-'}
                      </td>
                      <td style={{ textAlign: 'center' }}>{correctInput(details[1][key], key)}</td>
                    </tr>
                  )
                } else return null
              })}
            </tbody>
          </Table>
        </ContainerFlexResponsive>
      )}

      {!loading && history && history.length !== 0 && (
        <ContainerFlex margin='20px auto' width='100%' justify='center'>
          <Pagination
            pageCount={total_pages}
            handleChangePage={handleChangePage}
            currentPage={page}
          />
        </ContainerFlex>
      )}

      {loading && <Loader margin='20vh auto' />}

      {!loading && history && history.length === 0 && (
        <p>Não há registro de alterações nesse imóvel</p>
      )}
    </ContainerFlexResponsive>
  )
}

const correctPrice = data => {
  let res = { ...data }

  if (res.prices !== undefined) {
    res = {
      ...res,
      sale: res.prices[0].is_active,
      sale_price: res.prices[0].price,
      rent: res.prices[1].is_active,
      rent_price: res.prices[1].price,
      season: res.prices[2].is_active,
      season_price: res.prices[2].price
    }
    delete res.prices
  }

  return res
}

const parseData = data => {
  let fields = data.fields ? JSON.parse(data.fields) : null
  let alterations = JSON.parse(data.alterations)

  alterations = correctPrice(alterations)

  if (fields) {
    fields = correctPrice(fields)
  }

  return [fields, alterations]
}

const dataTranslation = {
  id_property: 'ID',
  employee_id: 'Criado por',
  property_types_id: 'Tipo',
  featured: 'Destaque',
  is_publishable: 'Publicado',
  status: 'Status',
  bedrooms: 'Quartos',
  suites: 'Suítes',
  bathrooms: 'Banheiros',
  parking_space: 'Vagas de Garagem',
  is_furnished: 'Mobiliado',
  solar_position: 'Posição Solar',
  building_area: 'Área Construída',
  land_area: 'Área do Terreno',
  front_area: 'Área da Frente',
  back_area: 'Área do Fundo',
  field_orientation: 'Orientação do Terreno',
  description: 'Descrição',
  cep: 'CEP',
  state: 'Estado',
  city: 'Cidade',
  district_id: 'Bairro',
  address: 'Endereço',
  number: 'Número',
  complement: 'Complemento',
  public_areas_id: 'Área Pública',
  latitude: 'Latitude',
  longitude: 'Longitude',
  condominium_fee: 'Taxa de Condomínio',
  iptu: 'IPTU',
  cleaning_fee: 'Taxa de Limpeza',
  is_financeable: 'Financiável',
  accepts_exchange: 'Aceita Permuta',
  fire_insurance: 'Seguro Incêndio',
  owner_id: 'Proprietário',
  condominiums_id: 'Condomínio',
  listing_broker_id: 'Captador',
  responsable_employee_id: 'Corretor Responsável',
  is_exclusive: 'Exclusividade',
  has_board: 'Placa',
  sale: 'Para Venda',
  rent: 'Para Locação',
  season: 'Para Temporada',
  sale_price: 'Preço de Venda',
  rent_price: 'Preço de Locação',
  season_price: 'Preço de Temporada',
  references: 'Referencias',
  renew_observation: 'Observações da Renovação',
  expiration_date: 'Data de Vencimento'
}
