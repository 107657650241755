import React, { useState } from 'react'
import { optionsInput } from '../../../utils/optionsInput'
import {
  handleSelection,
  handleEvent,
  handleCheckBox,
  clearField,
  fieldErrorCheck
} from '../handleFunctions'
import {
  Title,
  Description,
  ContainerFlexResponsive,
  SelectInput,
  SelectInputValue,
  Button,
  Checkbox,
  Textbox,
  TextInput
} from '../../../components'

export const PropertyBasics = ({ actions, state, dispatch }) => {
  const [errorCheck, setErrorCheck] = useState(false)

  return (
    <>
      <Title>Informações Gerais</Title>
      <Description>Informações iniciais sobre o imóvel</Description>
      <ContainerFlexResponsive gap='50px' margin='0 0 45px' resp_direction='column'>
        <SelectInputValue
          text='Tipo de Imóvel *'
          name='property_types_id'
          value={state.property_types_id}
          options={state.property_types}
          size='small'
          handleSelection={(name, selection) => handleSelection(name, selection, dispatch)}
          clear={name => clearField(name, dispatch, 2)}
          error={errorCheck ? state.property_types_id === null : false}
        />
        <Checkbox
          text='Destaque'
          name='featured'
          handleChange={name => handleCheckBox(name, dispatch)}
          state={state.featured}
        />
        <Checkbox
          text='Aparecer no Site'
          name='is_publishable'
          handleChange={name => handleCheckBox(name, dispatch)}
          state={state.is_publishable}
        />

        <SelectInput
          text='Situação do Imóvel'
          name='status'
          value={state.status}
          options={[
            'Ativo',
            'Inativo',
            'Vendido',
            'Tempo Expirado',
            'Limpeza',
            'Manutenção',
            'Locado'
          ]}
          size='small'
          handleSelection={(name, selection) => handleSelection(name, selection, dispatch)}
          clear={name => clearField(name, dispatch, 2)}
          error={errorCheck ? state.status === null : false}
        />

        <SelectInputValue
          text='Quartos *'
          name='bedrooms'
          value={state.bedrooms}
          options={optionsInput.quantity}
          size='small'
          handleSelection={(name, selection) => handleSelection(name, selection, dispatch)}
          clear={name => clearField(name, dispatch, 2)}
          error={errorCheck ? state.bedrooms === null : false}
        />
        <SelectInputValue
          text='Suítes *'
          name='suites'
          value={state.suites}
          options={optionsInput.quantity}
          size='small'
          handleSelection={(name, selection) => handleSelection(name, selection, dispatch)}
          clear={name => clearField(name, dispatch, 2)}
          error={errorCheck ? state.suites === null : false}
        />
        <SelectInputValue
          text='Banheiros *'
          name='bathrooms'
          value={state.bathrooms}
          options={optionsInput.quantity}
          size='small'
          handleSelection={(name, selection) => handleSelection(name, selection, dispatch)}
          clear={name => clearField(name, dispatch, 2)}
          error={errorCheck ? state.bathrooms === null : false}
        />

        <SelectInputValue
          text='Vagas de Garagem *'
          name='parking_space'
          value={state.parking_space}
          options={optionsInput.quantity}
          size='small'
          handleSelection={(name, selection) => handleSelection(name, selection, dispatch)}
          clear={name => clearField(name, dispatch, 2)}
          error={errorCheck ? state.parking_space === null : false}
        />

        <SelectInput
          text='Posição'
          name='field_orientation'
          value={state.field_orientation}
          options={optionsInput.field_orientation_options}
          size='small'
          handleSelection={(name, selection) => handleSelection(name, selection, dispatch)}
          clear={name => clearField(name, dispatch, 2)}
        />

        <SelectInput
          text='Posição Solar'
          name='solar_position'
          value={state.solar_position}
          options={optionsInput.solarPosition}
          size='small'
          handleSelection={(name, selection) => handleSelection(name, selection, dispatch)}
          clear={name => clearField(name, dispatch, 2)}
        />
        <TextInput
          text='Área Construída (m²) *'
          name='building_area'
          type='number'
          value={state.building_area}
          handleChange={event => handleEvent(event, dispatch)}
          clear={name => clearField(name, dispatch, 1)}
          error={errorCheck ? state.building_area === '' : false}
        />
        <TextInput
          text='Área Total (m²) *'
          name='land_area'
          type='number'
          value={state.land_area}
          handleChange={event => handleEvent(event, dispatch)}
          clear={name => clearField(name, dispatch, 1)}
          error={errorCheck ? state.land_area === '' : false}
        />

        <TextInput
          text='Área Terreno Frente (m²)'
          name='front_area'
          type='number'
          value={state.front_area}
          handleChange={event => handleEvent(event, dispatch)}
          clear={name => clearField(name, dispatch, 1)}
          error={false}
        />

        <TextInput
          text='Área Terreno Fundos (m²)'
          name='back_area'
          type='number'
          value={state.back_area}
          handleChange={event => handleEvent(event, dispatch)}
          clear={name => clearField(name, dispatch, 1)}
          error={false}
        />

        <Textbox
          text='Descrição do Imóvel'
          name='description'
          size='big'
          handleChange={event => handleEvent(event, dispatch)}
          value={state.description}
        />
      </ContainerFlexResponsive>
      <ContainerFlexResponsive justify='flex-end'>
        <Button
          size='small'
          onClick={() => {
            fieldErrorCheck(state, [
              'property_types_id',
              'Bedrooms',
              'suites',
              'bathrooms',
              'parking_space',
              'building_area',
              'land_area'
            ])
              ? setErrorCheck(true)
              : dispatch({ type: 'next' })
          }}
        >
          Próxima Etapa
        </Button>
      </ContainerFlexResponsive>
    </>
  )
}
