import React, { createContext, useReducer, useEffect, useContext } from 'react'
import { getClients, getProperties } from '../services/requests'
import { DataReducer } from '../reducers/DataReducer'
import axios from 'axios'
import { AuthContext } from './AuthContext'

export const DataContext = createContext()

export const DataContextProvider = ({ children }) => {
  const { auth_state } = useContext(AuthContext)

  const delay = ms => new Promise(res => setTimeout(res, ms))
  const get_data_after_delay = async () => {
    await delay(3000)
    getData()
  }

  useEffect(() => {
    let session = JSON.parse(sessionStorage.getItem('session_data'))
    if (session !== null) {
      get_data_after_delay()
    }
    // eslint-disable-next-line
  }, [auth_state])

  const initial_state = {
    properties: null,
    clients: null,
    getting_properties: false,
    getting_clients: false
  }

  const [data_state, dispatch] = useReducer(DataReducer, initial_state)

  const getData = async () => {
    try {
      let promises = await axios.all([
        getProperties({ page_items: 'MAX_VALUE' }),
        getClients({ page_items: 'MAX_VALUE' })
      ])
      dispatch({ type: 'set_data', fieldName: 'properties', payload: promises[0].data.properties })
      dispatch({ type: 'set_data', fieldName: 'getting_properties', payload: false })
      dispatch({ type: 'set_data', fieldName: 'clients', payload: promises[1].data.clients })
      dispatch({ type: 'set_data', fieldName: 'getting_clients', payload: false })
    } catch (e) {
      console.log(e)
    }
  }

  const getPropertiesData = async () => {
    try {
      dispatch({ type: 'getting_properties' })
      const properties = await getProperties({ page_items: 'MAX_VALUE' })
      dispatch({ type: 'set_data', fieldName: 'properties', payload: properties.data.properties })
      dispatch({ type: 'set_data', fieldName: 'getting_properties', payload: false })
    } catch (e) {
      console.log(e)
    }
  }

  const getClientsData = async () => {
    try {
      dispatch({ type: 'getting_clients' })
      const clients = await getClients({ page_items: 'MAX_VALUE' })
      dispatch({ type: 'set_data', fieldName: 'clients', payload: clients.data.clients })
      dispatch({ type: 'set_data', fieldName: 'getting_clients', payload: false })
    } catch (e) {
      console.log(e)
    }
  }

  const data_dispatch = action => {
    switch (action.type) {
      case 'get_data':
        if (!data_state.getting_data) getData()
        break
      case 'get_properties':
        if (!data_state.getting_properties) getPropertiesData()
        break
      case 'get_clients':
        if (!data_state.getting_clients) getClientsData()
        break
      default:
        return data_state
    }
  }

  return (
    <DataContext.Provider value={{ data_state, data_dispatch }}>{children}</DataContext.Provider>
  )
}
