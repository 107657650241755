import React from 'react'
import { BiCheck } from 'react-icons/bi'
import { CheckboxContainer, Label, Marker } from './styles'

export const Checkbox = ({ text, name, state, handleChange, size }) => {
  return (
    <CheckboxContainer size={size} onClick={() => handleChange(name)} marked={state}>
      <Label marked={state}>{text}</Label>
      <Marker marked={state}>
        <BiCheck />
      </Marker>
    </CheckboxContainer>
  )
}
