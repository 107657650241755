import React, { useState, useContext, useEffect } from 'react'
import { ReactComponent as Logo } from '../../assets/images/logo.svg'
import { AuthContext } from '../../context/AuthContext'
import { TextInput, Title, ContainerFlex, Marker, Button } from '../../components'
import { Container, Form, ShowPassword, Loader } from './styles'
import { BiCheck } from 'react-icons/bi'
import { useHistory } from 'react-router-dom'
import { DataContext } from '../../context/DataContext'

export const Login = ({ location }) => {
  let history = useHistory()
  const { auth_dispatch, auth_state } = useContext(AuthContext)
  const { data_dispatch } = useContext(DataContext)

  useEffect(() => {
    if (auth_state.user) {
      history.push(location.state.from.pathname)
    }
    // eslint-disable-next-line
  }, [auth_state.user])

  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [showPassword, setShowPassword] = useState(false)

  const onSubmit = event => {
    event.preventDefault()
    auth_dispatch({
      type: 'login',
      payload: {
        username: username,
        password: password,
        push: () => history.push(location.state.from.pathname)
      }
    })
    data_dispatch({ type: 'get_data' })
  }

  const changeUser = event => {
    setUsername(event.target.value)
  }

  const changePassword = event => {
    setPassword(event.target.value)
  }

  const changeShowPassword = () => {
    setShowPassword(!showPassword)
  }

  return (
    <Container>
      <Logo className='logo' />
      <Form onSubmit={onSubmit}>
        <Title>Login</Title>
        <ContainerFlex direction='column' gap='30px'>
          <TextInput
            type='text'
            text='Usuário'
            name='user'
            value={username}
            handleChange={changeUser}
            size='login'
            clear={() => setUsername('')}
          />

          <TextInput
            type={showPassword ? 'text' : 'password'}
            name='password'
            text='Senha'
            value={password}
            handleChange={changePassword}
            size='login'
            clear={() => setPassword('')}
          />
        </ContainerFlex>
        <ShowPassword onClick={changeShowPassword}>
          <Marker marked={showPassword}>
            <BiCheck />
          </Marker>
          Mostrar senha
        </ShowPassword>

        <Button size='full'>
          {auth_state.loading ? (
            <Loader className='spinner'>
              <div className='bounce1'></div>
              <div className='bounce2'></div>
              <div className='bounce3'></div>
            </Loader>
          ) : (
            'Acessar'
          )}
        </Button>
      </Form>
    </Container>
  )
}
