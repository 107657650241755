import styled from 'styled-components'

export const Label = styled.label`
  position: relative;
  display: block;

  & span {
    position: absolute;
    left: 16px;
    top: 24px;
    color: ${props => props.theme.input.placeholder};
    font-weight: 500;
    transition: 0.3s ease;
  }

  &:hover textarea {
    border: 1px solid ${props => props.theme.input.border_hover};
  }

  & textarea:valid + span,
  & textarea:focus + span {
    transform: translateY(-10px);
    font-size: 13px;
  }

  & textarea:valid,
  & textarea:focus {
    border: 1px solid ${props => props.theme.input.border_active};
  }

  & textarea:focus + span {
    color: ${props => props.theme.input.border_active};
  }
`
