export const helper = (data, dispatch) => {
  dispatch({
    id_property: data.id,
    id_characteristics: data.id,
    for_sale: data.for_sale,
    for_rent: data.for_rent,
    for_season: data.for_season,
    condominium_fee: data.condominium_fee,
    sale_price: data.sale_price,
    rent_price: data.rent_prince,
    season_price: data.season_price,
    iptu: data.iptu,
    cleaning_fee: data.cleaning_fee,
    is_financeable: data.is_financeable,
    accepts_exchange: data.accepts_exchange,
    fire_insurance: data.fire_insurance,
    expiration_date: data.expiration_date,
    expiration_date_count: null
  })
}
