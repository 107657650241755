import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { getEmployees, getPropertyById } from '../../services/requests'
import { MdKeyboardArrowDown } from 'react-icons/md'
import {
  BreadCrumb,
  BreadCrumbLabel,
  BreadCrumbLink,
  Container,
  ContainerFlex,
  ContainerFlexResponsive,
  Loader,
  Maps,
  RegularButton
} from '../../components/'
import {
  GalleryGrid,
  Title,
  Code,
  District,
  Icon,
  IconsContainer,
  Description,
  Commodities,
  Price,
  ShowImages,
  Prices,
  SectionTitle,
  Section,
  SectionField,
  FieldTitle,
  FieldContent,
  SectionContent
} from './styles'
import { AiFillCar } from 'react-icons/ai'
import { FaBath, FaBed, FaRulerHorizontal } from 'react-icons/fa'
import { BiFullscreen } from 'react-icons/bi'
/* import { Marker } from '@react-google-maps/api' */
import { Marker } from 'react-leaflet'
import FsLightbox from 'fslightbox-react'
import { RiArrowDropRightLine } from 'react-icons/ri'
import { helper } from './helper'
import axios from 'axios'

export const PropertyOverview = ({ setTransaction }) => {
  let { id } = useParams()

  const [property, setProperty] = useState(null)
  const [show, setShow] = useState('gallery')
  const [showGallery, setShowGallery] = useState(false)
  const [sections, setSections] = useState({
    general: false,
    characteristics: false,
    location: false,
    financial: false,
    internal: false
  })

  const getInitialData = async () => {
    const promises = await axios.all([getPropertyById(id), getEmployees()])
    helper(promises[0].data, promises[1].data, setProperty)
  }

  const handleSection = section => {
    setSections({ ...sections, [section]: !sections[section] })
  }

  useEffect(() => {
    getInitialData()
    // eslint-disable-next-line
  }, [])

  return (
    <>
      <ContainerFlexResponsive
        align='center'
        justify='space-between'
        margin='20px 0px'
        resp_direction='column'
      >
        <BreadCrumb>
          <BreadCrumbLink to={`/imovel/${id}`}>Imóvel {id}</BreadCrumbLink>
          <RiArrowDropRightLine />
          <BreadCrumbLabel>Visão Geral</BreadCrumbLabel>
        </BreadCrumb>
      </ContainerFlexResponsive>
      {property ? (
        <ContainerFlex margin='20px auto' direction='column' maxWidth='1120px' mobileWidth='90vw'>
          {show === 'gallery' && (
            <GalleryGrid onClick={() => setShowGallery(!showGallery)}>
              {property.images.slice(0, 5).map((img, index) => {
                return (
                  <img
                    src={img.url}
                    alt={property.id}
                    key={index}
                    className={index === 0 ? 'featured' : ''}
                  />
                )
              })}
              <ShowImages>
                <BiFullscreen />
              </ShowImages>
            </GalleryGrid>
          )}

          <FsLightbox
            toggler={showGallery}
            sources={property.images.map(img => img.url)}
            type='image'
          />

          {show === 'map' && (
            <Container maxWidth='1120px' height='400px' margin='0 0 20px 0'>
              <Maps
                position={{
                  lat: property.latitude,
                  lng: property.longitude
                }}
                zoom={15}
                style={{ height: '400px', width: '100%' }}
              >
                <Marker
                  position={{
                    lat: property.latitude,
                    lng: property.longitude
                  }}
                />
              </Maps>
            </Container>
          )}

          {show === 'video' && (
            <Container maxWidth='1120px' height='400px' margin='0 0 20px 0'>
              <iframe
                width='100%'
                height='100%'
                src='https://www.youtube.com/embed/iVYIYpMQBYI'
                autoplay='1'
                title='YouTube video player'
                frameborder='0'
                allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                allowfullscreen
              ></iframe>
            </Container>
          )}

          <ContainerFlex direction='row' gap='10px'>
            <RegularButton onClick={() => setShow('gallery')} selected={show === 'gallery'}>
              Fotos
            </RegularButton>
            <RegularButton onClick={() => setShow('map')} selected={show === 'map'}>
              Mapa
            </RegularButton>
          </ContainerFlex>
          <Code>Código {property.id}</Code>
          <Title>
            {property.public_area} {property.address}, {property.number} - {property.city}
          </Title>
          <District>{property.district}</District>
          <Prices>
            {property.for_sale && (
              <Price>Venda: R$ {Number(property.sale_price).toLocaleString('pt-br')}</Price>
            )}
            {property.for_rent && (
              <Price>Aluguel: R$ {Number(property.rent_price).toLocaleString('pt-br')}</Price>
            )}
            {property.for_season && (
              <Price>Diária: R$ {Number(property.season_price).toLocaleString('pt-br')}</Price>
            )}
          </Prices>

          <IconsContainer>
            <Icon>
              <FaRulerHorizontal />
              <p>{property.building_area} m²</p>
            </Icon>
            <Icon>
              <FaBed />
              <p>
                {property.bedrooms} Quarto
                {property.bedrooms > 1 ? 's' : null}
              </p>
            </Icon>
            <Icon>
              <FaBath />
              <p>
                {property.bathrooms} Banheiro
                {property.bathrooms > 1 ? 's' : null}
              </p>
            </Icon>
            <Icon>
              <AiFillCar />
              <p>
                {property.parking_space ? property.parking_space : 'Nenhuma'} Vaga
                {property.parking_space > 1 ? 's' : null} de Garagem
              </p>
            </Icon>
          </IconsContainer>
          <Description>{property.description}</Description>
          {property.commodities.length > 0 && (
            <Commodities>
              <h1>Comodidades</h1>
              <ul>
                {property.commodities.map(commodity => (
                  <li key={commodity.id}>{commodity.name}</li>
                ))}
              </ul>
            </Commodities>
          )}

          <Section active={sections.general} onClick={() => handleSection('general')}>
            <SectionTitle className='no-select'>Informações Gerais</SectionTitle>
            <MdKeyboardArrowDown />
          </Section>

          {sections.general && (
            <SectionContent>
              <SectionField>
                <FieldTitle>Destaque</FieldTitle>
                <FieldContent>{property.featured ? 'Sim' : 'Não'}</FieldContent>
              </SectionField>

              <SectionField>
                <FieldTitle>Aparecer no Site</FieldTitle>
                <FieldContent>{property.is_publishable ? 'Sim' : 'Não'}</FieldContent>
              </SectionField>

              <SectionField>
                <FieldTitle>Status</FieldTitle>
                <FieldContent>{property.status}</FieldContent>
              </SectionField>

              <SectionField>
                <FieldTitle>Posição Solar</FieldTitle>
                <FieldContent>{property.solar_position}</FieldContent>
              </SectionField>

              <SectionField>
                <FieldTitle>Posição no Terreno</FieldTitle>
                <FieldContent>{property.field_orientation}</FieldContent>
              </SectionField>

              <SectionField>
                <FieldTitle>Área Total</FieldTitle>
                <FieldContent>{property.building_area} m²</FieldContent>
              </SectionField>

              <SectionField>
                <FieldTitle>Área Construída</FieldTitle>
                <FieldContent>{property.land_area} m²</FieldContent>
              </SectionField>

              <SectionField>
                <FieldTitle>Área Terreno Frente</FieldTitle>
                <FieldContent>{property.front_area} m²</FieldContent>
              </SectionField>

              <SectionField>
                <FieldTitle>Área Terreno Fundo</FieldTitle>
                <FieldContent>{property.back_area} m²</FieldContent>
              </SectionField>
            </SectionContent>
          )}

          <Section
            active={sections.characteristics}
            onClick={() => handleSection('characteristics')}
          >
            <SectionTitle>Características</SectionTitle>
            <MdKeyboardArrowDown />
          </Section>

          {sections.characteristics && (
            <SectionContent>
              <SectionField>
                <FieldTitle>Tipo de Imóvel</FieldTitle>
                <FieldContent>{property.property_type}</FieldContent>
              </SectionField>
              <SectionField>
                <FieldTitle>Quartos</FieldTitle>
                <FieldContent>{property.bedrooms}</FieldContent>
              </SectionField>
              <SectionField>
                <FieldTitle>Suítes</FieldTitle>
                <FieldContent>{property.suites}</FieldContent>
              </SectionField>
              <SectionField>
                <FieldTitle>Banheiros</FieldTitle>
                <FieldContent>{property.bathrooms}</FieldContent>
              </SectionField>
              <SectionField>
                <FieldTitle>Vagas de Garagem</FieldTitle>
                <FieldContent>{property.parking_space}</FieldContent>
              </SectionField>
              <SectionField>
                <FieldTitle>Mobiliado</FieldTitle>
                <FieldContent>{property.is_furnished}</FieldContent>
              </SectionField>
            </SectionContent>
          )}

          <Section active={sections.location} onClick={() => handleSection('location')}>
            <SectionTitle>Localização</SectionTitle>
            <MdKeyboardArrowDown />
          </Section>

          {sections.location && (
            <SectionContent>
              <SectionField>
                <FieldTitle>CEP</FieldTitle>
                <FieldContent>{property.cep}</FieldContent>
              </SectionField>
              <SectionField>
                <FieldTitle>Estado</FieldTitle>
                <FieldContent>{property.state}</FieldContent>
              </SectionField>
              <SectionField>
                <FieldTitle>Bairro</FieldTitle>
                <FieldContent>{property.district}</FieldContent>
              </SectionField>
              <SectionField>
                <FieldTitle>Cidade</FieldTitle>
                <FieldContent>{property.city}</FieldContent>
              </SectionField>
              <SectionField>
                <FieldTitle>Endereço</FieldTitle>
                <FieldContent>
                  {property.public_area} {property.address}
                </FieldContent>
              </SectionField>
              <SectionField>
                <FieldTitle>Número</FieldTitle>
                <FieldContent>{property.number}</FieldContent>
              </SectionField>
              <SectionField>
                <FieldTitle>Complemento</FieldTitle>
                <FieldContent>{property.complement}</FieldContent>
              </SectionField>
            </SectionContent>
          )}

          <Section active={sections.financial} onClick={() => handleSection('financial')}>
            <SectionTitle>Financeiro</SectionTitle>
            <MdKeyboardArrowDown />
          </Section>

          {sections.financial && (
            <SectionContent>
              <SectionField>
                <FieldTitle>Para Venda</FieldTitle>
                <FieldContent>{property.for_sale ? 'Sim' : 'Não'}</FieldContent>
              </SectionField>
              <SectionField>
                <FieldTitle>Para Aluguel</FieldTitle>
                <FieldContent>{property.for_rent ? 'Sim' : 'Não'}</FieldContent>
              </SectionField>
              <SectionField>
                <FieldTitle>Para Temporada</FieldTitle>
                <FieldContent>{property.for_season ? 'Sim' : 'Não'}</FieldContent>
              </SectionField>
              <SectionField>
                <FieldTitle>Valor Venda</FieldTitle>
                <FieldContent>
                  R$ {Number(property.sale_price).toLocaleString('pt-br')}
                </FieldContent>
              </SectionField>
              <SectionField>
                <FieldTitle>Valor Aluguel</FieldTitle>
                <FieldContent>
                  R$ {Number(property.rent_price).toLocaleString('pt-br')}
                </FieldContent>
              </SectionField>
              <SectionField>
                <FieldTitle>Valor Temporada</FieldTitle>
                <FieldContent>
                  R$ {Number(property.season_price).toLocaleString('pt-br')}
                </FieldContent>
              </SectionField>
              <SectionField>
                <FieldTitle>Taxa de Condomínio</FieldTitle>
                <FieldContent>
                  R$ {Number(property.condominium_fee).toLocaleString('pt-br')}
                </FieldContent>
              </SectionField>
              <SectionField>
                <FieldTitle>IPTU</FieldTitle>
                <FieldContent>R$ {Number(property.iptu).toLocaleString('pt-br')}</FieldContent>
              </SectionField>
              <SectionField>
                <FieldTitle>Taxa de Limpeza</FieldTitle>
                <FieldContent>
                  R$ {Number(property.cleaning_fee).toLocaleString('pt-br')}
                </FieldContent>
              </SectionField>
              <SectionField>
                <FieldTitle>Seguro Incêndio</FieldTitle>
                <FieldContent>
                  R$ {Number(property.fire_insurance).toLocaleString('pt-br')}
                </FieldContent>
              </SectionField>
              <SectionField>
                <FieldTitle>Aceita Troca</FieldTitle>
                <FieldContent>{property.accepts_exchange ? 'Sim' : 'Não'}</FieldContent>
              </SectionField>
            </SectionContent>
          )}

          <Section active={sections.internal} onClick={() => handleSection('internal')}>
            <SectionTitle>Interno</SectionTitle>
            <MdKeyboardArrowDown />
          </Section>

          {sections.internal && (
            <SectionContent>
              <SectionField>
                <FieldTitle>Proprietário</FieldTitle>
                <FieldContent>{property.owner}</FieldContent>
              </SectionField>
              <SectionField>
                <FieldTitle>Criado por</FieldTitle>
                <FieldContent>{property.created_by}</FieldContent>
              </SectionField>
              <SectionField>
                <FieldTitle>Corretor Responsável</FieldTitle>
                <FieldContent>{property.responsable_employee}</FieldContent>
              </SectionField>
              <SectionField>
                <FieldTitle>Captador</FieldTitle>
                <FieldContent>{property.listing_broker.name}</FieldContent>
              </SectionField>
              <SectionField>
                <FieldTitle>Data de Vencimento</FieldTitle>
                <FieldContent>{property.expiration_date}</FieldContent>
              </SectionField>
              <SectionField>
                <FieldTitle>Exclusividade</FieldTitle>
                <FieldContent>{property.is_exclusive ? 'Sim' : 'Não'}</FieldContent>
              </SectionField>
              <SectionField>
                <FieldTitle>Placa no Imóvel</FieldTitle>
                <FieldContent>{property.has_board ? 'Possui' : 'Não Possui'}</FieldContent>
              </SectionField>
            </SectionContent>
          )}
        </ContainerFlex>
      ) : (
        <ContainerFlex margin='30vh 0' align='center' justify='center'>
          <Loader />
        </ContainerFlex>
      )}
    </>
  )
}
