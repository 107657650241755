import React, { useState, useContext, useEffect } from 'react'
import {
  handleSelection,
  handleEvent,
  handleCheckBox,
  fieldErrorCheck,
  clearField
} from '../handleFunctions'
import {
  Title,
  Description,
  ContainerFlexResponsive,
  SelectInputValue,
  Button,
  SecondaryButton,
  Checkbox,
  Textbox
} from '../../../components'
import { AuthContext } from '../../../context/AuthContext'

export const Internal = ({ state, dispatch, update }) => {
  const [checkError, setCheckError] = useState(false)
  const checkList = [
    'owner_id',
    'listing_broker_id',
    'responsable_employee_id',
    !update && 'expiration_date'
  ]
  const { auth_state } = useContext(AuthContext)
  const [authenticated, setAuthenticated] = useState(false)

  useEffect(() => {
    if (!update) {
      setAuthenticated(true)
    } else {
      if (auth_state.access_levels === 1 || state.expired) setAuthenticated(true)
    }
    // eslint-disable-next-line
  }, [])
  return (
    <>
      <Title>Informações Internas</Title>
      <Description>Informações internas sobre o imóvel</Description>

      <ContainerFlexResponsive gap='50px' margin='0 0 45px' resp_direction='column'>
        <SelectInputValue
          text='Proprietário *'
          name='owner_id'
          value={state.owner_id}
          options={state.clients}
          size='small'
          handleSelection={(name, selection) => handleSelection(name, selection, dispatch)}
          error={checkError ? state.owner_id === null : false}
          clear={name => clearField(name, dispatch, 2)}
        />

        <SelectInputValue
          text='Corretor Responsável *'
          name='responsable_employee_id'
          value={state.responsable_employee_id}
          options={state.employees_options}
          size='small'
          handleSelection={(name, selection) => handleSelection(name, selection, dispatch)}
          error={checkError ? state.responsable_employee_id === null : false}
          clear={name => clearField(name, dispatch, 2)}
        />

        {authenticated && (
          <SelectInputValue
            text='Captador *'
            name='listing_broker_id'
            value={state.listing_broker_id}
            options={state.employees_options}
            size='small'
            handleSelection={(name, selection) => handleSelection(name, selection, dispatch)}
            error={checkError ? state.listing_broker_id === null : false}
            clear={name => clearField(name, dispatch, 2)}
          />
        )}

        <SelectInputValue
          text='Condomínio'
          name='condominiums_id'
          value={state.condominiums_id}
          options={state.condominiums}
          size='small'
          handleSelection={(name, selection) => handleSelection(name, selection, dispatch)}
          clear={name => clearField(name, dispatch, 2)}
        />

        {!update && (
          <SelectInputValue
            text='Data de Vencimento *'
            name='expiration_date'
            value={state.expiration_date}
            options={[
              { id: 30, name: '30 dias' },
              { id: 60, name: '60 dias' },
              { id: 90, name: '90 dias' }
            ]}
            size='small'
            handleSelection={(name, selection) => handleSelection(name, selection, dispatch)}
            error={checkError ? state.expiration_date === null : false}
            clear={name => clearField(name, dispatch, 2)}
          />
        )}

        <Checkbox
          text='Placa no Imóvel'
          name='has_board'
          state={state.has_board}
          handleChange={name => handleCheckBox(name, dispatch)}
        />
      </ContainerFlexResponsive>

      <ContainerFlexResponsive margin='0 0 45px'>
        <Textbox
          text='Observações Internas'
          name='observacoes_internas'
          size='big'
          handleChange={event => handleEvent(event, dispatch)}
          clear={name => clearField(name, dispatch, 1)}
        />
      </ContainerFlexResponsive>

      <ContainerFlexResponsive justify='space-between' resp_direction='column' resp_gap='20px'>
        <SecondaryButton size='small' onClick={() => dispatch({ type: 'previous' })}>
          Voltar
        </SecondaryButton>
        <Button
          size='small'
          onClick={() => {
            fieldErrorCheck(state, checkList) ? setCheckError(true) : dispatch({ type: 'next' })
          }}
        >
          Próxima Etapa
        </Button>
      </ContainerFlexResponsive>
    </>
  )
}
