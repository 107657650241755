import React, { useState, useEffect } from 'react'
import {
  ContainerFlex,
  BreadCrumb,
  Loader,
  Table,
  PropertyCard,
  Title,
  Description,
  Pagination,
  ContainerGrid,
  PropertiesFilter
} from '../../components'
import axios from 'axios'
import { AiOutlineEye, AiOutlinePlus } from 'react-icons/ai'
import { RiArrowDropRightLine } from 'react-icons/ri'
import { IoMdClose, IoMdCheckmark } from 'react-icons/io'
import { HiSortAscending, HiSortDescending } from 'react-icons/hi'
import { FiFilter } from 'react-icons/fi'
import { useHistory } from 'react-router'
import {
  getProperties,
  getCities,
  getDistrictsByID,
  getPropertyTypes
} from '../../services/requests'
import { IconButton, Tag } from './styles'
import { tagsDecoder } from '../../utils/optionsInput'
import moment from 'moment'

export const Properties = () => {
  let history = useHistory()

  const [form, setForm] = useState({
    city: 414,
    district: null,
    type: null,
    address: null,
    number: null,
    bedrooms: null,
    bathrooms: null,
    parking_spaces: null,
    min: null,
    max: null,
    initial_date: null,
    final_date: null,
    transaction: 'sale'
  })

  const [formOptions, setFormOptions] = useState({
    cities: null,
    districts: null,
    types: null,
    transactions: [
      { id: 'sale', name: 'Venda' },
      { id: 'rent', name: 'Aluguel' },
      { id: 'season', name: 'Temporada' }
    ]
  })

  const [showFilter, setShowFilter] = useState(false)

  // For showing only the filtered data
  const [data, setData] = useState(null)
  const page_items = 30
  const [page, setPage] = useState(0)
  const [total_pages, setTotalPages] = useState(0)
  const [order, setOrder] = useState('id')
  const [orderDirection, setOrderDirection] = useState('asc')
  const [loading, setLoading] = useState(true)

  const [grid, setGrid] = useState(true)

  const getInitialData = async () => {
    let params = {
      city: 'São Lourenço do Sul',
      page_items,
      order,
      order_direction: orderDirection
    }

    try {
      let promises = await axios.all([
        getCities(),
        getDistrictsByID(form.city),
        getPropertyTypes(),
        getProperties(params)
      ])
      setFormOptions({
        ...formOptions,
        cities: promises[0].data,
        districts: promises[1].data,
        types: promises[2].data
      })

      setData(promises[3].data.properties)
      setTotalPages(promises[3].data.total_pages)
      setLoading(false)
    } catch (e) {
      console.log(e)
      alert('Erro ao carregar os dados')
    }
  }

  const handleChangePage = async type => {
    let params_filter = {
      ...form,
      city:
        form.city && formOptions.cities
          ? formOptions.cities.find(elem => elem.id === form.city).name
          : null,
      district:
        form.district && formOptions.districts
          ? formOptions.districts.find(elem => elem.id === form.district).name
          : null,
      page_items,
      order,
      order_direction: orderDirection
    }
    setLoading(true)

    if (type === 'next') {
      if (page !== total_pages) {
        setPage(page + 1)
        params_filter = {
          ...params_filter,
          page: page + 1
        }
      }
    } else if (type === 'prev') {
      if (page !== 0) {
        setPage(page - 1)
        params_filter = {
          ...params_filter,
          page: page - 1
        }
      }
    } else {
      setPage(type)
      params_filter = {
        ...params_filter,
        page: type
      }
    }

    try {
      let promise = await getProperties(params_filter)
      setData(promise.data.properties)
      setLoading(false)
    } catch (error) {
      console.log(error)
      alert('Erro ao carregar dados')
    }
  }

  const handleOrder = async (type, id) => {
    setLoading(true)
    setPage(0)

    let params_filter = {
      ...form,
      city:
        form.city && formOptions.cities
          ? formOptions.cities.find(elem => elem.id === form.city).name
          : null,
      district:
        form.district && formOptions.districts
          ? formOptions.districts.find(elem => elem.id === form.district).name
          : null,
      page: 0,
      page_items
    }

    if (type === 'orderDirection') {
      let direction = orderDirection === 'asc' ? 'desc' : 'asc'
      setOrderDirection(direction)

      try {
        let promise = await getProperties({
          ...params_filter,
          order,
          order_direction: direction
        })
        setData(promise.data.properties)
        setLoading(false)
      } catch (error) {
        console.log(error)
        alert('Erro ao carregar dados')
      }
    } else {
      setOrder(id)
      try {
        let promise = await getProperties({
          ...params_filter,
          order: id,
          order_direction: orderDirection
        })
        setData(promise.data.properties)
        setLoading(false)
      } catch (error) {
        console.log(error)
        alert('Erro ao carregar dados')
      }
    }
  }

  const handleFilter = async () => {
    setShowFilter(false)
    setLoading(true)
    setPage(0)

    let params = {
      ...form,
      city:
        form.city && formOptions.cities
          ? formOptions.cities.find(elem => elem.id === form.city).name
          : null,
      district:
        form.district && formOptions.districts
          ? formOptions.districts.find(elem => elem.id === form.district).name
          : null,
      page_items,
      order,
      order_direction: orderDirection
    }

    try {
      let promise = await getProperties(params)
      setData(promise.data.properties)
      setTotalPages(promise.data.total_pages)
      setLoading(false)
    } catch (e) {
      console.log(e)
      alert('Erro ao carregar os dados')
    }
  }

  const tags = () => {
    //eslint-disable-next-line
    return Object.keys(form).map(elem => {
      if (
        form[elem] !== null &&
        form[elem] !== '' &&
        elem !== 'min' &&
        elem !== 'max' &&
        elem !== 'initial_date' &&
        elem !== 'final_date'
      ) {
        if (elem === 'city') {
          return <Tag>{formOptions.cities.find(city => city.id === form[elem]).name}</Tag>
        }

        if (elem === 'district') {
          return (
            <Tag>{formOptions.districts.find(district => district.id === form[elem]).name}</Tag>
          )
        }

        if (elem === 'type') {
          return <Tag>{formOptions.types.find(type => type.id === form[elem]).name}</Tag>
        }

        if (elem === 'transaction') {
          return (
            <Tag>
              {formOptions.transactions.find(availability => availability.id === form[elem]).name}
            </Tag>
          )
        }

        return <Tag>{tagsDecoder(elem, form[elem])}</Tag>
      }
    })
  }

  useEffect(() => {
    getInitialData()
    // eslint-disable-next-line
  }, [])

  return (
    <>
      <ContainerFlex margin='20px 0 0' gap='10px' direction='column'>
        <BreadCrumb>
          <label>Imóveis</label>
          <RiArrowDropRightLine />
        </BreadCrumb>
        <div>
          <Title>Imóveis</Title>
          <Description margin_small={true}>Listagem de todos os imóveis disponíveis</Description>
        </div>
      </ContainerFlex>

      <ContainerFlex margin='5px 20px 20px 0' gap='15px'>
        <IconButton onClick={() => setShowFilter(!showFilter)}>
          <FiFilter />
          Filtro
        </IconButton>
        <IconButton onClick={() => handleOrder('orderDirection')}>
          {orderDirection === 'asc' ? <HiSortDescending /> : <HiSortAscending />}
        </IconButton>
        <IconButton onClick={() => setGrid(!grid)}>
          <AiOutlineEye />
        </IconButton>
        <IconButton onClick={() => history.push('/novo/imovel')}>
          <AiOutlinePlus />
        </IconButton>
      </ContainerFlex>

      <ContainerFlex margin='25px auto' width='100%' gap='15px'>
        {formOptions.cities && formOptions.districts && formOptions.types && tags()}
      </ContainerFlex>

      {showFilter && (
        <ContainerFlex margin='25px auto' width='100%'>
          <PropertiesFilter
            form_data={[form, setForm]}
            form_options={[formOptions, setFormOptions]}
            handleFilter={handleFilter}
          />
        </ContainerFlex>
      )}

      <ContainerFlex margin='25px auto'>
        <ContainerGrid width='100%' min='200px' gap='15px' justify='center'>
          {!loading &&
            data &&
            grid &&
            data.map(prop => {
              return (
                <PropertyCard
                  click={() => history.push(`/imovel/${prop.id}`)}
                  data={prop}
                  key={prop.id}
                />
              )
            })}
        </ContainerGrid>

        {!loading && data && !grid && (
          <Table>
            <thead>
              <tr>
                <th className='center'>Código</th>
                <th>Endereço</th>
                <th>Tipo</th>
                <th>Proprietário</th>
                <th>Data de Vencimento</th>
                <th className='center'>Destaque</th>
                <th className='center'>Aparece no Site</th>
              </tr>
            </thead>
            <tbody>
              {data.map(prop => {
                return (
                  <tr onClick={() => history.push(`/imovel/${prop.id}`)} key={prop.id}>
                    <td className='center'>{prop.id}</td>
                    <td>{`${prop.address}, ${prop.number}`}</td>
                    <td>{prop.propertytype}</td>
                    <td>{prop.client}</td>
                    <td>{moment(prop.expiration_date).format('DD-MM-YYYY')}</td>
                    <td className='center'>
                      {!!prop.featured ? (
                        <IoMdCheckmark className='enabled' />
                      ) : (
                        <IoMdClose className='disabled' />
                      )}
                    </td>
                    <td className='center' style={{ color: 'red' }}>
                      {!!prop.is_publishable ? (
                        <IoMdCheckmark className='enabled' />
                      ) : (
                        <IoMdClose className='disabled' />
                      )}
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </Table>
        )}

        {loading && <Loader margin='20vh auto' />}
      </ContainerFlex>

      {!loading && data && (
        <ContainerFlex margin='20px auto' width='100%' justify='center'>
          <Pagination
            pageCount={total_pages}
            handleChangePage={handleChangePage}
            currentPage={page}
          />
        </ContainerFlex>
      )}
    </>
  )
}
