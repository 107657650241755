import React, { createContext, useReducer, useEffect } from 'react'
import { login } from '../services/requests'
import { AuthReducer } from '../reducers/AuthReducer.js'

export const AuthContext = createContext()

export const AuthContextProvider = ({ children }) => {
  useEffect(() => {
    let session = sessionStorage.getItem('session_data')
    if (session) {
      dispatch({ type: 'login', payload: JSON.parse(session) })
    }
  }, [])

  const initial_state = {
    user: null,
    id: null,
    name: null,
    access_levels: null,
    loading: false,
    token: null,
    error: false
  }

  const [auth_state, dispatch] = useReducer(AuthReducer, initial_state)

  const tryLogin = async (username, password, push) => {
    try {
      const { data } = await login(username, password)
      if (!!data.error) throw new Error('Erro Interno')
      dispatch({ type: 'login', payload: data })
      dispatch({ type: 'set_data', fieldName: 'loading', payload: false })
      sessionStorage.setItem('session_data', JSON.stringify(data))
      push()
    } catch (error) {
      console.log(error)
      alert('Usuário ou senha incorretos')
      dispatch({ type: 'set_data', fieldName: 'error', payload: true })
      dispatch({ type: 'set_data', fieldName: 'loading', payload: false })
    }
  }

  const auth_dispatch = action => {
    switch (action.type) {
      case 'login':
        dispatch({ type: 'set_data', fieldName: 'loading', payload: true })
        tryLogin(action.payload.username, action.payload.password, action.payload.push)
        break

      case 'logout':
        dispatch({ type: 'logout' })
        sessionStorage.removeItem('session_data')
        break

      default:
        return auth_state
    }
  }

  return (
    <AuthContext.Provider value={{ auth_dispatch, auth_state }}>{children}</AuthContext.Provider>
  )
}
