import React from 'react'
import styled from 'styled-components'
import { AiOutlinePlus } from 'react-icons/ai'

const Button = styled.div`
  min-width: 200px;
  min-height: 200px;
  background: ${props => props.theme.input.background};
  border-radius: 15px;
  cursor: pointer;
  transition: all 0.3s;
  border: 1px solid ${props => props.theme.input.border};

  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    color: ${props => props.theme.text.primary_soft};
    width: 20px;
    height: 20px;
    transition: all 0.3s;
  }

  &:hover {
    border: 1px solid ${props => props.theme.input.border_active};

    svg {
      color: ${props => props.theme.input.border_active};
    }
  }
`

export const UploadButton = ({ action }) => {
  return (
    <Button onClick={action}>
      <AiOutlinePlus />
    </Button>
  )
}
