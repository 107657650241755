import moment from 'moment'

export const helper = (data, employees, dispatch) => {
  dispatch({
    id_property: data.id,
    property_type: data.propertyType.name,
    featured: data.featured,
    is_publishable: data.is_publishable,
    status: data.status,
    bedrooms: data.bedrooms,
    suites: data.suites,
    bathrooms: data.bathrooms,
    parking_space: data.parking_space,
    is_furnished: data.furnishing,
    solar_position: data.solar_position,
    building_area: data.building_area,
    land_area: data.land_area,
    front_area: data.front_area,
    back_area: data.back_area,
    field_orientation: data.field_orientation,
    description: data.description,

    cep: data.cep,
    state: data.district.city.state.name,
    city: data.district.city.name,
    district: data.district.name,
    address: data.address,
    number: data.number,
    complement: data.complement,
    public_area: data.publicArea.name,
    latitude: data.latitude,
    longitude: data.longitude,

    for_sale: data.for_sale,
    for_rent: data.for_rent,
    for_season: data.for_season,
    condominium_fee: data.condominium_fee,
    sale_price: data.sale_price,
    rent_price: data.rent_price,
    season_price: data.season_price,
    iptu: data.iptu,
    cleaning_fee: data.cleaning_fee,
    is_financeable: data.is_financeable,
    accepts_exchange: data.accepts_exchange,
    fire_insurance: data.fire_insurance,

    owner: data.client.name,
    created_by: data.createdBy.name,
    listing_broker: data.listing_broker
      ? employees.find(employee => employee.id === data.listing_broker)
      : '',
    responsable_employee: data.employee.name,
    is_exclusive: data.is_exclusive,
    has_board: data.has_board,
    favorite_image: data.images.length ? data.images.some(elem => !!elem.is_favorite) : false,
    images: data.images,
    commodities: data.commodities,
    expiration_date: moment(data.expiration_date).format('DD/MM/YYYY')
  })
}
