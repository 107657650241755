import React from 'react'
import { Card, Image, Title, NoImage } from './styles'
import { ContainerFlex } from '../'
import logo from '../../assets/images/logo_simple.png'

export const ClientCard = ({ data, url }) => {
  return (
    <Card to={url}>
      {data.photo ? (
        <Image src={data.photo} alt={data.id} />
      ) : (
        <NoImage>
          <img src={logo} alt='Sem Imagem' />
        </NoImage>
      )}
      <ContainerFlex margin='10px 20px 15px' direction='column'>
        <span>Código {data.id}</span>
        <div>
          <Title>{data.name}</Title>
          <p>{data.whatsapp}</p>
        </div>
      </ContainerFlex>
    </Card>
  )
}
