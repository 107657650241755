import { createGlobalStyle } from 'styled-components'
import { colors } from './colors'

export default createGlobalStyle`
/* BODY STYLES */

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body,
input,
button,
textarea,
ul,
li {
  font-family: 'IBM Plex Sans', sans-serif;
}

body {
  background: ${props => props.theme.general.background};
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: ${props => props.theme.text.primary};
}

p,
li,
label,
span {
  font-size: 14px;
  color: ${props => props.theme.text.primary};
}

select {
  padding: 0 15px;
}

/* Remove default style from browser autofill */
input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 1000px white inset !important;
  -webkit-text-fill-color: ${props => props.theme.text.primary};
}

input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 1000px white inset !important;
}

/* Remove arrows from input number */

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

button {
  display: block;
  width: 100%;
  border: 0;
  height: 45px;
  border-radius: 12px;
  background: ${props => props.theme.button.default};
  transition: 0.4s all;

  cursor: pointer;
  outline: none;
  text-decoration: none;

  color: white;
  font-weight: 600;
  font-size: 14px;
}

button:hover {
  background: ${props => props.theme.button.default_hover};
}

a {
  text-decoration: none;
  color: ${props => props.theme.text.primary};
  transition: 0.4s all;
}

.no-select {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Breadcrumb */

.breadcrumb.default {
  display: flex;
  align-items: center;
  gap: 5px;
}

.breadcrumb.default a {
  font-size: 13px;
  color: ${props => props.theme.text.primary_soft};
}

.breadcrumb.default a:hover {
  color: ${props => props.theme.text.primary};
}

.breadcrumb.default p {
  font-size: 13px;
  color: ${props => props.theme.text.primary_soft};
  cursor: default;
}

.breadcrumb.default svg {
  color: ${props => props.theme.text.primary_soft};
}

.breadcrumb.default p.current {
  color: ${props => props.theme.text.primary};
}

/* DatePicker AirBnB */

.DayPickerKeyboardShortcuts_show__bottomRight {
  display: none;
}

.CalendarDay__selected,
.CalendarDay__selected:active,
.CalendarDay__selected:hover {
  background: ${props => props.theme.button.default};
  border: 1px double ${props => props.theme.button.default_hover};
}

.CalendarDay__selected_span {
  background: ${props => props.theme.button.default_hover};
  border: 1px double ${props => props.theme.button.default_hover};
}

.CalendarDay__default:hover {
  background: ${props => props.theme.button.default_hover};
  border: 1px double ${props => props.theme.button.default_hover};
  color: ${props => props.theme.text.light};
}
`

export const default_theme = {
  general: {
    primary: '#fd7670',
    background: 'white',
    border: colors['Gray 200'],
    border_hover: colors['Blue 400']
  },

  text: {
    primary: 'rgb(79, 79, 79)',
    primary_soft: 'rgb(151, 151, 151)',
    primary_subtilte: 'rgb(107, 114, 128)',
    light: 'rgb(255, 254, 254)'
  },

  input: {
    color: colors['Red 700'],
    background: 'white',
    border: colors['Gray 300'],
    border_hover: colors['Gray 400'],
    border_active: colors['Blue 400'],
    border_error: colors['Red 500'],
    placeholder: colors['Gray 500'],
    placeholder_active: colors['Blue 400'],
    placeholder_error: colors['Red 500'],
    marker: '#e8e8e8',
    dropdown_background: 'white',
    dropdown_option_hover: colors['Blue 50'],
    dropdown_option_selected: colors['Blue 600'],

    button: colors['Gray 400'],
    button_hover: colors['Gray 400'],
    button_background_hover: colors['Gray 200'],
    button_height: '22px',
    button_width: '22px',
    button_padding: '5px'
  },

  button: {
    default_background: colors['Blue 500'],
    default_background_hover: colors['Blue 600'],
    default_text: 'white',
    regular_background: 'white',
    regular_background_hover: colors['Gray 200'],
    regular_text_hover: colors['Gray 600'],
    regular_border: colors['Gray 600'],
    secondary_background: colors['Blue 50'],
    secondary_text: colors['Blue 600'],
    secondary_background_hover: colors['Blue 100'],
    simple_background: 'white',
    simple_background_hover: colors['Blue 50'],
    simple_text: colors['Blue 500'],
    simple_text_hover: colors['Blue 600']
  },

  header: {
    background: 'white',
    border: colors['Gray 200'],
    search_border: colors['Gray 300']
  },

  sidebar: {
    background: 'white',
    border: colors['Gray 200'],
    button_border: colors['Gray 300'],
    button_background: 'white',
    button_background_hover: colors['Gray 300'],
    link: colors['Gray 600'],
    link_hover: colors['Blue 50'],
    link_active: colors['Blue 500'],
    link_background_active: colors['Blue 50'],
    link_disconnect: colors['Red 500'],
    link_disconnect_hover: colors['Red 400'],
    icon: colors['Blue 500'],
    user_icon: colors['Gray 400']
  },

  table: {
    background: 'white',
    border: colors['Gray 200'],
    header_text: colors['Gray 500'],
    text: colors['Gray 600'],
    head: '#e3e3e3',
    hover: colors['Blue 50'],
    hover_text: colors['Blue 600'],
    icon_border: colors['Gray 300'],
    icon_background_hover: colors['Blue 600'],
    icon_color_hover: 'white',
    icon_active: colors['Blue 300'],
    icon_disabled: colors['Red 300']
  },

  steps: {
    active: colors['Blue 400'],
    inactive: colors['Gray 400'],
    done: colors['Gray 300']
  },

  cards: {
    background: 'white',
    border: colors['Gray 300'],
    border_hover: colors['Blue 400'],
    text: colors['Gray 500'],
    text_hover: colors['Blue 500'],
    subtext: colors['Gray 500'],
    colors: [
      { background: colors['Purple 200'], icon: colors['Purple 600'] },
      { background: colors['Red 200'], icon: colors['Red 600'] },
      { background: colors['Green 200'], icon: colors['Green 600'] },
      { background: colors['Yellow 200'], icon: colors['Yellow 600'] },
      { background: colors['Pink 200'], icon: colors['Pink 600'] },
      { background: colors['Orange 200'], icon: colors['Orange 600'] }
    ]
  },

  date_picker: {
    background: 'white',
    border: colors['Gray 200'],
    divisor: colors['Gray 300'],
    button_color: colors['Gray 400'],
    button_color_hover: colors['Gray 200'],
    option_background: colors['Blue 50'],
    option_color: colors['Blue 600']
  },

  loader: {
    input: colors['Gray 600']
  },

  icons: {
    primary: ''
  }
}
