import axios from 'axios'

const api = axios.create({
  baseURL: process.env.REACT_APP_SERVER_PROD,
  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*'
  }
})

api.interceptors.request.use(req => {
  const session = JSON.parse(sessionStorage.getItem('session_data'))
  const token = session && session.token
  if (token) {
    req.headers.Authorization = `Bearer ${token}`
  }
  return req
})

export default api
