import styled from 'styled-components'
import { Link } from 'react-router-dom'

export const MenuContainer = styled.div`
  display: grid;
  align-items: center;
  justify-items: center;
  grid-template-columns: 1fr 2fr 1fr;
  width: 100%;
  height: 77px;
  border-bottom: 1px solid ${props => props.theme.header.border};

  @media (max-width: 1000px) {
    display: block;
  }
`

export const SearchContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: white;
  border: 1px solid
    ${props =>
      props.searching ? props.theme.header.search_border : props.theme.general.background};
  border-radius: 5px 5px 0 0;
  width: 500px;
  grid-column-start: 2;
  & svg {
    height: 20px;
    width: 20px;
    margin-right: 30px;
    color: ${props => props.theme.input.placeholder};

    &#close {
      cursor: pointer;
      height: 35px;
      width: 40px;
      padding: 10px;
      border-radius: 40px;
      margin-right: 15px;
      transition: 0.2s all;

      &:hover {
        background: ${props => props.theme.input.dropdown_option_hover};
      }
    }
  }

  //media query
  @media (max-width: 1000px) {
    display: ${props => (props.open ? 'flex' : 'none')};
    position: absolute;
    top: 90px;
    left: 50%;
    transform: translateX(-50%);
    width: 90vw;
    z-index: 9999;
  }
`

export const SearchInput = styled.input`
  border: 0;
  font-size: 14px;
  font-weight: 500;
  color: ${props => props.theme.text.primary};
  background: none;
  height: 100%;
  width: 100%;
  padding: 15px 35px;
  outline: none;

  &::placeholder {
    color: ${props => props.theme.input.placeholder};
    font-size: 14px;
  }
`

export const UserMenu = styled.div`
  svg {
    margin-right: 20px;
    height: 100%;
    width: 25px;
    color: ${props => props.theme.input.placeholder};
    cursor: pointer;
  }
`

export const Icon = styled.div`
  width: 50px;
  height: 50px;

  & svg {
    cursor: pointer;
    background: white;
    width: 50px;
    height: 50px;
    padding: 15px;
    border-radius: 50%;
    color: ${props => props.theme.text.primary};
  }
`

export const ResultsContainer = styled.div`
  position: absolute;
  z-index: 999;
  width: 500px;
  height: 500px;
  background: white;
  border-radius: 0px 0px 5px 5px;
  top: 48px;
  left: -1px;
  border-right: 1px solid ${props => props.theme.input.border};
  border-left: 1px solid ${props => props.theme.input.border};
  border-bottom: 1px solid ${props => props.theme.input.border};

  //media query
  @media (max-width: 1000px) {
    width: 90vw;
  }
`

export const Type = styled.div`
  border-bottom: 2px solid #f4f4f4;
  margin-top: 10px;
  padding: 0 30px;
  display: flex;
  gap: 25px;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
`

export const TypeOption = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  font-weight: 500;
  padding: 5px 10px;
  margin-bottom: 15px;
  border-radius: 5px;
  background: ${props =>
    props.active ? props.theme.sidebar.link_background_active : props.theme.general.background};
  transition: all 0.3s;
  cursor: pointer;

  &:hover {
    background: ${props => props.theme.sidebar.link_background_active};
  }

  & p {
    font-size: 13px;
    color: ${props =>
      props.active ? props.theme.sidebar.link_active : props.theme.text.primary_soft};
  }

  & svg {
    margin-right: 0;
    width: 15px;
    height: 15px;
  }
`

export const ResultsData = styled.div`
  margin-top: 10px;
  overflow-y: scroll;
  height: 83%;

  scrollbar-width: thin;
  scrollbar-color: #babac0 rgba(0, 0, 0, 0);

  &::-webkit-scrollbar {
    background-color: rgba(0, 0, 0, 0);
    width: 15px;
    border-radius: 10px;
    opacity: 0;
  }
  &::-webkit-scrollbar-track {
    background-color: rgba(0, 0, 0, 0);
    color: rgba(0, 0, 0, 0);
    opacity: 0;
    border-radius: 10px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #babac0;
    border-radius: 16px;
    border: 4px solid #fff;
  }
`

export const Option = styled(Link)`
  margin: 10px 25px;
  padding: 10px;
  border-radius: 5px;
  display: flex;
  gap: 20px;
  cursor: pointer;
  transition: all 0.3s;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */

  &:hover {
    background: ${props => props.theme.sidebar.link_hover};
  }

  & img {
    width: 40px;
    height: 40px;
    border-radius: 8px;
  }

  & div {
    h1 {
      font-size: 14px;
    }

    p {
      margin-top: 3px;
      font-size: 12px;
    }
  }
`

export const OptionNoPhoto = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 8px;
  background: ${props => props.theme.sidebar.link_background_active};
`

export const MobileContainer = styled.div`
  display: none;

  & > svg {
    border: 1px solid ${props => props.theme.sidebar.button_border};
    background: ${props => props.theme.sidebar.button_background};
    border-radius: 40px;
    width: 40px;
    height: 40px;
    padding: 10px;
    cursor: pointer;
    transition: 0.3s all;

    &:hover {
      background: ${props => props.theme.sidebar.button_background_hover};
    }
  }

  img {
    height: 40px;
  }

  @media (max-width: 1000px) {
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 100%;
    position: relative;
  }
`

export const MobileMenu = styled.div`
  position: absolute;
  top: 77px;
  z-index: 999;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background: white;
  border-bottom: 1px solid ${props => props.theme.input.border};

  & > nav {
    width: 90%;
    margin: 20px 0;
  }
`
