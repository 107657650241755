import React from 'react'
import { ContainerFlex, Description, Title } from '../../components'

export const NoPermission = () => {
  return (
    <ContainerFlex margin='40px' gap='10px' direction='column'>
      <Title>Permissão Negada</Title>
      <Description>
        Atualmente você não tem permissão para acessar essa página! Caso acredite que seja um erro,
        entre em contato com os administradores
      </Description>
    </ContainerFlex>
  )
}
