import styled from 'styled-components'

export const Table = styled.table`
  width: 100%;
  overflow: hidden;
  border-collapse: collapse;

  th,
  td {
    padding: 0 25px;
  }

  thead tr {
    height: 30px;
    text-align: left;
  }

  th {
    font-size: 12px;
    height: 50px;
    cursor: pointer;
    font-weight: 600;
    color: ${props => props.theme.table.header_text};
    background: white;
    border-bottom: 1px solid ${props => props.theme.table.border};
  }

  td {
    color: ${props => props.theme.table.text};
    font-size: 14px;
    height: 50px;
    transition: 0.3s all;
    border-bottom: 1px solid ${props => props.theme.table.border};
  }

  .center {
    text-align: center;
  }

  tbody tr {
    cursor: pointer;
    transition: 0.3s all;

    &:hover {
      background: ${props => props.theme.table.hover};
    }

    &:hover td {
      color: ${props => props.theme.table.hover_text};
    }
  }

  tr:nth-child(even) {
    background: #fff;
  }

  svg {
    width: 20px;
    height: 20px;
    transition: 0.3s all;

    &.action {
      border: 1px solid ${props => props.theme.table.icon_border};
      border-radius: 50%;
      padding: 5px;

      &:hover {
        background: ${props => props.theme.table.icon_background_hover};
        color: ${props => props.theme.table.icon_color_hover};
        border: 1px solid ${props => props.theme.table.icon_background_hover};
      }
    }

    &.enabled {
      color: ${props => props.theme.table.icon_active};
    }

    &.disabled {
      color: ${props => props.theme.table.icon_disabled};
    }
  }
`
