import styled from 'styled-components'

export const LogoContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 10px;
  & img {
    width: 250px;
  }
`

export const ImagesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;

  & img {
    height: 250px;
  }
`

export const Main = styled.div`
  display: flex;
  gap: 20px;
  margin: 20px;
  flex-direction: column;
  max-width: 1024px;

  & h1 {
    padding-top: 20px;
  }

  & p {
    font-size: 18px;
  }
`

export const Map = styled.div`
  width: 100%;
  height: 250px;
`
