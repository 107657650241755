import React, { useState, useEffect, useContext } from 'react'
import { fieldErrorCheck } from '../../components/MultiStep/handleFunctions'
import {
  Title,
  Description,
  ContainerFlexResponsive,
  Button,
  Checkbox,
  TextInput,
  SecondaryButton,
  BreadCrumb,
  BreadCrumbLink,
  BreadCrumbLabel,
  Loader,
  SelectInputValue,
  Textbox,
  ButtonLoader
} from '../../components'
import { helper } from './helper'
import { useParams } from 'react-router-dom'
import { getPropertyById, renewProperty } from '../../services/requests'
import { RiArrowDropRightLine } from 'react-icons/ri'
import { useHistory } from 'react-router-dom/'
import moment from 'moment'
import { AuthContext } from '../../context/AuthContext'

export const PropertyRenew = () => {
  const [checkError, setCheckError] = useState(false)

  let forSaleFields = ['price_sale']
  let forRentFields = ['price_rent', 'fire_insurance']
  let forSeasonFields = ['price_season', 'cleaning_fee']

  let { id } = useParams()
  let { auth_state } = useContext(AuthContext)
  let history = useHistory()

  const [state, setState] = useState(initialState)
  const [loading, setLoading] = useState(false)
  const [expirated, setExpirated] = useState(null)

  const getData = async () => {
    try {
      let { data } = await getPropertyById(id)

      helper(data, setState)

      let today = moment()
      let date = moment(data.expiration_date).add(1, 'days')

      setExpirated(date.diff(today) < 0)
    } catch (err) {
      alert('Erro ao carregar os dados!')
      console.log(err)
    }
  }

  const update = async () => {
    if (!loading) {
      setLoading(true)
      try {
        const data = {
          ...state,
          employee_id: auth_state.id,
          expiration_date: moment(new Date())
            .add(state.expiration_date_count, 'days')
            .format('YYYY-MM-DD')
        }

        await renewProperty(id, data)
        alert('Dados atualizados com sucesso!')
        history.push(`/imovel/${id}`)
      } catch (err) {
        alert('Erro ao atualizar os dados!')
        console.log(err)
      }
    }
  }

  const handleCheckBox = name => setState({ ...state, [name]: !state[name] })

  const handleSelection = (name, selection) => setState({ ...state, [name]: selection })

  const handleEvent = event => setState({ ...state, [event.target.name]: event.target.value })

  const clearField = (name, type) => {
    type === 1 ? setState({ ...state, [name]: '' }) : setState({ ...state, [name]: null })
  }

  useEffect(() => {
    getData()
    // eslint-disable-next-line
  }, [])

  return (
    <>
      <ContainerFlexResponsive
        align='center'
        justify='space-between'
        margin='20px 0 20px'
        resp_direction='column'
      >
        <BreadCrumb>
          <BreadCrumbLink to={`/imovel/${id}`}>Imóvel {id}</BreadCrumbLink>
          <RiArrowDropRightLine />
          <BreadCrumbLabel>Edição</BreadCrumbLabel>
        </BreadCrumb>
      </ContainerFlexResponsive>
      <Title>Renovação de Propriedade</Title>
      {expirated !== null && (
        <Description>
          Esse Imóvel {expirated ? 'expirou' : 'expira'} no dia{' '}
          {moment(state.expiration_date).format('DD/MM/yyyy')}
        </Description>
      )}

      {state.id_property && (
        <ContainerFlexResponsive
          gap='50px'
          margin='0 auto 45px'
          maxWidth='700px'
          resp_direction='column'
        >
          <Checkbox
            text='Para Venda'
            name='for_sale'
            state={state.for_sale}
            handleChange={name => handleCheckBox(name)}
          />
          <Checkbox
            text='Para Aluguel'
            name='for_rent'
            state={state.for_rent}
            handleChange={name => handleCheckBox(name)}
          />
          <Checkbox
            text='Para Temporada'
            name='for_season'
            state={state.for_season}
            handleChange={name => handleCheckBox(name)}
          />
        </ContainerFlexResponsive>
      )}

      {state.for_sale && state.id_property && (
        <>
          <ContainerFlexResponsive
            gap='50px'
            margin='0 auto 45px'
            maxWidth='700px'
            resp_direction='column'
          >
            <TextInput
              text='Valor de Venda'
              name='sale_price'
              type='number'
              value={state.sale_price}
              handleChange={event => handleEvent(event)}
              size='small'
              error={checkError ? state.sale_price === '' || state.sale_price === null : false}
              clear={name => clearField(name, 1)}
            />

            <TextInput
              text='Valor de IPTU'
              name='iptu'
              type='number'
              value={state.iptu}
              handleChange={event => handleEvent(event)}
              error={false}
              clear={name => clearField(name, 1)}
            />

            <Checkbox
              text='Financiável'
              name='is_financeable'
              state={state.is_financeable}
              handleChange={name => handleCheckBox(name)}
            />
            <Checkbox
              text='Aceita Permuta'
              name='accepts_exchange'
              state={state.accepts_exchange}
              handleChange={name => handleCheckBox(name)}
            />
          </ContainerFlexResponsive>
        </>
      )}

      {state.for_rent && state.id_property && (
        <>
          <ContainerFlexResponsive
            gap='50px'
            margin='0 auto 45px'
            maxWidth='700px'
            resp_direction='column'
          >
            <TextInput
              type='number'
              text='Valor de Aluguel'
              name='rent_price'
              value={state.rent_price}
              handleChange={event => handleEvent(event)}
              size='small'
              error={checkError ? state.rent_price === '' || state.rent_price === null : false}
              clear={name => clearField(name, 1)}
            />
            <TextInput
              type='number'
              text='Seguro Incêndio'
              name='fire_insurance'
              value={state.fire_insurance}
              handleChange={event => handleEvent(event)}
              size='small'
              error={
                checkError ? state.fire_insurance === '' || state.fire_insurance === null : false
              }
              clear={name => clearField(name, 1)}
            />
          </ContainerFlexResponsive>
        </>
      )}

      {state.for_season && state.id_property && (
        <>
          <ContainerFlexResponsive
            gap='50px'
            margin='0 auto 45px'
            maxWidth='700px'
            resp_direction='column'
          >
            <TextInput
              type='number'
              text='Valor da Diária'
              name='season_price'
              value={state.season_price}
              handleChange={event => handleEvent(event)}
              size='small'
              error={checkError ? state.season_price === '' || state.season_price === null : false}
              clear={name => clearField(name, 1)}
            />
            <TextInput
              type='number'
              text='Taxa de Limpeza'
              name='cleaning_fee'
              value={state.cleaning_fee}
              handleChange={event => handleEvent(event)}
              size='small'
              error={checkError ? state.cleaning_fee === '' || state.cleaning_fee === null : false}
              clear={name => clearField(name, 1)}
            />
          </ContainerFlexResponsive>
        </>
      )}

      {state.id_property && (
        <ContainerFlexResponsive
          margin='0 auto 45px'
          maxWidth='700px'
          gap='50px'
          resp_direction='column'
        >
          <Checkbox
            text='Exclusividade'
            name='is_exclusive'
            state={state.is_exclusive}
            handleChange={name => handleCheckBox(name)}
          />

          <SelectInputValue
            text='Renovar Por *'
            name='expiration_date_count'
            value={state.expiration_date_count}
            options={[
              { id: 30, name: '30 dias' },
              { id: 60, name: '60 dias' },
              { id: 90, name: '90 dias' }
            ]}
            size='small'
            handleSelection={(name, selection) => handleSelection(name, selection)}
            error={checkError ? state.expiration_date_count === null : false}
            clear={name => clearField(name, 2)}
          />
          <Textbox
            text='Observações'
            name='renew_observation'
            value={state.renew_observation}
            handleChange={event => handleEvent(event)}
            size='big'
            error={checkError ? state.price_season === '' || state.price_season === null : false}
            clear={name => clearField(name, 1)}
          />
        </ContainerFlexResponsive>
      )}

      {!state.id_property && <Loader margin='20vh auto' />}

      <ContainerFlexResponsive
        justify='space-between'
        resp_direction='column'
        margin='0 auto 45px'
        maxWidth='700px'
        resp_gap='20px'
      >
        <SecondaryButton size='small' onClick={() => history.push(`/imovel/${id}`)}>
          Voltar
        </SecondaryButton>
        <Button
          size='small'
          onClick={() => {
            let fields = ['expiration_date_count']
            if (state.for_sale) fields = [...fields, ...forSaleFields]
            if (state.for_rent) fields = [...fields, ...forRentFields]
            if (state.for_season) fields = [...fields, ...forSeasonFields]
            fieldErrorCheck(state, fields) ? setCheckError(true) : update()
          }}
        >
          {loading ? <ButtonLoader /> : 'Finalizar Edição'}
        </Button>
      </ContainerFlexResponsive>
    </>
  )
}

const initialState = {
  id_property: null,
  for_sale: false,
  for_rent: false,
  for_season: false,
  condominium_fee: null,
  sale_price: null,
  rent_price: null,
  season_price: null,
  iptu: null,
  cleaning_fee: null,
  is_financeable: false,
  accepts_exchange: false,
  fire_insurance: null,
  expiration_date_count: null,
  renew_observation: '',
  id_characteristics: null
}
