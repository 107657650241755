import React, { useState, forwardRef, useEffect, useContext } from 'react'
import { useParams } from 'react-router'
import axios from 'axios'
import { AiOutlineEdit } from 'react-icons/ai'
import { RiArrowDropRightLine } from 'react-icons/ri'
import { IoMdClose } from 'react-icons/io'
import {
  BreadCrumb,
  Title,
  ContainerFlexResponsive,
  SelectDate,
  SelectInputValue,
  Table,
  Subtitle,
  SecondaryButton,
  Button,
  ButtonLoader,
  BreadCrumbLabel,
  BreadCrumbLink
} from '../../components'
import {
  deleteReservation,
  getClients,
  getReservations,
  makeReservation,
  updateReservation
} from '../../services/requests'
import { ChangesControl, DatePickerContainer, ReservationInfo, Item } from './styles.js'
import moment from 'moment'
import DatePicker, { registerLocale } from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import pt from 'date-fns/locale/pt'
import { AuthContext } from '../../context/AuthContext'
registerLocale('pt', pt)

export const PropertyReservation = props => {
  let { id } = useParams()
  const { auth_state } = useContext(AuthContext)
  const [operation, setOperation] = useState(false)
  const [loading, setLoading] = useState(false)

  const [checkErrors, setCheckErrors] = useState(false)
  const [startDate, setStartDate] = useState(new Date())
  const [selectedStartDate, setSelectStartDate] = useState(false)
  const [endDate, setEndDate] = useState(new Date())
  const [selectedEndDate, setSelectEndDate] = useState(false)

  const [client, setClient] = useState(null)
  const [clients, setClients] = useState(null)

  const [reservations, setReservations] = useState(null)
  const [reservation, setReservation] = useState(null)
  const [deleting, setDeleting] = useState(false)

  const initialData = async () => {
    try {
      let promises = await axios.all([getClients({ page_items: 'MAX_VALUE' }), getReservations(id)])
      setClients(promises[0].data.clients)
      setReservations(promises[1].data)
    } catch (e) {
      alert('Erro ao carregar os dados')
      console.log(e)
    }
  }

  const SelectComponent = forwardRef(({ value, onClick, text, type, error }, ref) => (
    <SelectDate
      handleClick={onClick}
      ref={ref}
      value={handleDate(value, type)}
      text={`Data de ${text}`}
      error={error}
    />
  ))

  const handleDateSelection = (type, date) => {
    if (type === 'start') {
      setStartDate(date)
      setSelectStartDate(true)
      if (operation.type !== 'edit') setEndDate(date)
    } else {
      setEndDate(date)
      setSelectEndDate(true)
    }
  }

  const handleDate = (value, type) => {
    if (type === 'start')
      if (selectedStartDate) return moment(value).format('DD/MM/YYYY')
      else return null
    else if (selectedEndDate) return moment(value).format('DD/MM/YYYY')
    else return null
  }

  const handleReservation = async () => {
    if (selectedStartDate && selectedEndDate && client) {
      setCheckErrors(false)
      setLoading(true)
      try {
        let data = [
          id,
          client,
          auth_state.id,
          moment(startDate).format('YYYY-MM-DD'),
          moment(endDate).format('YYYY-MM-DD')
        ]

        if (operation.type === 'new') {
          await makeReservation(data[0], data[1], data[2], data[3], data[4])
          alert('Reserva realizada com sucesso')
        } else {
          await updateReservation(reservation.id, data[2], data[3], data[4])
          alert('Reserva atualizada com sucesso')
        }

        let res = await getReservations(id)
        setReservations(res.data)
        resetOperation()
        setLoading(false)
      } catch (e) {
        alert('Erro ao reservar a propriedade')
        console.log(e)
      }
    } else {
      setCheckErrors(true)
      alert('Preencha todos os campos')
    }
  }

  const removeReservation = async res => {
    if (window.confirm(`Tem certeza de que deseja excluir a reserva ${res.id} ?`)) {
      setDeleting(res)
      try {
        await deleteReservation(res.id)
        let { data } = await getReservations(id)
        setReservations(data)
        alert('Reserva excluida com sucesso')
      } catch (e) {
        alert('Erro ao excluir a reserva')
        console.log(e)
      }
      setDeleting(false)
    }
  }

  const handleOperation = (type, res) => {
    if (type === 'new') {
      setOperation({
        type: 'new',
        description: false,
        text: 'Adicionar Reserva'
      })
    } else {
      setOperation({
        type: 'edit',
        description: true,
        text: 'Editar Reserva'
      })

      setStartDate(new Date(moment(res.initial_date).add(1, 'days')))
      setEndDate(new Date(moment(res.final_date).add(1, 'days')))
      setClient(res.client_id)
      setSelectStartDate(true)
      setSelectEndDate(true)
      setReservation(res)
    }
  }

  const resetOperation = () => {
    setOperation(false)
    setCheckErrors(false)
    setSelectStartDate(false)
    setSelectEndDate(false)
    setClient(null)
    setStartDate(new Date())
    setEndDate(new Date())
    setReservation(null)
  }

  const handleDateAgo = () => {
    let result = moment(startDate).subtract(1, 'days').from(endDate).split(' ')[0]

    if (result === 'a') return '1 dia'
    else return `${result} dias`
  }

  useEffect(() => {
    initialData()
    // eslint-disable-next-line
  }, [id])

  return (
    <ContainerFlexResponsive margin='20px 0' direction='column'>
      <BreadCrumb>
        <BreadCrumbLink to={`/imovel/${id}`}>Imóvel {id}</BreadCrumbLink>
        <RiArrowDropRightLine />
        <BreadCrumbLabel>Reservas</BreadCrumbLabel>
      </BreadCrumb>

      <ContainerFlexResponsive direction='column'>
        <Title margin='20px 0'>Gerenciar Reservas</Title>

        {!operation && (
          <Button margin='0 0 20px' onClick={() => handleOperation('new')}>
            Adicionar Reserva
          </Button>
        )}

        {operation && (
          <ContainerFlexResponsive direction='column'>
            <Subtitle margin='0 0 10px'>{operation.text}</Subtitle>

            <ReservationInfo>
              {reservation && <p>ID da reserva: {reservation.id}</p>}
              {operation.type === 'edit' && <p>Cliente: {reservation.client_name}</p>}
              {selectedStartDate && selectedEndDate && <p>Reserva de {handleDateAgo()}</p>}
            </ReservationInfo>

            <DatePickerContainer
              gap='20px'
              resp_align='center'
              resp_direction='column'
              align='center'
              margin='0 0 30px 0'
            >
              <DatePicker
                selected={startDate}
                onChange={date => handleDateSelection('start', date)}
                selectsStart
                startDate={startDate}
                endDate={endDate}
                customInput={
                  <SelectComponent
                    text='Entrada'
                    type='start'
                    error={checkErrors ? !selectedStartDate : false}
                  />
                }
              />
              <DatePicker
                selected={endDate}
                onChange={date => handleDateSelection('end', date)}
                selectsEnd
                startDate={startDate}
                endDate={endDate}
                minDate={startDate}
                customInput={
                  <SelectComponent
                    text='Saída'
                    type='end'
                    error={checkErrors ? !selectedEndDate : false}
                  />
                }
              />
              <SelectInputValue
                text='Cliente'
                options={clients}
                name='client'
                value={client}
                size='small'
                handleSelection={(client, clientId) => setClient(clientId)}
                clear={() => setClient(null)}
                error={checkErrors ? !client : false}
              />
            </DatePickerContainer>
            <ContainerFlexResponsive gap='20px' margin='0 0 20px'>
              <SecondaryButton size='small' onClick={resetOperation}>
                Cancelar
              </SecondaryButton>
              <Button size='small' onClick={handleReservation}>
                {loading ? <ButtonLoader /> : operation.text}
              </Button>
            </ContainerFlexResponsive>
          </ContainerFlexResponsive>
        )}
      </ContainerFlexResponsive>
      <ContainerFlexResponsive direction='column' width='100%' margin='0 auto'>
        <Table>
          <thead>
            <tr>
              <th>Código</th>
              <th>Cliente</th>
              <th>Data de Entrada</th>
              <th>Data de Saída</th>
              <th>Corretor</th>
              <th>Editar</th>
              <th>Excluir</th>
            </tr>
          </thead>
          <tbody>
            {reservations &&
              reservations.map(reservation => {
                return (
                  <tr key={reservation.id}>
                    <td>{reservation.id}</td>
                    <td>{reservation.client_name}</td>
                    <td>{moment(reservation.initial_date).add(1, 'days').format('DD/MM/YYYY')}</td>
                    <td>{moment(reservation.final_date).add(1, 'days').format('DD/MM/YYYY')}</td>
                    <td>{reservation.created_by_name}</td>
                    <td>
                      <AiOutlineEdit
                        className='action'
                        onClick={() => handleOperation('edit', reservation)}
                      />
                    </td>
                    <td>
                      <IoMdClose
                        className='action'
                        onClick={() => removeReservation(reservation)}
                      />
                    </td>
                  </tr>
                )
              })}
          </tbody>
        </Table>
      </ContainerFlexResponsive>
      {deleting && (
        <ChangesControl>
          <Item>
            <IoMdClose />
            <p>Excluindo reserva {deleting.id}</p>
            <div class='spinner'>
              <div class='double-bounce1'></div>
              <div class='double-bounce2'></div>
            </div>
          </Item>
        </ChangesControl>
      )}
    </ContainerFlexResponsive>
  )
}
