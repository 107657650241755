import styled from 'styled-components'
import { Link } from 'react-router-dom'

export const BreadCrumb = styled.div`
  display: flex;
  align-items: center;

  & label {
    font-size: 13px;
    font-weight: 500;
    color: ${props => props.theme.text.primary_soft};
    user-select: none;
    transition: 0.3s all;

    &.link {
      cursor: pointer;

      &:hover {
        color: ${props => props.theme.button.default_background};
      }
    }
  }

  & svg {
    width: 15px;
    height: 15px;
    padding-top: 1px;
    color: ${props => props.theme.text.primary_soft};
  }
`

export const BreadCrumbLabel = styled.label`
  font-size: 13px;
  font-weight: 500;
  color: ${props => props.theme.text.primary_soft};
  user-select: none;
`

export const BreadCrumbLink = styled(Link)`
  font-size: 13px;
  font-weight: 500;
  color: ${props => props.theme.text.primary_soft};
  user-select: none;
  transition: 0.3s all;

  &:hover {
    color: ${props => props.theme.button.default_background};
  }
`
