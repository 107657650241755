import React, { useState, useContext } from 'react'
import { NavLink } from 'react-router-dom'
import regular from '../../assets/images/logo.png'
import small from '../../assets/images/logo_simple.png'
import {
  MdKeyboardArrowLeft,
  MdKeyboardArrowRight,
  MdKeyboardArrowDown,
  MdDashboard
} from 'react-icons/md'
import { AiFillHome, AiFillSetting } from 'react-icons/ai'
import { BsFillPersonFill } from 'react-icons/bs'
import {
  FixedContainer,
  Header,
  LinkSubMenuContainer,
  Navigation,
  SubMenu,
  UserContainer,
  CollapsedNavigation
} from './styles'
import { ContainerFlex } from '../StyledComponents/Core/Container'
import { AuthContext } from '../../context/AuthContext'

export const SidebarMenu = ({ children, open, handleClose }) => {
  const { auth_state, auth_dispatch } = useContext(AuthContext)

  return (
    <FixedContainer open={open}>
      <Header open={open}>
        {open ? (
          <MdKeyboardArrowLeft onClick={() => handleClose()} />
        ) : (
          <MdKeyboardArrowRight onClick={() => handleClose()} />
        )}
        <img src={open ? regular : small} alt='logo' />
      </Header>

      {open && <Nav access_levels={auth_state.access_levels} />}

      {!open && (
        <CollapsedNavigation>
          <NavLink to='/' exact>
            <MdDashboard className='icon' />
          </NavLink>
          <NavLink to='/imoveis'>
            <AiFillHome className='icon' />
          </NavLink>
          <NavLink to='/clientes' exact>
            <BsFillPersonFill className='icon' />
          </NavLink>
          <NavLink to='/admin/comodidades'>
            <AiFillSetting className='icon' />
          </NavLink>
        </CollapsedNavigation>
      )}

      {auth_state.user && (
        <UserContainer>
          <BsFillPersonFill />
          {open && (
            <div>
              <h3>{auth_state.name}</h3>
              <p>Nível de acesso: {auth_state.access_levels}</p>
              <p className='disconnect' onClick={() => auth_dispatch({ type: 'logout' })}>
                Desconectar
              </p>
            </div>
          )}
        </UserContainer>
      )}
    </FixedContainer>
  )
}

export const Nav = ({ handleClick, access_levels }) => {
  const [properties, setProperties] = useState(true)
  const [clients, setClients] = useState(true)
  const [admin, setAdmin] = useState(false)

  return (
    <Navigation>
      <NavLink to='/' exact onClick={handleClick}>
        <MdDashboard className='icon' />
        Página Principal
      </NavLink>
      <LinkSubMenuContainer onClick={() => setProperties(!properties)}>
        <ContainerFlex align='center'>
          <AiFillHome className='icon' />
          Imóveis
        </ContainerFlex>
        {properties ? (
          <MdKeyboardArrowDown className='arrow' />
        ) : (
          <MdKeyboardArrowRight className='arrow' />
        )}
      </LinkSubMenuContainer>
      {properties && (
        <SubMenu>
          <NavLink to='/imoveis' exact onClick={handleClick}>
            Todos Imóveis
          </NavLink>
          <NavLink to='/imoveis/corretor' exact onClick={handleClick}>
            Meus Imóveis
          </NavLink>
          <NavLink to='/novo/imovel' exact onClick={handleClick}>
            Cadastrar Imóvel
          </NavLink>
        </SubMenu>
      )}

      <LinkSubMenuContainer onClick={() => setClients(!clients)}>
        <ContainerFlex align='center'>
          <BsFillPersonFill className='icon' />
          Clientes
        </ContainerFlex>
        {clients ? (
          <MdKeyboardArrowDown className='arrow' />
        ) : (
          <MdKeyboardArrowRight className='arrow' />
        )}
      </LinkSubMenuContainer>
      {clients && (
        <SubMenu>
          <NavLink to='/clientes' exact onClick={handleClick}>
            Todos Clientes
          </NavLink>
          <NavLink to='/novo/cliente' exact onClick={handleClick}>
            Cadastrar Cliente
          </NavLink>
        </SubMenu>
      )}

      {access_levels < 3 && (
        <LinkSubMenuContainer onClick={() => setAdmin(!admin)}>
          <ContainerFlex align='center'>
            <AiFillSetting className='icon' />
            Administração
          </ContainerFlex>
          {admin ? (
            <MdKeyboardArrowDown className='arrow' />
          ) : (
            <MdKeyboardArrowRight className='arrow' />
          )}
        </LinkSubMenuContainer>
      )}
      {admin && (
        <SubMenu>
          <NavLink to='/admin/funcionarios' exact onClick={handleClick}>
            Gerenciar Funcionários
          </NavLink>
          <NavLink to='/admin/tipo_imovel' exact onClick={handleClick}>
            Gerenciar Tipos de Imóvel
          </NavLink>
          <NavLink to='/admin/comodidades' exact onClick={handleClick}>
            Gerenciar Comodidades
          </NavLink>
          <NavLink to='/admin/logradouro' exact onClick={handleClick}>
            Gerenciar Logradouros
          </NavLink>
          <NavLink to='/admin/bairros' exact onClick={handleClick}>
            Gerenciar Bairros
          </NavLink>
          <NavLink to='/admin/profissoes' exact onClick={handleClick}>
            Gerenciar Profissões
          </NavLink>
          <NavLink to='/admin/estado_civil' exact onClick={handleClick}>
            Gerenciar Estado Civil
          </NavLink>
        </SubMenu>
      )}
    </Navigation>
  )
}
