import React, { useRef, useState, useEffect } from 'react'
import { AiOutlinePlus } from 'react-icons/ai'
import { BiImageAdd } from 'react-icons/bi'
import styled from 'styled-components'

const Container = styled.div`
  background: #dbdbdb;
  border-radius: 50%;
  width: 200px;
  height: 200px;
  margin: 0 auto;
  cursor: pointer;
  display: flex;
  align-items: center;
  position: relative;

  & svg {
    margin: 0 auto;
    color: #4a4a4a;
    width: 20px;
    height: 20px;
  }

  & input {
    display: none;
  }

  & img {
    width: 200px;
    height: 200px;
    object-fit: cover;
    border-radius: 50%;
  }
`

const Mask = styled.div`
  transition: all 0.3s ease;
  background: rgba(0, 0, 0, 0);
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  border-radius: 50%;
  align-items: center;
  padding: 0 40px;
  display: flex;
  justify-content: space-around;
  backdrop-filter: blur(0);

  &:hover {
    background: rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(0.7px);
  }

  & svg {
    transition: all 0.3s ease;
    opacity: 0;
    cursor: pointer;
    width: 30px;
    height: 30px;
    color: white;
  }

  &:hover svg {
    opacity: 1;
  }
`

export const PhotoUpload = ({ action, state }) => {
  const ref = useRef()
  const [photo, setPhoto] = useState(null)

  useEffect(() => {
    if (state.photo && state.photo !== '') {
      setPhoto(state.photo)
    }
    // eslint-disable-next-line
  }, [])

  const handleChange = event => {
    setPhoto(URL.createObjectURL(event.target.files[0]))
    action(event)
  }

  return (
    <Container onClick={() => ref.current.click()}>
      {photo && <img src={photo} alt='Foto de Perfil' />}
      {photo && (
        <Mask>
          <BiImageAdd />
        </Mask>
      )}
      {!photo && <AiOutlinePlus />}
      <input type='file' accept='image/*' onChange={handleChange} ref={ref} />
    </Container>
  )
}
