import styled from 'styled-components'

export const Main = styled.main`
  margin: 20px 0 50px;
`

export const Section = styled.section`
  display: flex;
  align-content: center;
  gap: 20px;
`
