import styled from 'styled-components'
import { Input as InputCore, Title as TitleCore, Label as LabelCore } from '../../components'

export const Container = styled.div`
  svg.logo {
    height: 75px;
    width: 100%;
    margin-bottom: 20px;
  }

  width: 400px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100vh;

  //media query
  @media (max-width: 768px) {
    width: 70%;

    & form input {
      width: 100%;
    }
  }
`

export const Title = styled(TitleCore)`
  color: ${props => props.theme.button.primary};
  font-size: 24px;
  margin: 20px 0 25px;
`

export const Form = styled.form`
  width: 100%;
  & h1 {
    margin-bottom: 20px;
    margin-left: 2px;
  }
`

export const Label = styled(LabelCore)`
  display: block;
  margin: 20px 5px 15px;
`

export const Input = styled(InputCore)`
  width: 100%;
`

export const ShowPassword = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
  margin: 20px 0;
  cursor: pointer;

  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: -moz-none;
  -o-user-select: none;
  user-select: none;
`

export const CheckButton = styled.span`
  position: absolute;
  top: -1px;
  left: -30px;
  height: 20px;
  width: 20px;
  border-radius: 3px;
  background-color: white;
  transition: 0.4s all;

  &:after {
    content: '';
    position: absolute;
    display: none;
  }

  &:after {
    left: 7px;
    top: 5px;
    width: 3px;
    height: 5px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
`

export const Loader = styled.div`
  width: 100%;
  text-align: center;

  & > div {
    width: 12px;
    height: 12px;
    margin-right: 5px;
    background-color: ${props => props.theme.text.light};

    border-radius: 100%;
    display: inline-block;
    -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
    animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  }

  & .bounce1 {
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
  }

  & .bounce2 {
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
  }

  @-webkit-keyframes sk-bouncedelay {
    0%,
    80%,
    100% {
      -webkit-transform: scale(0);
    }
    40% {
      -webkit-transform: scale(1);
    }
  }

  @keyframes sk-bouncedelay {
    0%,
    80%,
    100% {
      -webkit-transform: scale(0);
      transform: scale(0);
    }
    40% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
  }
`
