import styled from 'styled-components'

import { Link } from 'react-router-dom'

export const Card = styled(Link)`
  width: 200px;
  cursor: pointer;
  background: white;
  border-radius: 10px;
  transition: all 0.4s;
  border: 1px solid ${props => props.theme.general.border};

  & span {
    font-size: 10px;
    margin: 2px 0 5px;
    color: ${props => props.theme.cards.subtext};
  }

  &:hover {
    border: 1px solid ${props => props.theme.general.border_hover};
  }

  @media (max-width: 500px) {
    width: 100%;
  }
`

export const Image = styled.img`
  width: 100%;
  height: 130px;
  object-fit: cover;
  border-radius: 10px 10px 0 0;
`

export const NoImage = styled.div`
  width: 100%;
  height: 130px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;

  & img {
    width: 100px;
  }
`

export const Title = styled.h3`
  font-size: 14px;
  font-weight: 500;
`
