import styled from 'styled-components'

export const IconButton = styled.div`
  border: 1px solid ${props => props.theme.input.border};
  height: 45px;
  cursor: pointer;
  transition: 0.3s all;
  border-radius: 5px;
  padding: 12px;
  gap: 10px;
  font-size: 14px;
  font-weight: 500;
  transition: 0.3s all;

  display: flex;
  align-items: center;
  justify-content: space-around;
  color: ${props => props.theme.input.placeholder};

  & > svg {
    height: 20px;
    width: 20px;
    color: ${props => props.theme.input.placeholder};
  }

  &:hover {
    border: 1px solid ${props => props.theme.input.border_hover};
  }
`
