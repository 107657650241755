import React, { useState, useEffect } from 'react'
import { useParams, useHistory } from 'react-router'
import { RiArrowDropRightLine } from 'react-icons/ri'
import {
  BreadCrumb,
  Title,
  ContainerFlexResponsive,
  Table,
  BreadCrumbLabel,
  BreadCrumbLink,
  Loader,
  Pagination,
  ContainerFlex
} from '../../../components'
import { getEmployeeChanges } from '../../../services/requests'
import moment from 'moment'

export const EmployeeChanges = props => {
  let { id } = useParams()
  let navigate = useHistory()

  const [history, setHistory] = useState(null)
  const [loading, setLoading] = useState(true)

  const page_items = 30
  const [page, setPage] = useState(0)
  const [total_pages, setTotalPages] = useState(0)

  const initialData = async () => {
    try {
      let employee_changes = await getEmployeeChanges(id, { page_items, page })
      setHistory(employee_changes.data.history)
      setTotalPages(employee_changes.data.total_pages)
      setLoading(false)
    } catch (e) {
      alert('Erro ao carregar os dados')
      console.log(e)
    }
  }

  const handleChangePage = async type => {
    setLoading(true)
    setHistory(null)

    if (type === 'next') {
      if (page !== total_pages) {
        setPage(page + 1)
      }
    } else if (type === 'prev') {
      if (page !== 0) {
        setPage(page - 1)
      }
    } else {
      setPage(type)
    }

    try {
      let employee_changes = await getEmployeeChanges(id, { page_items, page })
      setHistory(employee_changes.data.history)
    } catch (e) {
      alert('Erro ao carregar os dados')
      console.log(e)
    }
    setLoading(false)
  }

  useEffect(() => {
    initialData()
    // eslint-disable-next-line
  }, [id])

  return (
    <ContainerFlexResponsive margin='20px 0' direction='column'>
      <BreadCrumb>
        <BreadCrumbLink to={`/admin/funcionario/${id}`}>Funcionário {id}</BreadCrumbLink>
        <RiArrowDropRightLine />
        <BreadCrumbLabel>Alterações</BreadCrumbLabel>
      </BreadCrumb>

      <Title margin='20px 0 10px'>Histórico de Alterações</Title>

      {history && history.length !== 0 && (
        <ContainerFlexResponsive direction='column' width='100%' margin='0 auto'>
          <Table>
            <thead>
              <tr>
                <th>Imóvel</th>
                <th>Data de Alteração</th>
              </tr>
            </thead>
            <tbody>
              {history.map(item => {
                return (
                  <tr key={item.id} onClick={() => navigate.push(`/imovel/${item.properties_id}`)}>
                    <td>{item.properties_id}</td>
                    <td>{moment(item.altered_at).format('DD/MM/YYYY')}</td>
                  </tr>
                )
              })}
            </tbody>
          </Table>
        </ContainerFlexResponsive>
      )}

      {loading && <Loader margin='20vh auto' />}

      {!loading && history && history.length !== 0 && (
        <ContainerFlex margin='20px auto' width='100%' justify='center'>
          <Pagination
            pageCount={total_pages}
            handleChangePage={handleChangePage}
            currentPage={page}
          />
        </ContainerFlex>
      )}

      {!loading && history && history.length === 0 && (
        <p>Esse funcionário ainda não fez nenhuma alteração</p>
      )}
    </ContainerFlexResponsive>
  )
}
