import {
  createProperty,
  getCondominium,
  getPropertyTypes,
  getPublicAreas,
  getEmployees,
  getClients,
  getCommodities,
  updateProperty,
  getCities
} from '../services/requests'
import moment from 'moment'
import axios from 'axios'

export const getInitialPropertyData = async () => {
  let data
  try {
    let promises = await axios.all([
      getCondominium(),
      getPropertyTypes(),
      getPublicAreas(),
      getEmployees(),
      getCommodities(),
      getCities(),
      getClients({ page_items: 'MAX_VALUE' })
    ])

    data = {
      condominiums: promises[0].data,
      property_types: promises[1].data,
      public_area_options: promises[2].data,
      employees_options: promises[3].data,
      commodities_options: promises[4].data,
      cities: promises[5].data,
      clients: promises[6].data.clients
    }
  } catch (err) {
    console.log(err.response)
  }
  return data
}

const stateOrganization = state => {
  //Organize and remove unnecessary fields from state to send to API
  let obj = { ...state }
  delete obj.currentStep
  delete obj.id_property
  delete obj.observacoes_internas
  delete obj.public_area_options
  delete obj.employees_options
  delete obj.clients
  delete obj.condominiums
  delete obj.photos
  delete obj.property_types
  delete obj.commodities_options
  delete obj.cities
  delete obj.districts
  delete obj.favorite_image

  return obj
}

export const AddProperty = async state => {
  let obj = stateOrganization(state)

  obj = {
    ...obj,
    expiration_date: moment(new Date()).add(obj.expiration_date, 'days').format('YYYY-MM-DD')
  }

  return createProperty(obj)
}

export const updateProp = async state => {
  let obj = stateOrganization(state)

  delete obj.for_sale
  delete obj.for_rent
  delete obj.for_season
  delete obj.condominium_fee
  delete obj.sale_price
  delete obj.rent_price
  delete obj.season_price
  delete obj.iptu
  delete obj.cleaning_fee
  delete obj.is_financeable
  delete obj.accepts_exchange
  delete obj.fire_insurance
  delete obj.expired

  return updateProperty(state.id_property, obj)
}

export const PropertyState = {
  currentStep: 1,
  id_property: null,

  //General
  property_types_id: null,
  featured: false,
  is_publishable: false,
  bedrooms: null,
  suites: null,
  bathrooms: null,
  parking_space: null,
  status: 'Ativo',
  solar_position: null,
  building_area: '',
  land_area: '',
  front_area: '',
  back_area: '',
  description: '',
  field_orientation: null,

  //Address
  cep: '',
  state: '',
  city: '',
  district_id: null,
  address: '',
  number: '',
  complement: '',
  public_areas_id: null,
  latitude: null,
  longitude: null,

  //Financial
  for_sale: false,
  for_rent: false,
  for_season: false,
  condominium_fee: null,
  sale_price: null,
  rent_price: null,
  season_price: null,
  iptu: null,
  cleaning_fee: null,
  is_financeable: false,
  accepts_exchange: false,
  fire_insurance: null,

  //Internal
  owner_id: null,
  condominiums_id: null,
  responsable_employee_id: null,
  expiration_date: null,
  listing_broker_id: null,
  is_exclusive: false,
  has_board: false,

  observacoes_internas: null,

  //Features
  commodities: [],

  employee_id: null,
  references: null,

  //Media
  photos: [],

  //Options data
  employees_options: null,
  public_area_options: null,
  clients: null,
  condominiums: null,
  property_types: null,
  commodities_options: null,
  cities: null,
  districts: null,

  //control
  favorite_image: false
}
