import React, { useState, useEffect, forwardRef } from 'react'
import { getCities, getDistrictsByID, getPropertyTypes } from '../../services/requests'
import { optionsInput, pricesRange } from '../../utils/optionsInput'
import {
  Button,
  SecondaryButton,
  SelectDate,
  SelectInput,
  SelectInputValue,
  TextInput,
  ContainerGrid
} from '../'
import axios from 'axios'
import moment from 'moment'
import DatePicker, { registerLocale } from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import pt from 'date-fns/locale/pt'
registerLocale('pt', pt)

export const PropertiesFilter = ({ form_data, form_options, handleFilter }) => {
  const [form, setForm] = form_data
  const [formOptions, setFormOptions] = form_options
  const { quantity_filter } = optionsInput

  const [startDate, setStartDate] = useState(new Date())
  const [selectedStartDate, setSelectStartDate] = useState(false)
  const [endDate, setEndDate] = useState(new Date())
  const [selectedEndDate, setSelectEndDate] = useState(false)

  const handleInput = value => {
    setForm({ ...form, [value.target.name]: value.target.value })
  }

  const handleSelect = (name, value) => {
    setForm({ ...form, [name]: value })
  }

  const getInitialData = async update => {
    if (!formOptions.cities || !formOptions.districts || !formOptions.types || update) {
      try {
        let promises = await axios.all([
          getCities(),
          getDistrictsByID(form.city),
          getPropertyTypes()
        ])
        setFormOptions({
          ...formOptions,
          cities: promises[0].data,
          districts: promises[1].data,
          types: promises[2].data
        })
      } catch (err) {
        console.log(err)
        alert('Erro ao carregar dados')
      }
    }
  }

  const changeTransaction = (name, id) => {
    setForm({
      ...form,
      transaction: id,
      min: null,
      max: null,
      initial_date: null,
      final_date: null
    })
    setStartDate(new Date())
    setSelectStartDate(false)
    setEndDate(new Date())
    setSelectEndDate(false)
  }

  const SelectComponent = forwardRef(({ value, onClick, text, type, error }, ref) => (
    <SelectDate
      handleClick={onClick}
      ref={ref}
      value={handleDate(value, type)}
      text={`Data de ${text}`}
      error={error}
    />
  ))

  const handleChangeCity = async (name, value) => {
    setFormOptions({ ...formOptions, districts: null })
    setForm({ ...form, [name]: value })
    try {
      let promise = await getDistrictsByID(value)
      setFormOptions({ ...formOptions, districts: promise.data })
    } catch (err) {
      console.log(err)
      alert('Erro ao carregar dados')
    }
  }

  const handleDateSelection = (type, date) => {
    if (type === 'start') {
      setStartDate(date)
      setSelectStartDate(true)
      setForm({ ...form, initial_date: moment(date).format('YYYY-MM-DD') })
    } else {
      setEndDate(date)
      setSelectEndDate(true)
      setForm({ ...form, final_date: moment(date).format('YYYY-MM-DD') })
    }
  }

  const handleDate = (value, type) => {
    if (type === 'start')
      if (selectedStartDate) return moment(value).format('DD/MM/YYYY')
      else return null
    else if (selectedEndDate) return moment(value).format('DD/MM/YYYY')
    else return null
  }

  useEffect(() => {
    getInitialData(!formOptions.cities)
    // eslint-disable-next-line
  }, [])

  return (
    <ContainerGrid width='100%' min='200px' gap='15px' justify='center' align='center'>
      <SelectInputValue
        text='Modalidade'
        name='transaction'
        options={formOptions.transactions}
        value={form.transaction}
        handleSelection={changeTransaction}
      />

      <SelectInputValue
        text='Cidade'
        name='city'
        options={formOptions.cities}
        value={form.city}
        handleSelection={handleChangeCity}
        clear={name => {
          setForm({ ...form, [name]: 414 })
          handleChangeCity(name, 414)
        }}
      />

      <SelectInputValue
        text='Bairro'
        name='district'
        options={formOptions.districts}
        value={form.district}
        handleSelection={handleSelect}
        clear={name => setForm({ ...form, [name]: null })}
        clearButton={true}
      />

      <SelectInputValue
        text='Tipo de Imóvel'
        name='type'
        options={formOptions.types}
        value={form.type}
        handleSelection={handleSelect}
        clear={name => setForm({ ...form, [name]: null })}
        clearButton={true}
      />

      {form.transaction === 'season' && (
        <DatePicker
          selected={startDate}
          onChange={date => handleDateSelection('start', date)}
          selectsStart
          startDate={startDate}
          endDate={endDate}
          customInput={<SelectComponent text='Entrada' type='start' />}
        />
      )}

      {form.transaction === 'season' && (
        <DatePicker
          selected={endDate}
          onChange={date => handleDateSelection('end', date)}
          selectsEnd
          startDate={startDate}
          endDate={endDate}
          minDate={startDate}
          customInput={<SelectComponent text='Saída' type='end' />}
        />
      )}

      <SelectInputValue
        text='Quartos'
        options={quantity_filter}
        handleSelection={handleSelect}
        name='bedrooms'
        value={form.bedrooms}
        clear={name => setForm({ ...form, [name]: null })}
        clearButton={true}
      />

      <SelectInputValue
        text='Banheiros'
        options={quantity_filter}
        handleSelection={handleSelect}
        name='bathrooms'
        value={form.bathrooms}
        clear={name => setForm({ ...form, [name]: null })}
        clearButton={true}
      />
      <SelectInputValue
        text='Garagem'
        options={quantity_filter}
        handleSelection={handleSelect}
        name='parking_spaces'
        value={form.parking_spaces}
        clear={name => setForm({ ...form, [name]: null })}
        clearButton={true}
      />

      <SelectInput
        text='Valor Mínimo'
        name='min'
        value={form.min}
        options={pricesRange[form.transaction]}
        handleSelection={handleSelect}
        clear={name => setForm({ ...form, [name]: null })}
        clearButton={true}
      />

      <SelectInput
        text='Valor Máximo'
        name='max'
        value={form.max}
        options={pricesRange[form.transaction]}
        handleSelection={handleSelect}
        clear={name => setForm({ ...form, [name]: null })}
        clearButton={true}
      />

      <TextInput
        text='Endereço'
        name='address'
        value={form.address}
        handleChange={handleInput}
        clear={name => setForm({ ...form, [name]: '' })}
      />

      <TextInput
        text='Número'
        name='number'
        value={form.number}
        handleChange={handleInput}
        type='number'
        clear={name => setForm({ ...form, [name]: '' })}
      />

      <SecondaryButton
        onClick={() => {
          setForm({
            ...form,
            district: null,
            type: null,
            address: null,
            number: null,
            bedrooms: null,
            bathrooms: null,
            parking_spaces: null,
            min: null,
            max: null,
            initial_date: null,
            final_date: null,
            transaction: 'sale'
          })
          getInitialData(true)
        }}
      >
        Limpar Filtro
      </SecondaryButton>
      <Button onClick={handleFilter}>Filtrar Imóveis</Button>
    </ContainerGrid>
  )
}
