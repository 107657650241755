export const optionsInput = {
  quantity: [
    { id: 0, name: 'Nenhum' },
    { id: 1, name: '1' },
    { id: 2, name: '2' },
    { id: 3, name: '3' },
    { id: 4, name: '4' },
    { id: 5, name: '5' },
    { id: 6, name: '6' },
    { id: 7, name: '7' },
    { id: 8, name: '8' },
    { id: 9, name: '9' }
  ],
  quantity_filter: [
    { id: 0, name: 'Nenhum' },
    { id: 1, name: '1+' },
    { id: 2, name: '2+' },
    { id: 3, name: '3+' },
    { id: 4, name: '4+' },
    { id: 5, name: '5+' }
  ],
  field_orientation_options: ['Frente', 'Meio', 'Fundos'],
  solarPosition: ['Norte', 'Sul', 'Leste', 'Oeste']
}

export const pricesRange = {
  sale: [
    '50.000',
    '100.000',
    '150.000',
    '200.000',
    '250.000',
    '300.000',
    '350.000',
    '400.000',
    '450.000',
    '500.000',
    '550.000',
    '600.000',
    '650.000',
    '700.000',
    '750.000',
    '800.000',
    '850.000',
    '900.000',
    '950.000',
    '1.000.000',
    '1.500.000',
    '2.000.000',
    '2.500.000',
    '3.000.000'
  ],
  season: [
    '50',
    '100',
    '150',
    '200',
    '250',
    '300',
    '350',
    '400',
    '450',
    '500',
    '550',
    '600',
    '650',
    '700',
    '750',
    '800',
    '850',
    '900',
    '950',
    '1.000',
    '1.500',
    '2.000',
    '2.500',
    '3.000'
  ],

  rent: [
    '400',
    '450',
    '500',
    '550',
    '600',
    '650',
    '700',
    '750',
    '800',
    '850',
    '900',
    '950',
    '1.000',
    '1.500',
    '2.000',
    '2.500',
    '3.000',
    '3.500',
    '4.000',
    '4.500',
    '5.000'
  ]
}

export const tagsDecoder = (type, quantity) => {
  switch (type) {
    case 'bedrooms':
      return `${quantity} quarto${quantity !== 1 ? 's' : ''} ou mais`
    case 'bathrooms':
      return `${quantity} banheiro${quantity !== 1 ? 's' : ''} ou mais`
    case 'parking_spaces':
      return `${quantity} vaga${quantity !== 1 ? 's' : ''} ou mais`
    default:
      return quantity
  }
}
