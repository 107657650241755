import React, { useState } from 'react'
import {
  ContainerFlex,
  BreadCrumb,
  Title,
  TextInput,
  Button,
  Table,
  Description
} from '../../../components'
import { RiArrowDropRightLine } from 'react-icons/ri'
import { getDistricts, registerDistrict } from '../../../services/requests'
import axios from 'axios'

export const Districts = () => {
  const [input, setInput] = useState('')
  const [cep, setCep] = useState('')
  const [city, setCity] = useState(null)
  const [cityID, setCityID] = useState(null)
  const [data, setData] = useState(null)

  const getData = async () => {
    try {
      let res_districts = await getDistricts(city)
      setData(res_districts.data)
    } catch (err) {
      console.log(err)
    }
  }

  const handleSubmit = async () => {
    try {
      await registerDistrict(cityID, input)
      alert(`Bairro ${input} adicionado com sucesso!`)
      setInput('')
      getData()
    } catch (err) {
      console.log(err)
    }
  }

  const handleCEP = async event => {
    setCep(event.target.value)
    let value = String(event.target.value).replaceAll('_', '').replace('-', '')
    if (value.length === 8) {
      try {
        let res_city = await axios.get(`https://viacep.com.br/ws/${value}/json/`)
        setCity(res_city.data.localidade)
        let res_districts = await getDistricts(res_city.data.localidade)
        setData(res_districts.data)
        setCityID(res_districts.data[0].cities_id)
        console.log(res_city, res_districts)
      } catch (err) {
        alert('Erro no CEP, verifique se está correto')
        console.log(err)
      }
    }
  }

  return (
    <ContainerFlex margin='20px 0 25px' gap='20px' direction='column'>
      <BreadCrumb>
        <label>Gerenciar Bairros</label>
        <RiArrowDropRightLine />
      </BreadCrumb>

      <Title>Bairros</Title>
      <Description margin_small={true}>
        Digite qualquer CEP da cidade que deseja adicionar ou editar os bairros
      </Description>
      <ContainerFlex margin='0px 0 25px' gap='20px' align='center'>
        <TextInput
          text='CEP da Cidade'
          size='medium'
          value={cep}
          handleChange={handleCEP}
          mask='99999-999'
          maskChar='_'
          clear={() => {
            setCep('')
            setCity(null)
            setData(null)
          }}
        />

        <TextInput
          text='Novo Bairro'
          size='medium'
          value={input}
          handleChange={event => setInput(event.target.value)}
          clear={() => setInput('')}
        />
        <Button onClick={handleSubmit}>Adicionar</Button>
      </ContainerFlex>
      <Title>{city && city}</Title>
      <ContainerFlex>
        <Table>
          <thead>
            <tr>
              <th>ID</th>
              <th>Nome</th>
            </tr>
          </thead>
          <tbody>
            {data &&
              data.map(district => {
                return (
                  <tr>
                    <td>{district.id}</td>
                    <td>{district.name}</td>
                  </tr>
                )
              })}
          </tbody>
        </Table>
      </ContainerFlex>
    </ContainerFlex>
  )
}
