export const DataReducer = (state, action) => {
  switch (action.type) {
    case 'set_data':
      return {
        ...state,
        [action.fieldName]: action.payload
      }

    case 'getting_data':
      return {
        ...state,
        getting_properties: true,
        getting_clients: true
      }

    case 'getting_properties':
      return {
        ...state,
        getting_properties: true
      }

    case 'getting_clients':
      return {
        ...state,
        getting_clients: true
      }

    default:
      return state
  }
}
