import React, { useEffect, useState } from 'react'
import { ContainerFlex, BreadCrumb, Title, TextInput, Button, Table } from '../../../components'
import { RiArrowDropRightLine } from 'react-icons/ri'
import { getMaritalStatus, postMaritalStatus } from '../../../services/requests'

export const MaritalStatus = () => {
  const [input, setInput] = useState('')
  const [data, setData] = useState(null)

  const getData = async () => {
    try {
      let res = await getMaritalStatus()
      setData(res.data)
      console.log(res.data)
    } catch (err) {
      console.log(err)
    }
  }

  const handleSubmit = async () => {
    try {
      await postMaritalStatus({ name: input })
      getData()
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    getData()
  }, [])

  return (
    <ContainerFlex margin='20px 0 25px' gap='20px' direction='column'>
      <BreadCrumb>
        <label>Gerenciar Estado Civil</label>
        <RiArrowDropRightLine />
      </BreadCrumb>

      <Title>Estado Civil</Title>
      <ContainerFlex margin='15px 0 25px' gap='20px' align='center'>
        <TextInput
          text='Nova Profissão'
          size='medium'
          value={input}
          handleChange={event => setInput(event.target.value)}
        />
        <Button onClick={handleSubmit}>Adicionar</Button>
      </ContainerFlex>
      <ContainerFlex>
        <Table>
          <thead>
            <tr>
              <th>ID</th>
              <th>Nome</th>
            </tr>
          </thead>
          <tbody>
            {data &&
              data.map(elem => {
                return (
                  <tr>
                    <td>{elem.id}</td>
                    <td>{elem.name}</td>
                  </tr>
                )
              })}
          </tbody>
        </Table>
      </ContainerFlex>
    </ContainerFlex>
  )
}
