import React, { useState, useEffect } from 'react'
import {
  handleSelection,
  handleEvent,
  handleCheckBox,
  handleNumber,
  clearField
} from '../handleFunctions'
import {
  Title,
  Description,
  ContainerFlexResponsive,
  SelectInput,
  Checkbox,
  TextInput,
  SelectInputBoolean,
  PhotoUpload,
  SelectInputValue
} from '../..'
import { module11 } from '../../../utils/module11'
import { checkClientCPFCNPJ } from '../../../services/requests'

export const ClientBasics = ({ state, dispatch, update, checkError }) => {
  const [birthdayError, setBirthdayError] = useState(false)
  const [loading, setLoading] = useState(false)

  const checkCPFCNPJ = async (value, type) => {
    setLoading(true)
    let res = await checkClientCPFCNPJ(value)
    if (res.data) {
      type === 'CPF'
        ? dispatch({ type: 'set_data', fieldName: 'cpf_duplicated', payload: true })
        : dispatch({ type: 'set_data', fieldName: 'cnpj_duplicated', payload: true })
    }
    setLoading(false)
  }

  const format_cpf_cnpj = state => {
    return state.replaceAll('_', '').replaceAll('.', '').replace('-', '').replace('/', '')
  }

  const handleCPF = event => {
    dispatch({ type: 'reset_cpf' })
    if (format_cpf_cnpj(event.target.value).length < 11) {
      handleEvent(event, dispatch)
    } else if (format_cpf_cnpj(event.target.value).length === 11) {
      checkCPFCNPJ(event.target.value, 'CPF')
      handleEvent(event, dispatch)
      let result = module11(format_cpf_cnpj(event.target.value), 2, 12, true)
      result
        ? dispatch({ type: 'set_data', fieldName: 'cpf_incorrect', payload: true })
        : dispatch({ type: 'set_data', fieldName: 'cpf_complete', payload: true })
    }
  }

  const handleCNPJ = event => {
    dispatch({ type: 'reset_cnpj' })
    if (format_cpf_cnpj(event.target.value).length < 14) {
      handleEvent(event, dispatch)
    } else if (format_cpf_cnpj(event.target.value).length === 14) {
      checkCPFCNPJ(event.target.value, 'CNPJ')
      handleEvent(event, dispatch)
      let result = module11(format_cpf_cnpj(event.target.value), 2, 9, true)
      String(result) !== '00'
        ? console.log('erro')
        : dispatch({ type: 'set_data', fieldName: 'cnpj_complete', payload: true })
      dispatch({ type: 'set_data', fieldName: 'cnpj_complete', payload: true })
    }
  }

  const checkCPF = state => format_cpf_cnpj(state).length !== 11

  const checkCNPJ = state => format_cpf_cnpj(state).length !== 14

  const handleBirthday = event => {
    handleEvent(event, dispatch)

    if (birthdayError) setBirthdayError(false)

    if (event.target.value.replaceAll('_', '').replaceAll('/', '').length === 8) {
      let date = event.target.value.replaceAll('/', '')

      if (Number(date.substr(0, 2)) > 31) setBirthdayError(true)
      if (Number(date.substr(2, 2)) > 12) setBirthdayError(true)
      if (date.substr(4) === '0000') setBirthdayError(true)
    }
  }

  const handlePhoto = event => {
    let formData = new FormData()
    formData.append('photo', event.target.files[0])
    handleSelection('photo', formData, dispatch)
    console.log(formData.get('photo'))
  }

  useEffect(() => {
    if (update) {
      if (state.is_legal_person) {
        if (!checkCNPJ(state.cpf_cnpj))
          dispatch({ type: 'set_data', fieldName: 'cnpj_complete', payload: true })
      } else if (!checkCPF(state.cpf_cnpj))
        dispatch({ type: 'set_data', fieldName: 'cpf_complete', payload: true })
    }
    // eslint-disable-next-line
  }, [])

  return (
    <>
      <Title style={{ marginTop: '20px' }}>Informações Gerais</Title>
      <Description>Informações gerais sobre o cliente</Description>
      <PhotoUpload action={handlePhoto} state={state} />
      <ContainerFlexResponsive gap='50px' margin='30px 0 45px' resp_direction='column'>
        <SelectInputBoolean
          text='Tipo de Cliente'
          name='is_legal_person'
          value={state.is_legal_person}
          options={[
            { value: false, name: 'Pessoa Física' },
            { value: true, name: 'Pessoa Jurídica' }
          ]}
          handleSelection={(name, selection) => handleSelection(name, selection, dispatch)}
          clear={name => clearField(name, dispatch, 2)}
        />

        {state.is_legal_person ? (
          <TextInput
            text='CNPJ *'
            name='cpf_cnpj'
            value={state.cpf_cnpj}
            handleChange={handleCNPJ}
            size='small'
            mask='99.999.999/9999-99'
            error={
              checkError
                ? state.cpf_cnpj === '' ||
                  checkCNPJ(state.cpf_cnpj) ||
                  state.cnpj_incorrect ||
                  state.cnpj_duplicated ||
                  !state.cnpj_complete
                : state.cnpj_incorrect || state.cnpj_duplicated
            }
            errorMessage={
              checkError && checkCNPJ(state.cpf_cnpj)
                ? 'Insira um CNPJ Válido'
                : state.cnpj_incorrect
                ? 'CNPJ Incorreto'
                : 'CNPJ Já Existente'
            }
            clear={name => {
              clearField(name, dispatch, 1)
              dispatch({ type: 'reset_cnpj' })
            }}
            loading={loading}
          />
        ) : (
          <TextInput
            text='CPF *'
            name='cpf_cnpj'
            value={state.cpf_cnpj}
            handleChange={handleCPF}
            size='small'
            mask='999.999.999-99'
            error={
              checkError
                ? state.cpf_cnpj === '' ||
                  checkCPF(state.cpf_cnpj) ||
                  state.cpf_incorrect ||
                  state.cpf_duplicated ||
                  !state.cpf_complete
                : state.cpf_incorrect || state.cpf_duplicated
            }
            errorMessage={
              checkError && checkCPF(state.cpf_cnpj)
                ? 'Insira um CPF Válido'
                : state.cpf_incorrect
                ? 'CPF Incorreto'
                : 'CPF Já Existente'
            }
            clear={name => {
              clearField(name, dispatch, 1)
              dispatch({ type: 'reset_cpf' })
            }}
            loading={loading}
          />
        )}

        <TextInput
          text={state.is_legal_person ? 'Inscrição Estadual *' : 'RG *'}
          name='rg_ie'
          value={state.rg_ie}
          handleChange={event => handleNumber(event, dispatch)}
          size='small'
          error={checkError ? state.rg_ie === '' : false}
          clear={name => clearField(name, dispatch, 1)}
        />

        <TextInput
          text='Nome *'
          name='name'
          value={state.name}
          handleChange={event => handleEvent(event, dispatch)}
          size='medium'
          error={checkError ? state.name === '' : false}
          clear={name => clearField(name, dispatch, 1)}
        />

        <TextInput
          text='Nome de Usuário'
          name='nickname'
          value={state.nickname}
          handleChange={event => handleEvent(event, dispatch)}
          size='small'
          clear={name => clearField(name, dispatch, 1)}
        />

        <TextInput
          text={state.is_legal_person ? 'Data da Fundação *' : 'Data de Nascimento *'}
          name='birth_date'
          value={state.birth_date}
          handleChange={handleBirthday}
          size='small'
          mask='99/99/9999'
          error={
            checkError
              ? state.birth_date === '' ||
                state.birth_date.replaceAll('_', '').length !== 10 ||
                birthdayError
              : birthdayError
          }
          errorMessage='Data Inválida'
          clear={name => clearField(name, dispatch, 1)}
        />

        {!state.is_legal_person && (
          <SelectInput
            text='Gênero *'
            name='gender'
            value={state.gender}
            options={['Masculino', 'Feminino']}
            handleSelection={(name, selection) => handleSelection(name, selection, dispatch)}
            error={checkError ? state.gender === null : false}
            clear={name => clearField(name, dispatch, 2)}
          />
        )}

        {!state.is_legal_person && (
          <SelectInputValue
            text='Profissão *'
            name='professions_id'
            value={state.professions_id}
            options={state.professions}
            handleSelection={(name, selection) => handleSelection(name, selection, dispatch)}
            size='small'
            error={checkError ? state.professions_id === null : false}
            clear={name => clearField(name, dispatch, 2)}
          />
        )}

        {!state.is_legal_person && (
          <TextInput
            text='Renda'
            name='income'
            value={state.income}
            handleChange={event => handleEvent(event, dispatch)}
            size='small'
            clear={name => clearField(name, dispatch, 1)}
          />
        )}

        {!state.is_legal_person && (
          <SelectInputValue
            text='Estado Civil *'
            name='marital_status_list_id'
            value={state.marital_status_list_id}
            options={state.marital_status_options}
            handleSelection={(name, selection) => handleSelection(name, selection, dispatch)}
            error={checkError ? state.marital_status_list_id === null : false}
            size='small'
            clear={name => clearField(name, dispatch, 2)}
          />
        )}

        {!state.is_legal_person && (
          <TextInput
            text='Conjugê'
            name='spouse'
            value={state.spouse}
            handleChange={event => handleEvent(event, dispatch)}
            size='small'
            clear={name => clearField(name, dispatch, 1)}
          />
        )}

        <Checkbox
          text='Cliente Problemático'
          name='is_problematic'
          handleChange={name => handleCheckBox(name, dispatch)}
          state={state.is_problematic}
        />
        <Checkbox
          text='Cliente Ativo'
          name='status'
          handleChange={name => handleCheckBox(name, dispatch)}
          state={state.status}
        />
      </ContainerFlexResponsive>
    </>
  )
}
