import React, { useEffect, useReducer, useContext } from 'react'
import { useParams } from 'react-router'
import { RiArrowDropRightLine } from 'react-icons/ri'
import { MultiStepReducer, PropertyState, getInitialPropertyData } from '../../reducers/'
import { Main, Section } from './styles'
import { getPropertyById, getDistrictsByID, getImages } from '../../services/requests'
import { AuthContext } from '../../context/AuthContext'
import { helper } from './helper'
import {
  BreadCrumb,
  Loader,
  Address,
  Features,
  Internal,
  Media,
  PropertyBasics,
  Steps,
  ContainerFlexResponsive,
  BreadCrumbLink,
  BreadCrumbLabel
} from '../../components'
import axios from 'axios'

export const PropertyEdit = props => {
  let { id } = useParams()
  let { auth_state } = useContext(AuthContext)

  const [state, dispatch] = useReducer(MultiStepReducer, PropertyState)
  const getData = async () => {
    try {
      let all_promises = await axios.all([
        getInitialPropertyData(),
        getPropertyById(id),
        getImages(id)
      ])

      dispatch({ type: 'store_data', payload: all_promises[0] })
      helper(all_promises[1].data, dispatch)
      dispatch({ type: 'set_data', fieldName: 'photos', payload: all_promises[2].data })

      let districts = await getDistrictsByID(all_promises[1].data.district.cities_id)
      dispatch({ type: 'set_data', fieldName: 'districts', payload: districts.data })
    } catch (err) {
      console.log(err)
    }
  }

  let edit_props = { state, dispatch, update: true }

  const steps_list = [
    { name: 'Geral', component: <PropertyBasics {...edit_props} /> },
    { name: 'Endereço', component: <Address {...edit_props} /> },
    { name: 'Interno', component: <Internal {...edit_props} /> },
    { name: 'Comodidades', component: <Features {...edit_props} /> },
    { name: 'Mídia', component: <Media {...edit_props} /> }
  ]

  useEffect(() => {
    dispatch({ type: 'set_data', fieldName: 'districts', payload: null })
    getData()
    dispatch({ type: 'set_data', fieldName: 'employee_id', payload: auth_state.id })
    // eslint-disable-next-line
  }, [id])

  const { currentStep } = state

  return (
    <Main>
      <ContainerFlexResponsive
        align='center'
        justify='space-between'
        margin='0 0 20px'
        resp_direction='column'
      >
        <BreadCrumb>
          <BreadCrumbLink to={`/imovel/${id}`}>Imóvel {id}</BreadCrumbLink>
          <RiArrowDropRightLine />
          <BreadCrumbLabel>Edição</BreadCrumbLabel>
        </BreadCrumb>

        <Steps
          list={steps_list}
          currentStep={currentStep}
          clickable={true}
          handleClick={index => dispatch({ type: 'go_to', payload: index })}
        />
      </ContainerFlexResponsive>

      {state.districts ? (
        <Section>{steps_list[currentStep].component}</Section>
      ) : (
        <Loader margin='20vh auto' />
      )}
    </Main>
  )
}
