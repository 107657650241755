import React, { useReducer, useEffect, useState } from 'react'
import { RiArrowDropRightLine } from 'react-icons/ri'
import {
  BreadCrumb,
  BreadCrumbLabel,
  ClientAddress,
  ClientBasics,
  ClientContact
} from '../../components'
import { MultiStepReducer, getInitialClientData, ClientState } from '../../reducers'
import { Main, Section } from './styles'

export const NewClient = () => {
  const [state, dispatch] = useReducer(MultiStepReducer, ClientState)
  const [checkError, setCheckError] = useState(false)

  const getData = async () => {
    try {
      const data = await getInitialClientData()
      dispatch({ type: 'store_data', payload: data })
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    getData()
    // eslint-disable-next-line
  }, [])

  return (
    <Main>
      <BreadCrumb>
        <BreadCrumbLabel>Novo Cliente</BreadCrumbLabel>
        <RiArrowDropRightLine />
      </BreadCrumb>
      <Section>
        <ClientBasics state={state} dispatch={dispatch} update={false} checkError={checkError} />
        <ClientContact state={state} dispatch={dispatch} update={true} checkError={checkError} />
        <ClientAddress
          state={state}
          dispatch={dispatch}
          update={false}
          checkError={checkError}
          setCheckError={setCheckError}
        />
      </Section>
    </Main>
  )
}
