import React from 'react'
import { handleEvent, clearField } from '../handleFunctions'
import {
  Title,
  Description,
  ContainerFlexResponsive,
  SelectInput,
  TextInput,
  RegularButton
} from '../..'
import { deletePhoneClient } from '../../../services/requests'

export const ClientContact = ({ state, dispatch, checkError }) => {
  return (
    <>
      <Title>Contato</Title>
      <Description>Informações de contato do cliente</Description>

      <ContainerFlexResponsive gap='50px' margin='0px 0 45px' resp_direction='column'>
        <TextInput
          text='Email'
          name='email'
          value={state.email}
          handleChange={event => handleEvent(event, dispatch)}
          size='medium'
          error={false}
          clear={name => clearField(name, dispatch, 1)}
        />

        <TextInput
          text='Celular'
          name='whatsapp'
          value={state.whatsapp}
          handleChange={event => handleEvent(event, dispatch)}
          size='small'
          mask='(99) 99999-9999'
          clear={name => clearField(name, dispatch, 1)}
        />

        {state.phones.length !== 0 &&
          state.phones.map((phone, index) => {
            return (
              <ContainerFlexResponsive
                align='center'
                gap='45px'
                key={index}
                resp_direction='column'
              >
                <TextInput
                  text='Número'
                  name={phone.index}
                  value={phone.number}
                  handleChange={event => handle_number(dispatch, state, index, event)}
                  size='small'
                  mask={
                    phone.description
                      ? phone.description.includes('Telefone')
                        ? '(99) 9999-9999'
                        : '(99) 99999-9999'
                      : '(99) 99999-9999'
                  }
                  clear={() => clear_number(dispatch, state, index, 1)}
                />
                <SelectInput
                  text='Tipo de Telefone'
                  name={phone.id}
                  value={phone.description}
                  options={state.phone_types}
                  handleSelection={(name, selection) =>
                    handle_description(dispatch, state, index, selection)
                  }
                  size='small'
                  error={checkError ? phone.description === null : false}
                  clear={() => clear_number(dispatch, state, index, 2)}
                />
                <RegularButton onClick={() => remove_number(dispatch, state, index)}>
                  Remover Telefone
                </RegularButton>
              </ContainerFlexResponsive>
            )
          })}

        <RegularButton onClick={() => add_phone(dispatch, state)}>
          Novo Campo de Telefone
        </RegularButton>
      </ContainerFlexResponsive>
    </>
  )
}

const add_phone = (dispatch, state) => {
  dispatch({
    type: 'set_data',
    fieldName: 'phones',
    payload: [
      ...state.phones,
      {
        number: '',
        description: null
      }
    ]
  })
}

const remove_number = async (dispatch, state, index) => {
  if (state.phones[index].id === undefined) {
    let new_list = state.phones
    new_list.splice(index, 1)
    dispatch({ type: 'set_data', fieldName: 'phones', payload: new_list })
  } else {
    try {
      await deletePhoneClient(state.id, state.phones[index].id)
      let new_list = state.phones
      new_list.splice(index, 1)
      dispatch({ type: 'set_data', fieldName: 'phones', payload: new_list })
    } catch (e) {
      alert('Erro ao excluir número de telefone!')
      console.log(e)
    }
  }
}

const handle_number = (dispatch, state, index, event) => {
  let new_list = state.phones

  state.phones[index].id === undefined
    ? (new_list[index] = {
        number: event.target.value,
        description: state.phones[index].description
      })
    : (new_list[index] = {
        id: state.phones[index].id,
        number: event.target.value,
        description: state.phones[index].description
      })

  dispatch({
    type: 'set_data',
    fieldName: 'phones',
    payload: new_list
  })
}

const handle_description = (dispatch, state, index, description) => {
  let new_list = state.phones

  state.phones[index].id === undefined
    ? (new_list[index] = { number: state.phones[index].number, description })
    : (new_list[index] = {
        id: state.phones[index].id,
        number: state.phones[index].number,
        description
      })

  dispatch({
    type: 'set_data',
    fieldName: 'phones',
    payload: new_list
  })
}

const clear_number = (dispatch, state, index, type) => {
  let new_list = state.phones

  state.phones[index].id === undefined
    ? type === 1
      ? (new_list[index] = { number: '', description: state.phones[index].description })
      : (new_list[index] = { number: state.phones[index].number, description: null })
    : type === 1
    ? (new_list[index] = {
        id: state.phones[index].id,
        number: '',
        description: state.phones[index].description
      })
    : (new_list[index] = {
        id: state.phones[index].id,
        number: state.phones[index].number,
        description: null
      })

  dispatch({
    type: 'set_data',
    fieldName: 'phones',
    payload: new_list
  })
}
