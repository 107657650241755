import { ContainerFlexResponsive } from '../../components'
import styled from 'styled-components'

export const DatePickerContainer = styled(ContainerFlexResponsive)`
  & > div.react-datepicker-wrapper {
    width: 200px;
  }

  & > div.react-datepicker__tab-loop {
    position: absolute;
  }

  & div.react-datepicker-popper div.react-datepicker {
    font-family: 'IBM Plex Sans', sans-serif;
    border: 1px solid ${props => props.theme.date_picker.border};
    color: ${props => props.theme.text.primary};
    background: ${props => props.theme.date_picker.background};
  }

  & div.react-datepicker__header {
    background: ${props => props.theme.date_picker.background};
  }

  & button.react-datepicker__year-read-view--down-arrow,
  .react-datepicker__month-read-view--down-arrow,
  .react-datepicker__month-year-read-view--down-arrow,
  .react-datepicker__navigation-icon::before {
    border-color: ${props => props.theme.date_picker.button_color};
  }

  & button.react-datepicker__year-read-view--down-arrow,
  .react-datepicker__month-read-view--down-arrow,
  .react-datepicker__month-year-read-view--down-arrow,
  .react-datepicker__navigation-icon::before {
    border-width: 1px 1px 0 0;
  }

  & button.react-datepicker__navigation {
    border-radius: 25px;
  }

  & button.react-datepicker__navigation:hover {
    background: ${props => props.theme.date_picker.button_color_hover};
  }

  & button .react-datepicker__navigation-icon {
    top: 0;
  }

  & button .react-datepicker__navigation-icon--next {
    left: 0px;
  }

  & button .react-datepicker__navigation-icon--previous {
    right: 0px;
  }

  & .react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle::before {
    border-bottom-color: ${props => props.theme.date_picker.border};
  }

  & .react-datepicker__day--selected,
  .react-datepicker__day--in-selecting-range,
  .react-datepicker__day--in-range,
  .react-datepicker__month-text--selected,
  .react-datepicker__month-text--in-selecting-range,
  .react-datepicker__month-text--in-range,
  .react-datepicker__quarter-text--selected,
  .react-datepicker__quarter-text--in-selecting-range,
  .react-datepicker__quarter-text--in-range,
  .react-datepicker__year-text--selected,
  .react-datepicker__year-text--in-selecting-range,
  .react-datepicker__year-text--in-range {
    border-radius: 25px;
    background: ${props => props.theme.date_picker.option_background};
    color: ${props => props.theme.date_picker.option_color};
  }

  & .react-datepicker__day--keyboard-selected,
  .react-datepicker__month-text--keyboard-selected,
  .react-datepicker__quarter-text--keyboard-selected,
  .react-datepicker__year-text--keyboard-selected {
    border-radius: 25px;
    background: ${props => props.theme.date_picker.option_background};
    color: ${props => props.theme.date_picker.option_color};
  }

  & .react-datepicker__day--today,
  .react-datepicker__month-text--today,
  .react-datepicker__quarter-text--today,
  .react-datepicker__year-text--today {
    font-weight: 400;
  }

  & .react-datepicker__day:hover,
  .react-datepicker__month-text--today:hover,
  .react-datepicker__quarter-text--today:hover,
  .react-datepicker__year-text--today:hover {
    border-radius: 25px;
  }

  @media (max-width: 500px) {
    & > div.react-datepicker-wrapper {
      width: 100%;
    }
  }
`

export const ReservationInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin: 0 0 15px;

  p {
    font-size: 14px;
  }
`

export const ChangesControl = styled.div`
  position: fixed;
  bottom: 30px;
  right: 30px;
  max-height: 500px;
  overflow-y: scroll;
  border-radius: 16px;
  border: 1px solid ${props => props.theme.general.border};
  background: ${props => props.theme.general.background};

  display: flex;
  flex-direction: column;
`

export const Item = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  width: 350px;
  margin: 20px;

  & p {
    font-weight: 500;
    width: 150px;
    overflow: hidden;
  }

  & img {
    width: 50px;
    height: 50px;
    border-radius: 7px;
  }

  & svg {
    width: 40px;
    height: 40px;
    background: ${props => props.theme.button.secondary_background};
    color: ${props => props.theme.button.secondary_text};
    border-radius: 7px;
    padding: 10px;
  }

  & .spinner {
    width: 20px;
    height: 20px;

    position: relative;
  }

  & .double-bounce1,
  .double-bounce2 {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: ${props => props.theme.button.secondary_text};
    opacity: 0.6;
    position: absolute;
    top: 0;
    left: 0;

    -webkit-animation: sk-bounce 2s infinite ease-in-out;
    animation: sk-bounce 2s infinite ease-in-out;
  }

  & .double-bounce2 {
    -webkit-animation-delay: -1s;
    animation-delay: -1s;
  }

  @-webkit-keyframes sk-bounce {
    0%,
    100% {
      -webkit-transform: scale(0);
    }
    50% {
      -webkit-transform: scale(1);
    }
  }

  @keyframes sk-bounce {
    0%,
    100% {
      transform: scale(0);
      -webkit-transform: scale(0);
    }
    50% {
      transform: scale(1);
      -webkit-transform: scale(1);
    }
  }
`
