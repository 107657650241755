export const MultiStepReducer = (state, action) => {
  switch (action.type) {
    // Component control
    case 'next':
      return {
        ...state,
        currentStep: state.currentStep + 1
      }

    case 'previous':
      return {
        ...state,
        currentStep: state.currentStep - 1
      }

    case 'go_to':
      return {
        ...state,
        currentStep: action.payload
      }

    //Set generic data
    case 'set_data':
      return {
        ...state,
        [action.fieldName]: action.payload
      }

    case 'store_data':
      return {
        ...state,
        ...action.payload
      }

    //Inputs
    case 'clear':
      return {
        ...state,
        [action.fieldName]: ''
      }

    case 'clear_select':
      return {
        ...state,
        [action.fieldName]: null
      }

    case 'numeric_input':
      return {
        ...state,
        [action.fieldName]: action.payload.replace(/\D/g, '')
      }

    case 'checkbox':
      return {
        ...state,
        [action.fieldName]: !state[action.fieldName]
      }

    case 'commodity':
      if (action.value) {
        return {
          ...state,
          commodities: [...state.commodities, action.id]
        }
      } else {
        return {
          ...state,
          commodities: state.commodities.filter(commodity => commodity !== action.id)
        }
      }

    //Address control
    case 'set_address':
      return {
        ...state,
        cep: action.payload.cep,
        state: action.payload.uf,
        city: action.payload.localidade
      }

    case 'reset_address':
      return {
        ...state,
        cep: '',
        state: '',
        city: '',
        district: null,
        address: '',
        number: '',
        public_areas_id: null,
        districts: null
      }

    case 'add_photo':
      return { ...state, photos: [...state.photos, ...action.payload] }

    case 'add_video':
      return { ...state, videos: [...state.videos, action.payload] }

    case 'reset_cpf':
      return {
        ...state,
        cpf_incorrect: false,
        cpf_duplicated: false,
        cpf_complete: false
      }

    case 'reset_cnpj':
      return {
        ...state,
        cnpj_incorrect: false,
        cnpj_duplicated: false,
        cnpj_complete: false
      }

    default:
      return state
  }
}
