import styled from 'styled-components'

export const Main = styled.main`
  transition: 0.3s all;
  padding-left: ${props => (props.open ? '275px' : '95px')};
  padding-right: 25px;
  height: 100%;
  width: 100%;

  //media query
  @media (max-width: 1000px) {
    padding-left: 25px;
  }
`
