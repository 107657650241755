import React, { useState } from 'react'
import { Header, SidebarMenu } from '../../components'
import { Main } from './styles'

export const Dashboard = ({ children }) => {
  const [open, setOpen] = useState(true)

  const handleClose = () => setOpen(!open)

  return (
    <>
      <SidebarMenu handleClose={handleClose} open={open} />
      <Header />
      <Main handleClose={handleClose} open={open}>
        {children}
      </Main>
    </>
  )
}
