import React, { useEffect, useState } from 'react'
import {
  ContainerFlex,
  BreadCrumb,
  Title,
  TextInput,
  Button,
  SelectInputValue,
  Checkbox,
  SimpleButton,
  ButtonLoader,
  ContainerFlexResponsive,
  Description,
  Loader,
  BreadCrumbLink
} from '../../../components'
import { RiArrowDropRightLine } from 'react-icons/ri'
import { getEmployees, updateEmployee } from '../../../services/requests'
import { useHistory, useParams } from 'react-router-dom'
import moment from 'moment'

export const EditEmployee = () => {
  const [form, setForm] = useState(null)
  const [submitted, setSubmitted] = useState(true)
  const [loading, setLoading] = useState(false)

  let { id } = useParams()
  let navigate = useHistory()

  const getData = async () => {
    try {
      let res = await getEmployees()
      let data = res.data.find(employee => employee.id === parseInt(id))
      setForm({
        ...data,
        access_levels_id: data.accessLevel.id,
        phone: data.phones.length ? data.phones[0].number : '',
        birth_date: moment(data.birth_date).format('YYYY-MM-DD')
      })
    } catch (err) {
      console.log(err)
    }
  }

  const handleSubmit = async () => {
    setSubmitted(true)
    setLoading(true)

    if (form.name && form.email && form.phone && form.access_levels_id) {
      if (
        window.confirm(`Tem certeza que deseja atualizar os dados do funcionário ${form.name}?`)
      ) {
        try {
          await updateEmployee(id, {
            ...form,
            phones: [
              {
                number: form.phone,
                description: 'main'
              }
            ]
          })
          alert('Edição realizada com sucesso!')
          navigate.push('/admin/funcionarios')
        } catch (err) {
          alert('Erro ao editar o cadastro do funcionário! Tente novamente mais tarde.')
          console.log(err)
        }
        setLoading(false)
        setSubmitted(false)
      } else {
        setLoading(false)
        setSubmitted(false)
      }
    } else {
      alert('Preencha todos os campos!')
      setLoading(false)
    }
  }

  useEffect(() => {
    getData()
    // eslint-disable-next-line
  }, [id])

  return (
    <ContainerFlex margin='20px 0 25px' gap='20px' direction='column'>
      <BreadCrumb>
        <BreadCrumbLink to={`/admin/funcionario/${id}`}>Funcionário {id}</BreadCrumbLink>
        <RiArrowDropRightLine />
        <label>Edição</label>
      </BreadCrumb>

      <Title>Editar cadastro</Title>
      <Description>
        Edite os dados do funcionário e clique em "Finalizar Edição" para confirmar as alterações.
        Para alteração de senha, entre em contato com o administrador.
      </Description>
      {form && (
        <ContainerFlexResponsive margin='0' gap='30px' resp_direction='column'>
          <TextInput
            text='Nome'
            value={form.name}
            handleChange={e => setForm({ ...form, name: e.target.value })}
            size='medium'
            error={submitted && form.name.length === 0}
            clear={() => setForm({ ...form, name: '' })}
          />

          <TextInput
            text='E-mail'
            value={form.email}
            handleChange={e => setForm({ ...form, email: e.target.value })}
            size='medium'
            error={submitted && form.email.length === 0}
            clear={() => setForm({ ...form, email: '' })}
          />

          <TextInput
            text='Telefone'
            value={form.phone}
            handleChange={e => setForm({ ...form, phone: [e.target.value] })}
            size='medium'
            mask='(99) 99999-9999'
            error={submitted && form.phone.length === 0}
            clear={() => setForm({ ...form, phone: '' })}
          />

          <SelectInputValue
            text='Cargo'
            value={form.access_levels_id}
            handleSelection={(name, id) => setForm({ ...form, access_levels_id: id })}
            size='medium'
            options={[
              { id: 1, name: 'Administrador', level: 0 },
              { id: 2, name: 'Operador Sênior', level: 1 },
              { id: 3, name: 'Operador', level: 2 },
              { id: 4, name: 'Operador Júnior', level: 3 },
              { id: 5, name: 'Visitante', level: 4 },
              { id: 6, name: 'Acesso negado', level: 999 }
            ]}
            error={submitted && form.access_levels_id === null}
            clear={() => setForm({ ...form, access_levels_id: null })}
          />

          <Checkbox
            text='Ativo'
            name='status'
            size='medium'
            state={form.status}
            handleChange={() => setForm({ ...form, status: !form.status })}
          />
        </ContainerFlexResponsive>
      )}

      {!form && <Loader margin='20vh auto' />}

      <ContainerFlex gap='20px'>
        <Button size='medium' onClick={handleSubmit}>
          {loading ? <ButtonLoader /> : 'Finalizar Edição'}
        </Button>
        <SimpleButton size='small' onClick={() => navigate.push('/admin/funcionarios')}>
          Cancelar
        </SimpleButton>
      </ContainerFlex>
    </ContainerFlex>
  )
}
