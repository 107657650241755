import styled from 'styled-components'
import { handleSize } from '../../Utils/index'

export const CheckboxContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: white;
  width: ${props => handleSize(props.size)};
  height: 65px;
  padding: 0 15px;
  border-radius: 5px;
  border: 1px solid
    ${props => (props.marked ? props.theme.input.placeholder_active : props.theme.input.border)};
  transition: 0.4s all;
  font-weight: 500;
  font-size: 14px;
  cursor: pointer;

  &:hover {
    border: 1px solid ${props => props.theme.input.border_hover};
  }

  @media (max-width: 499px) {
    width: 100%;
  }
`

export const Label = styled.label`
  color: ${props =>
    props.marked ? props.theme.input.placeholder_active : props.theme.input.placeholder};
  cursor: pointer;
  user-select: none;
`

export const Marker = styled.div`
  transition: 0.4s all;
  width: 21px;
  height: 21px;
  background: ${props =>
    props.marked ? props.theme.input.placeholder_active : props.theme.input.background};
  border: 1px solid
    ${props => (props.marked ? props.theme.input.placeholder_active : props.theme.input.marker)};
  border-radius: 20px;

  & svg {
    width: 18px;
    height: 18px;
    color: ${props => props.theme.input.background};
  }
`
