import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;

  @media (max-width: 500px) {
    flex-direction: column;
    margin: 20px 0;
  }
`

export const Step = styled.div`
  font-size: 13px;
  font-weight: ${props => (props.status === 'active' ? '800' : '500')};
  color: ${props => props.theme.steps[props.status]};
  cursor: ${props => (props.clickable ? 'pointer' : 'default')};

  user-selection: none;
  -webkit-user-select: none; /* Chrome all / Safari all */
  -moz-user-select: none; /* Firefox all */
  -ms-user-select: none; /* IE 10+ */
  user-select: none;
`
