import React from 'react'
import { useParams } from 'react-router'
import { RiArrowDropRightLine } from 'react-icons/ri'
import { AiOutlineEdit } from 'react-icons/ai'
import { IoDocumentTextOutline } from 'react-icons/io5'
import { BiCalendar } from 'react-icons/bi'
import { AiOutlineHome, AiOutlineHistory } from 'react-icons/ai'
import { MdOutlineAutorenew } from 'react-icons/md'
import { Main } from './styles'
import {
  BreadCrumb,
  Description,
  ContainerFlexResponsive,
  LinkCard,
  BreadCrumbLabel
} from '../../components'

export const PropertyPage = props => {
  let { id } = useParams()

  const options_list = [
    { name: 'Visão Geral', icon: <AiOutlineHome />, link: `/imovel/${id}/detalhes` },
    { name: 'Relatório', icon: <IoDocumentTextOutline />, link: `/imovel/${id}/relatorio` },
    { name: 'Editar Imóvel', icon: <AiOutlineEdit />, link: `/imovel/${id}/editar` },
    { name: 'Reservas', icon: <BiCalendar />, link: `/imovel/${id}/reservas` },
    { name: 'Renovar Imóvel', icon: <MdOutlineAutorenew />, link: `/imovel/${id}/renovar` },
    {
      name: 'Alterações',
      icon: <AiOutlineHistory />,
      link: `/imovel/${id}/alteracoes`
    }
  ]

  return (
    <Main>
      <BreadCrumb>
        <BreadCrumbLabel>Imóvel</BreadCrumbLabel>
        <RiArrowDropRightLine />
        <BreadCrumbLabel>{id}</BreadCrumbLabel>
      </BreadCrumb>

      <Description>Selecione a opção desejada abaixo</Description>

      <ContainerFlexResponsive gap='20px' resp_align='center' resp_direction='column'>
        {options_list.map((option, index) => (
          <LinkCard index={index} to={option.link} className='no-select'>
            {option.icon}
            {option.name}
          </LinkCard>
        ))}
      </ContainerFlexResponsive>
    </Main>
  )
}
