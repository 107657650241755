import styled from 'styled-components'
import { handleSize } from '../../Utils'

export const Input = styled.input`
  width: ${props => handleSize(props.size)};
  height: 65px;
  padding: 35px 15px 15px;
  border-radius: 12px;
  outline: none;
  border: 1px solid ${props => props.theme.input.border};
  transition: 0.4s all;
  color: ${props => props.theme.text.primary};
  font-weight: 500;
  font-size: 14px;
  caret-color: ${props => (props.inactive ? 'white' : '')};

  &:focus {
    border: 1px solid ${props => props.theme.input.border_active};
  }
`

export const InputTextbox = styled.textarea`
  height: 150px;
  width: ${props => handleSize(props.size)};
  padding: 35px 15px 15px;
  border-radius: 5px;
  outline: none;
  border: 1px solid ${props => props.theme.input.border};
  transition: 0.4s all;
  color: ${props => props.theme.text.primary};
  font-weight: 500;
  font-size: 14px;
  caret-color: ${props => (props.inactive ? 'white' : '')};

  &:focus {
    border: 1px solid ${props => props.theme.input.border_active};
  }

  &::placeholder {
    padding-top: 20px;
    color: ${props => props.theme.input.placeholder};
  }

  @media (max-width: 500px) {
    width: 100%;
  }
`
