import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { Loader } from '../../components'
import { getPropertyById } from '../../services/requests'
import logo from '../../assets/images/logo.png'
import { Title, Maps } from '../../components'
import { Main, LogoContainer, ImagesContainer } from './styles'
/* import { Marker } from '@react-google-maps/api' */
import { Marker } from 'react-leaflet'

export const PropertyReport = props => {
  let { id } = useParams()

  const [state, setState] = useState(null)

  const getData = async () => {
    try {
      let property_data = await getPropertyById(id)
      setState(property_data.data)
    } catch (err) {
      alert('Erro ao buscar dados do imóvel')
      console.log(err)
    }
  }

  useEffect(() => {
    getData()
    // eslint-disable-next-line
  }, [id])

  return (
    <>
      {state ? (
        <Main>
          <LogoContainer>
            <img src={logo} alt='logo' />
          </LogoContainer>
          <Title>Ficha de Informações do Imóvel {id}</Title>
          <h3>Informações Gerais</h3>
          <p>{state.propertyType.name}</p>
          <p>Quartos: {state.bedrooms}</p>
          <p>Banheiros: {state.bathrooms}</p>
          <p>Vagas De Garagem: {state.parking_space}</p>
          <p>Suítes: {state.suites}</p>
          <p>{state.furnishing}</p>
          <p>Área Total: {state.land_area} m²</p>
          <p>Área Construída: {state.building_area} m²</p>
          <h3>Endereço</h3>
          <p>
            {state.publicArea.name} {state.address}, {state.number}
          </p>
          <p>Bairro: {state.district.name}</p>
          <p>{state.district.city.name}</p>
          <Maps
            position={
              state.latitude && state.longitude
                ? {
                    lat: parseFloat(state.latitude),
                    lng: parseFloat(state.longitude)
                  }
                : null
            }
            zoom={15}
            style={{ width: '100%', height: '300px' }}
          >
            {state.latitude && state.longitude ? (
              <Marker
                position={{
                  lat: parseFloat(state.latitude),
                  lng: parseFloat(state.longitude)
                }}
              />
            ) : null}
          </Maps>
          <h3>Imagens</h3>
          <ImagesContainer>
            {state.images.map(image => {
              return <img key={image.id} src={image.url} alt={image.id} />
            })}
          </ImagesContainer>
        </Main>
      ) : (
        <Loader margin='20vh auto' />
      )}
    </>
  )
}
