import React, { useContext } from 'react'
import { Route, Switch, BrowserRouter, Redirect } from 'react-router-dom'
import {
  Login,
  Dashboard,
  Home,
  NewClient,
  NewProperty,
  Properties,
  Clients,
  Professions,
  MaritalStatus,
  PublicArea,
  PropertyType,
  Updates,
  Client,
  Commodities,
  PropertyReport,
  PropertyEdit,
  PropertyReservation,
  PropertyChanges,
  Employees,
  NewEmployee,
  EditEmployee,
  NoPermission,
  PropertyOverview,
  EmployeeProperties,
  Employee,
  EmployeeChanges,
  PropertyRenew
} from './containers'
import { Districts } from './containers/Admin/Districts'
import { PropertyPage } from './containers/Property'
import { AuthContext } from './context/AuthContext'

export const Routes = () => {
  const { auth_state } = useContext(AuthContext)

  const PrivateRoute = ({ permission, component: Component, ...rest }) => {
    return (
      <Route
        {...rest}
        render={props =>
          auth_state.user ? (
            permission() ? (
              <Component {...props} />
            ) : (
              <NoPermission {...props} />
            )
          ) : (
            <Redirect
              to={{
                pathname: '/login',
                state: { from: props.location }
              }}
            />
          )
        }
      />
    )
  }

  return (
    <BrowserRouter>
      <Switch>
        <Route component={Login} path='/login' exact />
        <PrivateRoute
          component={PropertyReport}
          path='/imovel/:id/relatorio'
          exact
          permission={() => true}
        />
        <Dashboard>
          <PrivateRoute component={Home} path='/' exact permission={() => true} />

          <PrivateRoute component={NewClient} path='/novo/cliente' exact permission={() => true} />
          <PrivateRoute component={Clients} path='/clientes' exact permission={() => true} />
          <PrivateRoute component={Client} path='/cliente/:id' permission={() => true} />

          <PrivateRoute component={Properties} path='/imoveis' exact permission={() => true} />
          <PrivateRoute
            component={EmployeeProperties}
            path='/imoveis/corretor'
            exact
            permission={() => true}
          />
          <PrivateRoute component={PropertyPage} path='/imovel/:id' exact permission={() => true} />
          <PrivateRoute component={NewProperty} path='/novo/imovel' exact permission={() => true} />
          <PrivateRoute
            component={PropertyEdit}
            path='/imovel/:id/editar'
            exact
            permission={() => true}
          />
          <PrivateRoute
            component={PropertyReservation}
            path='/imovel/:id/reservas'
            exact
            permission={() => true}
          />

          <PrivateRoute
            component={PropertyOverview}
            path='/imovel/:id/detalhes'
            exact
            permission={() => true}
          />

          <PrivateRoute
            component={PropertyChanges}
            path='/imovel/:id/alteracoes'
            exact
            permission={() => true}
          />

          <PrivateRoute
            component={PropertyRenew}
            path='/imovel/:id/renovar'
            exact
            permission={() => true}
          />

          <PrivateRoute
            component={Professions}
            path='/admin/profissoes'
            exact
            permission={() => auth_state.access_levels < 3}
          />
          <PrivateRoute
            component={MaritalStatus}
            path='/admin/estado_civil'
            exact
            permission={() => auth_state.access_levels < 3}
          />
          <PrivateRoute
            component={PublicArea}
            path='/admin/logradouro'
            exact
            permission={() => auth_state.access_levels < 3}
          />
          <PrivateRoute
            component={PropertyType}
            path='/admin/tipo_imovel'
            exact
            permission={() => auth_state.access_levels < 3}
          />
          <PrivateRoute
            component={Commodities}
            path='/admin/comodidades'
            exact
            permission={() => auth_state.access_levels < 3}
          />
          <PrivateRoute
            component={Districts}
            path='/admin/bairros'
            exact
            permission={() => auth_state.access_levels < 3}
          />

          <PrivateRoute
            component={Employees}
            path='/admin/funcionarios'
            exact
            permission={() => auth_state.access_levels < 3}
          />
          <PrivateRoute
            component={NewEmployee}
            path='/admin/funcionarios/novo'
            exact
            permission={() => auth_state.access_levels < 3}
          />
          <PrivateRoute
            component={EditEmployee}
            path='/admin/funcionario/:id/editar/'
            exact
            permission={() => auth_state.access_levels < 3}
          />

          <PrivateRoute
            component={Employee}
            path='/admin/funcionario/:id/'
            exact
            permission={() => auth_state.access_levels < 3}
          />

          <PrivateRoute
            component={EmployeeChanges}
            path='/admin/funcionario/:id/alteracoes/'
            exact
            permission={() => auth_state.access_levels < 3}
          />

          <PrivateRoute component={Updates} path='/admin/updates' exact permission={() => false} />
        </Dashboard>
      </Switch>
    </BrowserRouter>
  )
}
