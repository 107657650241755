import React, { useRef, useState } from 'react'
import API from '../../../services/api'
import { setFavorite, deleteImage, getImages, updateOrderImages } from '../../../services/requests'
import { Thumbnail, ChangesControl, Item } from './styles'
import { useHistory } from 'react-router-dom'
import {
  Title,
  Description,
  ContainerFlexResponsive,
  Button,
  SecondaryButton,
  UploadButton,
  ButtonLoader,
  Table,
  ButtonGroup,
  ContainerFlex,
  TextInput
} from '../../../components'
import { updateProp } from '../../../reducers/PropertyReducer'
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors
} from '@dnd-kit/core'
import {
  SortableContext,
  arrayMove,
  rectSortingStrategy,
  sortableKeyboardCoordinates
} from '@dnd-kit/sortable'
import { AiOutlineEdit, AiOutlineRetweet, AiOutlineStar } from 'react-icons/ai'
import { IoMdClose } from 'react-icons/io'

export const Media = ({ state, dispatch, update }) => {
  const [loading, setLoading] = useState(false)
  const [files, setFiles] = useState([])
  const [orderLoading, setOrderLoading] = useState([])
  const [deleteLoading, setDeleteLoading] = useState([])
  const [favoriteLoading, setFavoriteLoading] = useState([])

  const [videoUrl, setVideoUrl] = useState('')
  const [videoLoading, setVideoLoading] = useState(false)
  const [videoUpdating, setVideoUpdating] = useState(false)
  const imgs = []

  const [currentSection, setCurrentSection] = useState('imagens')

  const ref = useRef()
  let history = useHistory()

  const handleUpload = async event => {
    let formData = new FormData()
    let fileList = []

    formData.append('property_id', state.id_property)

    for (let i = 0; i < event.target.files.length; i++) {
      formData.append('images', event.target.files[i])
      fileList.push(`${event.target.files[i].name}${event.target.files[i].size}`)
    }

    setFiles([...files, ...event.target.files])
    event.target.value = null

    try {
      let { data } = await API.post('/admin/image', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      dispatch({ type: 'add_photo', payload: data })
      if (!state.favorite_image) {
        handleFavorite(data[0].id)
        dispatch({ type: 'set_data', fieldName: 'favorite_image', payload: true })
      }
    } catch (err) {
      alert('Erro ao enviar as imagens!')
      console.log(err.request)
    }

    setFiles(files.filter(elem => fileList.includes(`${elem.name}${elem.size}`)))
  }

  const handleDelete = async img => {
    let file = img
    setDeleteLoading([...deleteLoading, img])

    try {
      await deleteImage(img.id)
      const { data } = await getImages(state.id_property)
      dispatch({ type: 'set_data', fieldName: 'photos', payload: data })
      setDeleteLoading(deleteLoading.filter(elem => elem.id === file.id))
    } catch (error) {
      alert('Um erro aconteceu ao excluir uma imagem do imóvel!')
      console.log(error)
      setDeleteLoading(deleteLoading.filter(elem => elem.id === file.id))
    }
  }

  const handleFavorite = async ID => {
    setFavoriteLoading([...favoriteLoading, ''])
    let arr = favoriteLoading

    try {
      await setFavorite(ID)
      const { data } = await getImages(state.id_property)
      dispatch({ type: 'set_data', fieldName: 'photos', payload: data })
      arr.pop()
      setFavoriteLoading(arr)
    } catch (error) {
      alert('Um erro aconteceu ao favoritar uma imagem do imóvel!')
      console.log(error)
      arr.pop()
      setFavoriteLoading(arr)
    }
  }

  const updateOrder = async img => {
    setOrderLoading([...orderLoading, ''])
    let arr = orderLoading

    try {
      await updateOrderImages(img.map(img => img.id))
      arr.pop()
      setOrderLoading(arr)
    } catch (err) {
      alert('Um erro aconteceu ao atualizar a ordem das imagens do imóvel!')
      console.log(err)
      arr.pop()
      setOrderLoading(arr)
    }
  }

  const updateProperty = async () => {
    if (!loading) {
      setLoading(true)
      try {
        await updateProp(state)
        alert('Imóvel atualizado com sucesso!')
        history.push(`/imovel/${state.id_property}`)
      } catch (e) {
        alert('Um erro aconteceu na atualização do imóvel!')
        console.log(e)
      }
      setLoading(false)
    }
  }

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        delay: 100,
        tolerance: 20
      }
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates
    })
  )

  const handleDragEnd = event => {
    const { active, over } = event

    if (active.id !== over.id) {
      const oldIndex = state.photos.findIndex(item => item.id === active.id)
      const newIndex = state.photos.findIndex(item => item.id === over.id)
      let new_state = arrayMove(state.photos, oldIndex, newIndex)
      dispatch({ type: 'set_data', fieldName: 'photos', payload: new_state })
      updateOrder(new_state)
    }
  }

  const handleNewVideo = async () => {
    setVideoLoading(true)
    if (videoUrl) {
      try {
        let res = await API.post('/admin/videos/create', {
          properties_id: state.id_property,
          url: videoUrl
        })
        alert('Vídeo adicionado com sucesso!')
        dispatch({ type: 'add_video', payload: res.data.video })
        setVideoUrl('')
      } catch (error) {
        alert('Um erro aconteceu ao adicionar um vídeo do imóvel!')
        console.log(error)
      }
    }
    setVideoLoading(false)
  }

  const handleDeleteVideo = async (ID, index) => {
    try {
      let new_state = await API.delete(`/admin/videos/${state.id_property}/${ID}`)
      alert('Vídeo excluído com sucesso!')
      dispatch({ type: 'set_data', fieldName: 'videos', payload: new_state.data.video })
    } catch (error) {
      alert('Um erro aconteceu ao excluir um vídeo do imóvel!')
      console.log(error)
    }
  }

  const handleEditVideo = vid => {
    setVideoUrl(vid.url)
    setVideoUpdating(vid.id)
  }

  const handleUpdateVideo = async () => {
    setVideoLoading(true)
    try {
      let new_state = await API.put(`/admin/videos/${state.id_property}/${videoUpdating}`, {
        url: videoUrl
      })
      alert('Vídeo atualizado com sucesso!')
      dispatch({ type: 'set_data', fieldName: 'videos', payload: new_state.data.video })
      setVideoUrl('')
      setVideoUpdating(false)
    } catch (error) {
      alert('Um erro aconteceu ao atualizar um vídeo do imóvel!')
      console.log(error)
    }
    setVideoLoading(false)
  }

  return (
    <>
      <Title>Mídia</Title>

      <input
        style={{ display: 'none' }}
        type='file'
        accept='image/*'
        onChange={handleUpload}
        ref={ref}
        multiple
      ></input>

      <ContainerFlex justify='space-around' margin='20px 0px'>
        <ButtonGroup
          selected={currentSection === 'imagens'}
          onClick={() => setCurrentSection('imagens')}
        >
          Imagens
        </ButtonGroup>
        {/* <ButtonGroup
          selected={currentSection === 'imagens360'}
          onClick={() => setCurrentSection('imagens360')}
        >
          Imagens 360
        </ButtonGroup> */}
        <ButtonGroup
          selected={currentSection === 'videos'}
          onClick={() => setCurrentSection('videos')}
        >
          Vídeos
        </ButtonGroup>
      </ContainerFlex>

      {currentSection === 'imagens' && (
        <ContainerFlexResponsive gap='50px' margin='15px 0 45px' resp_direction='column'>
          <DndContext
            onDragEnd={handleDragEnd}
            collisionDetection={closestCenter}
            sensors={sensors}
          >
            <SortableContext
              items={state.photos.map(item => item.id)}
              strategy={rectSortingStrategy}
            >
              {state.photos.length > 0 &&
                state.photos.map(image => {
                  return (
                    <Thumbnail
                      state={image}
                      action={{ favorite: handleFavorite, delete: handleDelete }}
                      key={image.id}
                    />
                  )
                })}
            </SortableContext>
          </DndContext>
          <UploadButton action={() => ref.current.click()} />
        </ContainerFlexResponsive>
      )}

      {currentSection === 'imagens360' && (
        <ContainerFlexResponsive gap='50px' margin='15px 0 45px' resp_direction='column'>
          <DndContext
            onDragEnd={handleDragEnd}
            collisionDetection={closestCenter}
            sensors={sensors}
          >
            <SortableContext items={imgs.map(item => item.id)} strategy={rectSortingStrategy}>
              {imgs > 0 &&
                imgs.map(image => {
                  return (
                    <Thumbnail
                      state={image}
                      action={{ favorite: handleFavorite, delete: handleDelete }}
                      key={image.id}
                    />
                  )
                })}
            </SortableContext>
          </DndContext>
          <UploadButton action={() => ref.current.click()} />
        </ContainerFlexResponsive>
      )}

      {currentSection === 'videos' && (
        <ContainerFlexResponsive justify='space-between' align='center' margin='15px 0'>
          <TextInput
            text='Link do vídeo'
            name='videoUrl'
            value={videoUrl}
            handleChange={e => setVideoUrl(e.target.value)}
            size='medium'
            clear={() => setVideoUrl('')}
          />
          {videoUpdating ? (
            <Button size='small' onClick={() => handleUpdateVideo()}>
              {videoLoading ? <ButtonLoader /> : 'Atualizar Link'}
            </Button>
          ) : (
            <Button size='small' onClick={() => handleNewVideo()}>
              {videoLoading ? <ButtonLoader /> : 'Adicionar Vídeo'}
            </Button>
          )}
        </ContainerFlexResponsive>
      )}

      {currentSection === 'videos' && state.videos.length === 0 && (
        <Description style={{ margin: '15px' }}>Nenhum vídeo cadastrado</Description>
      )}

      {currentSection === 'videos' && state.videos.length > 0 && (
        <Table>
          <thead>
            <tr>
              <th>Ordem</th>
              <th>Link</th>
              <th className='center'>Editar</th>
              <th className='center'>Excluir</th>
            </tr>
          </thead>
          <tbody>
            {state.videos.map((prop, index) => {
              return (
                <tr>
                  <td>{index + 1}</td>
                  <td onClick={() => window.open(prop.url)}>{prop.url}</td>
                  <td className='center'>
                    <AiOutlineEdit className='action' onClick={() => handleEditVideo(prop)} />
                  </td>
                  <td className='center'>
                    <IoMdClose
                      className='action'
                      onClick={() => handleDeleteVideo(prop.id, index)}
                    />
                  </td>
                </tr>
              )
            })}
          </tbody>
        </Table>
      )}

      {update && (
        <ContainerFlexResponsive justify='space-between' resp_direction='column' margin='15px 0'>
          <SecondaryButton size='small' onClick={() => dispatch({ type: 'previous' })}>
            Voltar
          </SecondaryButton>

          <Button size='small' onClick={updateProperty}>
            {loading ? <ButtonLoader /> : 'Atualizar Imóvel'}
          </Button>
        </ContainerFlexResponsive>
      )}

      <ChangesControl>
        {files.map(file => {
          return (
            <Item key={file.name}>
              <img src={URL.createObjectURL(file)} alt={file.name} />
              <p>{file.name.slice(0, 17)}</p>
              <div class='spinner'>
                <div class='double-bounce1'></div>
                <div class='double-bounce2'></div>
              </div>
            </Item>
          )
        })}

        {deleteLoading.map(file => {
          return (
            <Item>
              <img src={file.miniature.url} alt={file.id} />
              <p>Excluindo Imagem</p>
              <div class='spinner'>
                <div class='double-bounce1'></div>
                <div class='double-bounce2'></div>
              </div>
            </Item>
          )
        })}

        {orderLoading.map(() => {
          return (
            <Item>
              <AiOutlineRetweet />
              <p>Atualizando Ordem</p>
              <div class='spinner'>
                <div class='double-bounce1'></div>
                <div class='double-bounce2'></div>
              </div>
            </Item>
          )
        })}

        {favoriteLoading.map(() => {
          return (
            <Item>
              <AiOutlineStar />
              <p>Favoritando foto</p>
              <div class='spinner'>
                <div class='double-bounce1'></div>
                <div class='double-bounce2'></div>
              </div>
            </Item>
          )
        })}
      </ChangesControl>
    </>
  )
}
