import React from 'react'
import { Container, Step } from './styles'

export const Steps = ({ currentStep, list, clickable, handleClick }) => {
  return (
    <Container>
      {list.map((step, index) => (
        <Step
          status={currentStep > index ? 'done' : currentStep === index ? 'active' : 'inactive'}
          clickable={clickable}
          onClick={() => (clickable ? handleClick(index) : null)}
          key={index}
        >
          {step.name}
        </Step>
      ))}
    </Container>
  )
}
