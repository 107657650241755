import React from 'react'
import { Label } from './styles'
import { InputTextbox } from '../../index'

export const Textbox = ({ type, text, placeholder, name, disabled, value, handleChange, size }) => {
  return (
    <Label>
      <InputTextbox
        value={value}
        name={name}
        disabled={disabled}
        onChange={event => handleChange(event)}
        size={size}
        placeholder={placeholder}
        required
      />
      <span>{text}</span>
    </Label>
  )
}
