import React from 'react'
import { Card, Image, Title, IconsContainer, Icon, NoImage, SubTitle } from './styles'
import { AiFillCar } from 'react-icons/ai'
import { FaBath, FaBed } from 'react-icons/fa'
import { ContainerFlex } from '../'
import logo from '../../assets/images/logo_simple.png'
import moment from 'moment'

export const PropertyCard = ({ data }) => {
  return (
    <Card to={`/imovel/${data.id}`}>
      {data.image ? (
        <Image src={data.image} alt={data.id} />
      ) : (
        <NoImage>
          <img src={logo} alt='Sem Imagem' />
        </NoImage>
      )}
      <ContainerFlex margin='10px 20px' direction='column' gap='10px'>
        <span>Código {data.id}</span>
        <div>
          <Title>
            {data.address}, {data.number}
          </Title>
          <SubTitle>{data.district}</SubTitle>
          <SubTitle style={{ marginTop: '5px' }}>{`Expira em ${moment(data.expiration_date).format(
            'DD-MM-YY'
          )}`}</SubTitle>
        </div>
        <IconsContainer>
          <Icon>
            <FaBed />
            <p>{data.bedrooms}</p>
          </Icon>
          <Icon>
            <AiFillCar />
            <p>{data.parking_space}</p>
          </Icon>
          <Icon>
            <FaBath />
            <p>{data.bathrooms}</p>
          </Icon>
        </IconsContainer>
      </ContainerFlex>
    </Card>
  )
}
