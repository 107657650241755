import React, { useState } from 'react'
import { handleEvent, handleCheckBox, fieldErrorCheck, clearField } from '../handleFunctions'
import {
  Title,
  Description,
  ContainerFlexResponsive,
  Button,
  Checkbox,
  TextInput,
  SecondaryButton
} from '../../../components'

export const Financial = ({ state, dispatch }) => {
  const [checkError, setCheckError] = useState(false)

  let forSaleFields = ['sale_price']
  let forRentFields = ['rent_price', 'fire_insurance']
  let forSeasonFields = ['season_price', 'cleaning_fee']

  return (
    <>
      <Title>Financeiro</Title>
      <Description>
        Ative as condições disponíveis para esse imóvel para preencher os dados específicos de cada
        transação
      </Description>
      <ContainerFlexResponsive gap='50px' margin='0 0 45px' resp_direction='column'>
        <Checkbox
          text='Para Venda'
          name='for_sale'
          state={state.for_sale}
          handleChange={name => handleCheckBox(name, dispatch)}
        />
        <Checkbox
          text='Para Aluguel'
          name='for_rent'
          state={state.for_rent}
          handleChange={name => handleCheckBox(name, dispatch)}
        />
        <Checkbox
          text='Para Temporada'
          name='for_season'
          state={state.for_season}
          handleChange={name => handleCheckBox(name, dispatch)}
        />
      </ContainerFlexResponsive>

      {state.for_sale && (
        <>
          <ContainerFlexResponsive gap='50px' margin='0 0 45px' resp_direction='column'>
            <TextInput
              text='Valor de Venda'
              name='sale_price'
              type='number'
              value={state.sale_price}
              handleChange={event => handleEvent(event, dispatch)}
              size='small'
              error={checkError ? state.sale_price === '' || state.sale_price === null : false}
              clear={name => clearField(name, dispatch, 1)}
            />

            <TextInput
              text='Valor de IPTU'
              name='iptu'
              type='number'
              value={state.iptu}
              handleChange={event => handleEvent(event, dispatch)}
              error={false}
              clear={name => clearField(name, dispatch, 1)}
            />

            <Checkbox
              text='Financiável'
              name='is_financeable'
              state={state.is_financeable}
              handleChange={name => handleCheckBox(name, dispatch)}
            />
            <Checkbox
              text='Aceita Permuta'
              name='accepts_exchange'
              state={state.accepts_exchange}
              handleChange={name => handleCheckBox(name, dispatch)}
            />
          </ContainerFlexResponsive>
        </>
      )}

      {state.for_rent && (
        <>
          <ContainerFlexResponsive gap='50px' margin='0 0 45px' resp_direction='column'>
            <TextInput
              type='number'
              text='Valor de Aluguel'
              name='rent_price'
              value={state.rent_price}
              handleChange={event => handleEvent(event, dispatch)}
              size='small'
              error={checkError ? state.rent_price === '' || state.rent_price === null : false}
              clear={name => clearField(name, dispatch, 1)}
            />
            <TextInput
              type='number'
              text='Seguro Incêndio'
              name='fire_insurance'
              value={state.fire_insurance}
              handleChange={event => handleEvent(event, dispatch)}
              size='small'
              error={
                checkError ? state.fire_insurance === '' || state.fire_insurance === null : false
              }
              clear={name => clearField(name, dispatch, 1)}
            />
          </ContainerFlexResponsive>
        </>
      )}

      {state.for_season && (
        <>
          <ContainerFlexResponsive gap='50px' margin='0 0 45px' resp_direction='column'>
            <TextInput
              type='number'
              text='Valor da Diária'
              name='season_price'
              value={state.season_price}
              handleChange={event => handleEvent(event, dispatch)}
              size='small'
              error={checkError ? state.season_price === '' || state.season_price === null : false}
              clear={name => clearField(name, dispatch, 1)}
            />
            <TextInput
              type='number'
              text='Taxa de Limpeza'
              name='cleaning_fee'
              value={state.cleaning_fee}
              handleChange={event => handleEvent(event, dispatch)}
              size='small'
              error={checkError ? state.cleaning_fee === '' || state.cleaning_fee === null : false}
              clear={name => clearField(name, dispatch, 1)}
            />
          </ContainerFlexResponsive>
        </>
      )}

      <ContainerFlexResponsive margin='0 0 45px' gap='50px' resp_direction='column'>
        <Checkbox
          text='Exclusividade'
          name='is_exclusive'
          state={state.is_exclusive}
          handleChange={name => handleCheckBox(name, dispatch)}
        />
      </ContainerFlexResponsive>

      <ContainerFlexResponsive justify='space-between' resp_direction='column' resp_gap='20px'>
        <SecondaryButton size='small' onClick={() => dispatch({ type: 'previous' })}>
          Voltar
        </SecondaryButton>
        <Button
          size='small'
          onClick={() => {
            let fields = []
            if (state.for_sale) fields = [...fields, ...forSaleFields]
            if (state.for_rent) fields = [...fields, ...forRentFields]
            if (state.for_season) fields = [...fields, ...forSeasonFields]
            fieldErrorCheck(state, fields) ? setCheckError(true) : dispatch({ type: 'next' })
          }}
        >
          Próxima Etapa
        </Button>
      </ContainerFlexResponsive>
    </>
  )
}
