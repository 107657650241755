import React, { useState } from 'react'
import {
  Title,
  Description,
  ContainerFlexResponsive,
  Button,
  Checkbox,
  SecondaryButton,
  ButtonLoader
} from '../../../components'
import { AddProperty } from '../../../reducers/PropertyReducer'

export const Features = ({ state, dispatch, update }) => {
  const [loading, setLoading] = useState(false)

  const handleProperty = async () => {
    if (!loading) {
      setLoading(true)
      try {
        let { data } = await AddProperty(state)
        alert(`Imóvel ${data.property.id} cadastrado com sucesso!`)
        dispatch({ type: 'set_data', fieldName: 'id_property', payload: data.property.id })
        dispatch({ type: 'next' })
      } catch (e) {
        alert('Erro ao cadastrar imóvel!')
        console.log(e)
      }
      setLoading(false)
    }
  }

  return (
    <>
      <Title>Comodidades</Title>
      <Description>Especificação das comodidades presentes no imóvel</Description>

      <ContainerFlexResponsive gap='50px' margin='0 0 45px' resp_direction='column'>
        {state.commodities_options.map(commodity => {
          return (
            <Checkbox
              text={commodity.name}
              name={commodity.id}
              state={state.commodities.includes(commodity.id)}
              handleChange={name =>
                dispatch({
                  type: 'commodity',
                  value: !state.commodities.includes(commodity.id),
                  id: name
                })
              }
            />
          )
        })}
      </ContainerFlexResponsive>

      <ContainerFlexResponsive justify='space-between' resp_direction='column' resp_gap='20px'>
        <SecondaryButton size='small' onClick={() => dispatch({ type: 'previous' })}>
          Voltar
        </SecondaryButton>
        <Button size='small' onClick={update ? () => dispatch({ type: 'next' }) : handleProperty}>
          {update ? 'Próxima Etapa' : loading ? <ButtonLoader /> : 'Finalizar Cadastro'}
        </Button>
      </ContainerFlexResponsive>
    </>
  )
}
