import styled from 'styled-components'

export const FixedContainer = styled.div`
  position: fixed;
  overflow-y: auto;
  height: 100vh;
  width: ${props => (props.open ? '250px' : '70px')};
  background: ${props => props.theme.sidebar.background};
  border-right: 1px solid ${props => props.theme.sidebar.border};
  transition: 0.3s all;
  display: grid;
  grid-template-rows: auto 2fr auto;

  //media query
  @media (max-width: 1000px) {
    display: none;
  }
`

export const Header = styled.div`
  display: flex;
  flex-direction: ${props => (props.open ? 'row' : 'column')};
  justify-content: space-around;
  align-items: center;
  padding: 20px 10px;
  gap: ${props => (props.open ? '0px' : '20px')};
  border-bottom: 1px solid ${props => props.theme.sidebar.border};

  img {
    width: ${props => (props.open ? '150px' : '50px')};
  }

  svg {
    border: 1px solid ${props => props.theme.sidebar.button_border};
    background: ${props => props.theme.sidebar.button_background};
    border-radius: 40px;
    width: 25px;
    height: 25px;
    padding: 5px;
    cursor: pointer;
    transition: 0.3s all;

    &:hover {
      background: ${props => props.theme.sidebar.button_background_hover};
    }
  }
`

export const Navigation = styled.nav`
  margin: 10px;
  display: flex;
  flex-direction: column;
  gap: 5px;

  a {
    font-size: 13px;
    font-weight: 500;
    display: block;
    width: 100%;
    border-radius: 5px;
    padding: 5px 10px;
    color: ${props => props.theme.sidebar.link};
    display: flex;
    align-items: center;

    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
  }

  a:hover {
    background: ${props => props.theme.sidebar.link_hover};
  }

  a.active {
    background: ${props => props.theme.sidebar.link_background_active};
    color: ${props => props.theme.sidebar.link_active};
  }

  svg.icon {
    margin-right: 10px;
    color: ${props => props.theme.sidebar.icon};
  }

  svg.arrow {
    color: ${props => props.theme.sidebar.icon};
  }
`

export const LinkSubMenuContainer = styled.div`
  display: flex;
  font-size: 13px;
  font-weight: 500;
  display: block;
  width: 100%;
  border-radius: 5px;
  padding: 5px 10px;
  color: ${props => props.theme.sidebar.link};
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: space-between;
  transition: 0.3s all;

  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */

  &:hover {
    background: ${props => props.theme.sidebar.link_hover};
  }
`

export const SubMenu = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;

  a {
    padding: 5px 33px;
  }
`

export const UserContainer = styled.div`
  border-top: 1px solid ${props => props.theme.sidebar.border};
  display: flex;
  align-items: center;
  padding: 10px 10px;
  justify-content: space-evenly;

  h3 {
    font-size: 14px;
    font-weight: 500;
    color: ${props => props.theme.text.primary};
  }

  svg {
    height: 40px;
    width: 40px;
    border: 1px solid ${props => props.theme.sidebar.button_border};
    border-radius: 20px;
    padding: 7px;
    color: 1px solid ${props => props.theme.sidebar.button_border};
    fill: ${props => props.theme.sidebar.user_icon};
  }

  h3 {
    margin-bottom: 3px;
  }

  p {
    font-size: 12px;
  }

  p.disconnect {
    color: ${props => props.theme.sidebar.link_disconnect};
    margin-top: 3px;
    font-weight: 500;
    cursor: pointer;
    transition: 0.3s all;

    &:hover {
      color: ${props => props.theme.sidebar.link_disconnect_hover};
    }
  }
`

export const CollapsedNavigation = styled.nav`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin: 15px auto;

  a svg {
    height: 30px;
    width: 30px;
    padding: 5px;
    color: ${props => props.theme.sidebar.icon};
    border-radius: 5px;
    transition: 0.3s all;
  }

  a:hover svg {
    background: ${props => props.theme.sidebar.link_hover};
  }

  a.active svg {
    background: ${props => props.theme.sidebar.link_background_active};
  }
`
