import React from 'react'
import { List } from './styles'
import { ContainerFlex, BreadCrumb, Title } from '../../../components'
import { RiArrowDropRightLine } from 'react-icons/ri'

export const Updates = () => {
  return (
    <ContainerFlex margin='20px 0 25px' gap='20px' direction='column'>
      <BreadCrumb>
        <label>Atualizações do Sistema</label>
        <RiArrowDropRightLine />
      </BreadCrumb>

      <Title>Atualizações do Sistema</Title>
      <List magin='10px 0 25px' gap='20px' direction='column'>
        <p>24/12/2021</p>
        <ul>
          <li>
            Novo: Implementação de novo esquema de paginação por problemas na antiga implementação +
            Melhor facilidade de visualizar as páginas, diferente da forma antiga
          </li>
        </ul>
        <p>22/12/2021</p>
        <ul>
          <li>
            Bug: Paliativo para resolver o problema da paginação, onde ao selecionar outra página,
            essa por vezes não perfoma a mudança
          </li>
        </ul>
        <p>18/12/2021</p>
        <ul>
          <li>Back: Finalização da migração dos dados do sistema antigo</li>
        </ul>
        <p>17/12/2021</p>
        <ul>
          <li>
            Novo: Implementação de Paginação para melhorar a visualização das informações de
            clientes e imóveis em grande quantidade e evitar o travamento do navegador
          </li>
        </ul>
        <p>15/12/2021</p>
        <ul>
          <li>Novo: Utilização do logo da imobiliária em cards sem foto</li>
          <li>
            Resolvido: Sistema travando com a grande quantidade de informações de clientes numa
            mesma página
          </li>
        </ul>
        <p>12/12/2021</p>
        <ul>
          <li>Novo: Novas informações e icones nos cards de listagem de imóveis e clientes</li>
          <li>Novo: Animação dos cards quando se passa o mouse por cima</li>
        </ul>
        <p>09/12/2021</p>
        <ul>
          <li>Resolvido: Indicação de carregamento no login não estava funcionando</li>
          <li>
            Novo: Novos cards de informação de imóveis e clientes, com melhor disposição das
            informações e cores
          </li>
          <li>Resolvido: Problema nos campos de texto</li>
          <li>
            Melhoria: Adição de novas cores e organização para receber automaticamente a cor do tema
            de cada imobiliária
          </li>
        </ul>
        <p>30/10/2021</p>
        <ul>
          <li>Melhoria: Indicação de carregamento ao finalizar cadastro de imóvel e cliente</li>
          <li>Melhoria: Indicação de cadastro completo ao finalizar um cadastro</li>
          <li>
            Resolvido: Problema de carregamento duplicado das páginas de novo cliente e imóvel
          </li>
          <li>
            Resolvido: Comportamento inadequado na etapa de endereço quando estivesse editando um
            imóvel
          </li>
          <li>
            Resolvido: Ao finalizar cadastro de imóvel, código gerado não estava sendo salvo para
            enviar as imagens
          </li>
        </ul>
        <p>29/10/2021</p>
        <ul>
          <li>Novo: Novas rotas</li>
          <li>Resolvido: Cadastro do cliente não estava sendo finalizado</li>
          <li>Resolvido: Problema de renderização no cadastro de imóvel</li>
          <li>Resolvido: Erro CPF/CNPJ não estava sendo resetado ao apagar</li>
          <li>Resolvido: CNPJ não estava mostrando erro se já existisse</li>
          <li>Resolvido: Erro ao cadastrar cliente sem foto</li>
        </ul>
        <p>26/10/2021</p>
        <ul>
          <li>Novo: Páginas de gerenciamento de dados</li>
          <li>
            Resolvido: página de atualização de imóvel e cliente agora já aparece com o mapa aberto
          </li>
          <li>Resolvido: logradouro não estava sendo preenchido</li>
          <li>Novo: trava de preenchimento em todas as etapas</li>
          <li>Novo: Mensagem de erro específica para o campo</li>
        </ul>
        <p>22/10/2021</p>
        <ul>
          <li>Novo: tela de alterações para melhor controle</li>
          <li>Novo: design das tabelas com melhorias</li>
          <li>Resolvido: cadastro do imóvel não era completado</li>
          <li>Resolvido: latitude e longitude sendo salvo como inteiro</li>
          <li>Resolvido: erro na primeira página de novo imóvel</li>
          <li>Novo: verificação da validade de CPF e se já está cadastrado</li>
          <li>
            Melhoria: toda parte de gerenciamento de estados em imóveis e clientes, gerando um
            sistema bem mais rápido e mais leve, retirando redundâncias e aprimorando ainda mais o
            que existia
          </li>
        </ul>
      </List>
    </ContainerFlex>
  )
}
