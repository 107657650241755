import React from 'react'
import styled from 'styled-components'
import { AiFillStar } from 'react-icons/ai'
import { RiCloseCircleFill } from 'react-icons/ri'
import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'

const Img = styled.img`
  width: 200px;
  height: 200px;
  object-fit: cover;
  border-radius: 10px;

  @media (max-width: 500px) {
    width: 100%;
  }
`

const Container = styled.div`
  position: relative;
  width: 200px;
  height: 200px;
  cursor: grab;

  &:hover div {
    background: rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(0.7px);
  }

  &:hover div svg {
    opacity: 1;
  }

  @media (max-width: 500px) {
    width: 100%;
  }
`

const Mask = styled.div`
  transition: all 0.3s ease;
  background: rgba(0, 0, 0, 0);
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  border-radius: 10px;
  align-items: center;
  padding: 0 40px;
  display: flex;
  justify-content: space-around;
  backdrop-filter: blur(0);

  & svg {
    transition: all 0.3s ease;
    opacity: 0;
    cursor: pointer;
    width: 30px;
    height: 30px;
    color: white;
  }

  & svg:nth-child(1) {
    color: ${props => (props.favorite ? 'gold' : 'white')};
  }

  & svg:nth-child(1):hover {
    color: gold;
  }

  & svg:nth-child(2):hover {
    color: #ff7878;
  }
`

export const Thumbnail = ({ state, action }) => {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: state.id })

  const style = {
    transform: CSS.Transform.toString(transform),
    transition
  }

  return (
    <Container ref={setNodeRef} style={style} {...listeners} {...attributes}>
      <Img src={state.miniature.url} alt={state.id} />
      <Mask favorite={state.is_favorite}>
        <AiFillStar type='star' onClick={() => action.favorite(state.id)} />
        <RiCloseCircleFill type='delete' onClick={() => action.delete(state)} />
      </Mask>
    </Container>
  )
}

export const ChangesControl = styled.div`
  position: fixed;
  bottom: 30px;
  right: 30px;
  max-height: 500px;
  overflow-y: scroll;
  border-radius: 10px;
  border: 1px solid ${props => props.theme.general.border};
  background: ${props => props.theme.general.background};

  display: flex;
  flex-direction: column;
`

export const Item = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  width: 350px;
  margin: 20px;

  & p {
    font-weight: 500;
    width: 150px;
    overflow: hidden;
  }

  & img {
    width: 50px;
    height: 50px;
    border-radius: 7px;
  }

  & svg {
    width: 40px;
    height: 40px;
    background: ${props => props.theme.button.secondary_background};
    color: ${props => props.theme.button.secondary_text};
    border-radius: 7px;
    padding: 10px;
  }

  & .spinner {
    width: 20px;
    height: 20px;

    position: relative;
  }

  & .double-bounce1,
  .double-bounce2 {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: ${props => props.theme.button.secondary_text};
    opacity: 0.6;
    position: absolute;
    top: 0;
    left: 0;

    -webkit-animation: sk-bounce 2s infinite ease-in-out;
    animation: sk-bounce 2s infinite ease-in-out;
  }

  & .double-bounce2 {
    -webkit-animation-delay: -1s;
    animation-delay: -1s;
  }

  @-webkit-keyframes sk-bounce {
    0%,
    100% {
      -webkit-transform: scale(0);
    }
    50% {
      -webkit-transform: scale(1);
    }
  }

  @keyframes sk-bounce {
    0%,
    100% {
      transform: scale(0);
      -webkit-transform: scale(0);
    }
    50% {
      transform: scale(1);
      -webkit-transform: scale(1);
    }
  }
`
