import React from 'react'
import './styles.css'

export const Home = () => {
  return (
    <div className='home'>
      <h1>Bem vindo de volta...</h1>
    </div>
  )
}
