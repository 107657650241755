import API from './api'

/*
 *  Client related Functions
 */

//Client
export const addClient = state => API.post('/admin/client/register', state)

export const addClientImage = formdata =>
  API.post('/admin/client/image', formdata, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })

export const updateClientRoute = (state, id) => API.put(`/admin/client/${id}`, state)

export const getClients = params => API.get('/admin/client', { params: params })

export const getClient = id => API.get(`/admin/client/${id}`)

export const deletePhoneClient = (client_id, phone_id) =>
  API.delete(`/admin/client/${client_id}/${phone_id}`)

//Professions
export const getProfessions = () => API.get('/admin/profession')

export const postProfessions = data => API.post('/admin/profession', data)

//Marital Status
export const getMaritalStatus = () => API.get('/admin/maritalStatus')

export const postMaritalStatus = data => API.post('/admin/maritalStatus', data)

//CPF - CNPJ
export const checkClientCPFCNPJ = value => {
  return API.post('/admin/client/verifyCpfCnpj', { cpfCnpj: value })
}

/*
 *  Property related Functions
 */

//Properties
export const createProperty = state => {
  return API.post('/admin/property/create', state)
}

export const updateProperty = (id, state) => {
  return API.put(`/admin/property/${id}`, state)
}

export const getProperties = params => API.get('/admin/property/filter', { params: params })

export const getPropertyById = ID => API.get(`/admin/property/${ID}`)

export const getChangesHistory = (ID, params) =>
  API.get(`/admin/property/history/${ID}`, { params: params })

export const renewProperty = (ID, data) => API.put(`/admin/property/renew/${ID}`, data)

//Condominium
export const getCondominium = () => API.get('/admin/condominium')

export const createCondominium = state => API.post('/admin/condominium/register', state)

//Property Type
export const postPropertyType = data => API.post('/admin/propertyType', data)

export const getPropertyTypes = () => API.get('/propertyType')

//Public Area
export const postPublicArea = data => API.post('/admin/publicArea', data)

export const getPublicAreas = () => API.get('/admin/publicArea')

//Cities
export const getCities = () => {
  return API.get('/cities')
}

//Districts
export const getDistricts = cityName => {
  return API.get(`/districts/byCity?cityName=${cityName}`)
}

export const getDistrictsByID = cityID => {
  return API.get(`/districts/byCity?cityId=${cityID}`)
}

export const registerDistrict = (cityID, district) => {
  return API.post(`/admin/districts/${cityID}`, { name: district })
}

//Images
export const getImages = ID => {
  return API.get(`/image/${ID}`)
}

export const deleteImage = ID => {
  return API.delete(`/admin/image/${ID}`)
}

export const setFavorite = ID => {
  return API.put(`/admin/image/${ID}`, {
    is_favorite: true
  })
}

export const updateOrderImages = imagesID => {
  return API.put(`/admin/image/order/`, {
    images_ids: imagesID
  })
}

//Commodities
export const getCommodities = () => API.get('/admin/commodity')

export const createCommodity = state => API.post('/admin/commodity', { name: state })

export const deleteCommodity = id => API.delete(`/admin/commodity/${id}`)

export const updateCommodity = (id, state) => API.update(`/admin/commodity/${id}`)

//Season
export const getReservations = id => {
  return API.get(`/admin/property/${id}/propertiesRentals`)
}

export const makeReservation = (id, clientId, employeeId, startDate, endDate) => {
  return API.post(`/admin/property/${id}/propertiesRentals`, {
    clients_id: clientId,
    employees_id: employeeId,
    initial_date: startDate,
    final_date: endDate
  })
}

export const updateReservation = (reservationId, employeeId, startDate, endDate) => {
  return API.put(`/admin/property/propertiesRentals/${reservationId}`, {
    employees_id: employeeId,
    initial_date: startDate,
    final_date: endDate
  })
}

export const deleteReservation = reservationId => {
  return API.delete(`/admin/property/propertiesRentals/${reservationId}`)
}

export const getAllProperties = () => {
  return API.get('/properties/')
}

/*
 *  Login management
 */

//Login
export const login = (username, password) => {
  return API.post('/employee/login', { user: username, password: password })
}

/*
 *  Employees
 */

export const getEmployees = () => API.get('/admin/employee')

export const registerEmployee = payload => {
  return API.post('/admin/employee/', payload)
}

export const updateEmployee = (id, payload) => {
  return API.put(`/admin/employee/${id}`, payload)
}

export const getEmployeeChanges = (id, params) => {
  return API.get(`/admin/employee/changes/${id}`, { params: params })
}

export const sendEmail = (email, body, title) => API.post('/email/contact', { email, body, title })
