import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;

  svg {
    color: rgb(79, 79, 79);
    height: ${props => props.size};
    width: ${props => props.size};
    padding: 10px;
    background: #ebebeb;
    border-radius: 20px;
  }
`
