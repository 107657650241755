import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import axios from 'axios'
import {
  handleSelection,
  handleEvent,
  handleNumber,
  clearField,
  fieldErrorCheck
} from '../handleFunctions'
import { addNewClient, updateClient } from '../../../reducers/ClientReducer'
import {
  Title,
  Description,
  ContainerFlexResponsive,
  TextInput,
  SelectInputValue,
  Button,
  ButtonLoader
} from '../../'
import { getDistricts } from '../../../services/requests'

const cpf_cnpj_ok = state => {
  if (state.is_legal_person) return state.cnpj_complete && !state.cnpj_duplicated
  else return state.cpf_complete && !state.cpf_duplicated
}

export const ClientAddress = ({ state, dispatch, update, checkError, setCheckError }) => {
  const [cepOk, setCepOk] = useState(false)
  const [loadingCep, setLoadingCep] = useState(false)

  let history = useHistory()

  useEffect(() => {
    if (update) {
      setCepOk(true)
    }
    //eslint-disable-next-line
  }, [])

  const handleCep = async event => {
    if (cepOk) setCepOk(false)

    if (event.target.value.length < 8)
      dispatch({ type: 'set_data', fieldName: event.target.name, payload: event.target.value })

    if (event.target.value.length === 8) {
      dispatch({ type: 'set_data', fieldName: event.target.name, payload: event.target.value })

      try {
        let { data } = await axios.get(`https://viacep.com.br/ws/${event.target.value}/json/`)

        let res_districts = await getDistricts(data.localidade)
        console.log(res_districts)

        dispatch({ type: 'set_data', fieldName: 'districts', payload: res_districts.data })

        setLoadingCep(false)
        setCepOk(true)

        if (event.target.value.slice(5, 8) !== '000') {
          dispatch({ type: 'set_address', payload: data })
          dispatch({
            type: 'set_data',
            fieldName: 'address',
            payload: data.logradouro.substr(data.logradouro.indexOf(' ') + 1)
          })

          //Find public area
          let p_area = data.logradouro.substr(0, data.logradouro.indexOf(' '))
          let public_area = state.public_area_options.find(elem => elem.name === p_area)
          if (public_area !== undefined) {
            dispatch({ type: 'set_data', fieldName: 'public_areas_id', payload: public_area.id })
          }

          //Find District
          let district = res_districts.data.find(elem => elem.name === data.bairro)
          console.log(district)
          console.log(res_districts.data.find(elem => elem.name === res_districts.data.bairro))
          if (district !== undefined) {
            dispatch({ type: 'set_data', fieldName: 'district', payload: district.id })
          }
        } else {
          dispatch({ type: 'set_address', payload: data })
        }
      } catch (err) {
        console.log(err)
      }
    }
  }

  //Adding new client
  const [loading, setLoading] = useState(false)

  const addClient = async () => {
    setLoading(true)
    let res = await addNewClient(state)
    if (res.status) {
      alert('Cliente cadastrado com sucesso!')
      history.push('/clientes')
    } else {
      alert(res.error)
      setLoading(false)
    }
  }

  const update_client = async () => {
    setLoading(true)
    try {
      let res = await updateClient(state)
      if (res) {
        alert('Cliente atualizado com sucesso!')
        history.push('/clientes')
      } else {
        alert('Erro ao atualizar cliente!')
        setLoading(false)
      }
    } catch (e) {
      console.log(e)
      alert('Erro ao atualizar cliente!')
      setLoading(false)
    }
  }

  return (
    <>
      <Title>Endereço</Title>
      <Description>Informações de endereço do cliente</Description>

      <ContainerFlexResponsive gap='50px' margin='0 0 45px' resp_direction='column'>
        <TextInput
          text='CEP *'
          name='cep'
          type='text'
          size='small'
          value={state.cep}
          handleChange={handleCep}
          clear={() => {
            dispatch({ type: 'reset_address' })
            setCepOk(false)
          }}
          clearMessage='Resetar Endereço'
          loading={loadingCep}
          error={checkError ? state.cep === '' : false}
        />

        <TextInput
          text='Estado'
          name='state'
          size='small'
          value={state.state}
          disabled={!cepOk}
          clear={() => alert('Limpe o campo de CEP e adicione o CEP do estado correto')}
          error={checkError ? state.state === '' : false}
        />

        <TextInput
          text='Cidade'
          name='city'
          type='text'
          size='small'
          disabled={!cepOk}
          value={state.city}
          clear={() => alert('Limpe o campo de CEP e adicione o CEP da cidade correta')}
          error={checkError ? state.city === '' : false}
        />

        {state.districts && (
          <SelectInputValue
            text='Bairro'
            name='district'
            options={state.districts}
            type='text'
            size='small'
            disabled={!cepOk}
            value={state.district}
            handleSelection={(name, selection) => handleSelection(name, selection, dispatch)}
            clear={name => clearField(name, dispatch, 1)}
            error={checkError ? state.district === '' : false}
          />
        )}

        <SelectInputValue
          text='Logradouro'
          name='public_areas_id'
          options={state.public_area_options}
          size='small'
          value={state.public_areas_id}
          handleSelection={(name, selection) => handleSelection(name, selection, dispatch)}
          clear={name => clearField(name, dispatch, 2)}
          error={checkError ? state.public_areas_id === null : false}
        />

        <TextInput
          text='Endereço'
          name='address'
          type='text'
          size='small'
          disabled={!cepOk}
          value={state.address}
          handleChange={event => handleEvent(event, dispatch)}
          clear={name => clearField(name, dispatch, 1)}
          error={checkError ? state.address === '' : false}
        />

        <TextInput
          text='Número'
          name='number'
          type='text'
          size='small'
          value={state.number}
          handleChange={event => handleNumber(event, dispatch)}
          clear={name => clearField(name, dispatch, 1)}
          error={checkError ? state.number === '' : false}
        />

        <TextInput
          text='Complemento'
          name='complement'
          type='text'
          size='small'
          value={state.complement}
          handleChange={event => handleEvent(event, dispatch)}
          clear={name => clearField(name, dispatch, 1)}
        />
      </ContainerFlexResponsive>

      <ContainerFlexResponsive resp_direction='column'>
        <Button
          size='full'
          onClick={() => {
            let fields = [
              'cpf_cnpj',
              'rg_ie',
              'name',
              'birth_date',
              'cep',
              'district',
              'public_areas_id',
              'address',
              'number'
            ]
            if (!state.is_legal_person)
              fields = [...fields, 'gender', 'professions_id', 'marital_status_list_id']

            if (
              fieldErrorCheck(state, fields) ||
              !cpf_cnpj_ok(state) ||
              check_numbers_error(state)
            ) {
              alert('Informe corretamente todos os campos necessários!')
              setCheckError(true)
            } else update ? update_client() : addClient()
          }}
        >
          {loading ? <ButtonLoader /> : update ? 'Atualizar Cadastro' : 'Finalizar Cadastro'}
        </Button>
      </ContainerFlexResponsive>
    </>
  )
}

const check_numbers_error = state => {
  if (state.phones.length === 0) return false

  console.log(state.phones.some(elem => elem.description === null))

  return state.phones.some(elem => elem.description === null)
}
