import React, { useState, useEffect } from 'react'
import {
  ContainerFlex,
  BreadCrumb,
  Loader,
  Table,
  ClientCard,
  Title,
  Description,
  Pagination,
  ContainerGrid
} from '../../components'
import moment from 'moment'
import { useHistory } from 'react-router-dom'
import { getClients } from '../../services/requests'
import { AiOutlineEye, AiOutlinePlus } from 'react-icons/ai'
import { RiArrowDropRightLine } from 'react-icons/ri'
//import { HiSortAscending, HiSortDescending } from 'react-icons/hi'
import { IconButton } from './styles'

export const Clients = () => {
  const [data, setData] = useState(null)
  const page_items = 30
  const [page, setPage] = useState(0)
  const [total_pages, setTotalPages] = useState(0)
  //const [order, setOrder] = useState('id')
  //const [orderDirection, setOrderDirection] = useState('asc')
  const [loading, setLoading] = useState(true)
  const [grid, setGrid] = useState(true)

  let history = useHistory()

  const getInitialData = async () => {
    let params = {
      page,
      page_items
      //order,
      //order_direction: orderDirection
    }
    try {
      let promise = await getClients(params)
      setData(promise.data.clients)
      setTotalPages(promise.data.total_pages)
      setLoading(false)
    } catch (err) {
      console.log(err)
    }
  }

  const handleChangePage = async type => {
    let params_filter = {
      page_items
      //order,
      //order_direction: orderDirection
    }
    setLoading(true)

    if (type === 'next') {
      if (page !== total_pages) {
        setPage(page + 1)
        params_filter = {
          ...params_filter,
          page: page + 1
        }
      }
    } else if (type === 'prev') {
      if (page !== 0) {
        setPage(page - 1)
        params_filter = {
          ...params_filter,
          page: page - 1
        }
      }
    } else {
      setPage(type)
      params_filter = {
        ...params_filter,
        page: type
      }
    }

    try {
      let promise = await getClients(params_filter)
      setData(promise.data.clients)
      setLoading(false)
    } catch (error) {
      console.log(error)
      alert('Erro ao carregar dados')
    }
  }

  /*const handleOrder = async (type, id) => {
    setLoading(true)
    setPage(0)

    let params_filter = {
      page: 0,
      page_items
    }

    if (type === 'orderDirection') {
      let direction = orderDirection === 'asc' ? 'desc' : 'asc'
      setOrderDirection(direction)

      try {
        let promise = await getClients({
          ...params_filter,
          order,
          order_direction: direction
        })
        setData(promise.data.clients)
        setLoading(false)
      } catch (error) {
        console.log(error)
        alert('Erro ao carregar dados')
      }
    } else {
      setOrder(id)
      try {
        let promise = await getClients({
          ...params_filter,
          order: id,
          order_direction: orderDirection
        })
        setData(promise.data.clients)
        setLoading(false)
      } catch (error) {
        console.log(error)
        alert('Erro ao carregar dados')
      }
    }
  }*/

  useEffect(() => {
    getInitialData()
    // eslint-disable-next-line
  }, [])

  return (
    <>
      <ContainerFlex margin='20px 0 0' gap='10px' direction='column'>
        <BreadCrumb>
          <label>Clientes</label>
          <RiArrowDropRightLine />
        </BreadCrumb>
        <div>
          <Title>Clientes</Title>
          <Description margin_small={true}>Listagem de todos os clientes disponíveis</Description>
        </div>
      </ContainerFlex>

      <ContainerFlex margin='5px 20px 20px 0' gap='15px'>
        {/*<IconButton onClick={() => handleOrder('orderDirection')}>
          {orderDirection === 'asc' ? <HiSortDescending /> : <HiSortAscending />}
          </IconButton>*/}
        <IconButton onClick={() => setGrid(!grid)}>
          <AiOutlineEye />
        </IconButton>
        <IconButton onClick={() => history.push('/novo/cliente')}>
          <AiOutlinePlus />
        </IconButton>
      </ContainerFlex>

      <ContainerFlex gap='15px' margin='25px auto' justify='space-evenly'>
        <ContainerGrid width='100%' min='200px' gap='15px' justify='center'>
          {!loading &&
            data &&
            grid &&
            data.map(client => {
              return <ClientCard data={client} key={client.id} url={`/cliente/${client.id}`} />
            })}
        </ContainerGrid>

        {!loading && data && !grid && (
          <Table>
            <thead>
              <tr>
                <th className='center'>Código</th>
                <th>Nome</th>
                <th>Celular</th>
                <th>Email</th>
                <th>Aniversário</th>
              </tr>
            </thead>
            <tbody>
              {data.map(client => {
                return (
                  <tr key={client.id} onClick={() => history.push(`/cliente/${client.id}`)}>
                    <td className='center'>{client.id}</td>
                    <td>{client.name}</td>
                    <td>{client.whatsapp}</td>
                    <td>{client.email}</td>
                    <td>{moment(client.birth_date).format('L')}</td>
                  </tr>
                )
              })}
            </tbody>
          </Table>
        )}

        {loading && <Loader margin='20vh auto' />}
      </ContainerFlex>

      <ContainerFlex margin='20px auto' direction='column' align='center' justify='center'>
        {!loading && data && (
          <Pagination
            pageCount={total_pages}
            handleChangePage={handleChangePage}
            currentPage={page}
          />
        )}
      </ContainerFlex>
    </>
  )
}
