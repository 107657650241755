export const AuthReducer = (state, action) => {
  switch (action.type) {
    case 'set_data':
      return {
        ...state,
        [action.fieldName]: action.payload
      }

    case 'login':
      return {
        ...state,
        user: action.payload,
        id: action.payload.id,
        name: action.payload.name,
        access_levels: action.payload.access_levels_id,
        token: action.payload.token
      }

    case 'logout':
      return {
        user: null,
        id: null,
        name: null,
        access_levels: null,
        token: null
      }

    default:
      return state
  }
}
