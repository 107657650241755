import styled from 'styled-components'

export const List = styled.div`
  & p {
    font-weight: bold;
    margin-bottom: 10px;
  }

  & ul {
    margin-left: 20px;
    margin-bottom: 20px;
  }

  & ul li {
    font-size: 15px;
    margin-bottom: 5px;
  }
`
