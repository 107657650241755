import React, { useReducer, useEffect, useState } from 'react'
import { RiArrowDropRightLine } from 'react-icons/ri'
import { useParams } from 'react-router-dom'
import {
  BreadCrumb,
  BreadCrumbLabel,
  ClientAddress,
  ClientBasics,
  ClientContact,
  Loader
} from '../../components'
import { MultiStepReducer, getInitialClientData, ClientState } from '../../reducers'
import { getClient, getDistricts } from '../../services/requests'
import { helper } from './helper'
import { Main, Section } from './styles'

export const Client = () => {
  const [state, dispatch] = useReducer(MultiStepReducer, ClientState)
  const [checkError, setCheckError] = useState(false)
  let { id } = useParams()

  const getData = async () => {
    try {
      const { data } = await getClient(id)
      helper(data, dispatch)

      if (data.personalLocation.district.city.name) {
        let districts = await getDistricts(data.personalLocation.district.city.name)
        dispatch({ type: 'set_data', fieldName: 'districts', payload: districts.data })
      }

      const initialData = await getInitialClientData()
      dispatch({ type: 'store_data', payload: initialData })
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    getData()
    // eslint-disable-next-line
  }, [])

  return (
    <Main>
      <BreadCrumb>
        <BreadCrumbLabel>Cliente {id}</BreadCrumbLabel>
        <RiArrowDropRightLine />
        <BreadCrumbLabel>Edição</BreadCrumbLabel>
      </BreadCrumb>
      {state.marital_status_options ? (
        <Section>
          <ClientBasics state={state} dispatch={dispatch} update={true} checkError={checkError} />
          <ClientContact state={state} dispatch={dispatch} update={true} checkError={checkError} />
          <ClientAddress
            state={state}
            dispatch={dispatch}
            update={true}
            checkError={checkError}
            setCheckError={setCheckError}
          />
        </Section>
      ) : (
        <Loader margin='20vh auto' />
      )}
    </Main>
  )
}
