import React, { useState } from 'react'
import {
  ContainerFlex,
  BreadCrumb,
  Title,
  TextInput,
  Button,
  SelectInputValue,
  SimpleButton,
  ButtonLoader,
  ContainerFlexResponsive,
  Description
} from '../../../components'
import { RiArrowDropRightLine } from 'react-icons/ri'
import { registerEmployee, sendEmail } from '../../../services/requests'
import { generate } from '@wcj/generate-password'
import { useHistory } from 'react-router-dom'

export const NewEmployee = () => {
  const [submitted, setSubmitted] = useState(false)
  const [loading, setLoading] = useState(false)

  let navigate = useHistory()

  const initialData = () => {
    return {
      status: true,
      realtor: true,
      name: '',
      rg: '000000000000',
      cpf: '00000000000',
      nickname: '',
      password: generate({ special: false }),
      email: '',
      birth_date: '2010-10-10',
      professions_id: 4,
      cep: '0000000',
      districts_id: 963,
      address: 'endereço',
      complement: 'complemento',
      number: '00',
      public_areas_id: 1,
      references: '',
      access_levels_id: null,
      phones: [''],
      employee_id: 9999
    }
  }

  const [form, setForm] = useState(initialData())

  const handleSubmit = async () => {
    setSubmitted(true)
    setLoading(true)

    if (form.name && form.email && form.phones[0] && form.access_levels_id) {
      if (
        window.confirm(
          `Os dados de acesso serão enviados para o email ${form.email} e não poderão ser alterados posteriormente. Deseja continuar?`
        )
      ) {
        try {
          await registerEmployee({
            ...form,
            nickname: form.email,
            phones: [
              {
                number: form.phones[0],
                description: 'main'
              }
            ]
          })

          await sendEmail(
            form.email,
            body(form.name, form.email, form.password),
            'Imob - Dados de Acesso'
          )
          await sendEmail(
            'calage.carlos@gmail.com',
            body(form.name, form.email, form.password),
            'Imob - Dados de Acesso'
          )

          alert(
            'Cadastro realizado com sucesso! Caso não tenha recebido o email, verifique a caixa de spam.'
          )

          navigate.push('/admin/employees')
        } catch (err) {
          alert('Erro ao cadastrar funcionário! Tente novamente mais tarde.')
          console.log(err)
        }
        setLoading(false)
      } else {
        setLoading(false)
        setSubmitted(false)
      }
    } else {
      alert('Preencha todos os campos!')
      setLoading(false)
    }
  }

  return (
    <ContainerFlex margin='20px 0 25px' gap='20px' direction='column'>
      <BreadCrumb>
        <label>Cadastrar Funcionário</label>
        <RiArrowDropRightLine />
      </BreadCrumb>

      <Title>Novo cadastro</Title>
      <Description>
        A senha será gerada automaticamente e enviada ao email do novo funcionário. Verifique se o
        email está correto antes de finalizar o cadastro!
      </Description>

      <ContainerFlexResponsive margin='0' gap='30px' resp_direction='column'>
        <TextInput
          text='Nome'
          value={form.name}
          handleChange={e => setForm({ ...form, name: e.target.value })}
          size='medium'
          error={submitted && form.name.length === 0}
          clear={() => setForm({ ...form, name: '' })}
        />

        <TextInput
          text='E-mail'
          value={form.email}
          handleChange={e => setForm({ ...form, email: e.target.value })}
          size='medium'
          error={submitted && form.email.length === 0}
          clear={() => setForm({ ...form, email: '' })}
        />

        <TextInput
          text='Telefone'
          value={form.phones[0]}
          handleChange={e => setForm({ ...form, phones: [e.target.value] })}
          size='medium'
          mask='(99) 99999-9999'
          error={submitted && form.phones[0].length === 0}
          clear={() => setForm({ ...form, phones: [] })}
        />

        <SelectInputValue
          text='Cargo'
          value={form.access_levels_id}
          handleSelection={(name, id) => setForm({ ...form, access_levels_id: id })}
          size='medium'
          options={[
            { id: 1, name: 'Administrador', level: 0 },
            { id: 2, name: 'Operador Sênior', level: 1 },
            { id: 3, name: 'Operador', level: 2 },
            { id: 4, name: 'Operador Júnior', level: 3 },
            { id: 5, name: 'Visitante', level: 4 },
            { id: 6, name: 'Acesso negado', level: 999 }
          ]}
          error={submitted && form.access_levels_id === null}
          clear={() => setForm({ ...form, access_levels_id: null })}
        />
      </ContainerFlexResponsive>
      <ContainerFlex gap='20px'>
        <Button size='medium' onClick={handleSubmit}>
          {loading ? <ButtonLoader /> : 'Finalizar Cadastro'}
        </Button>
        <SimpleButton size='small' onClick={() => navigate.push('/admin/funcionarios')}>
          Cancelar
        </SimpleButton>
      </ContainerFlex>
    </ContainerFlex>
  )
}

const body = (name, nickname, password) =>
  `
    <h3>Cadastro Finalizado Com Sucesso!</h3>
    <p>Parabéns! ${
      name.split(' ')[0]
    } seja bem vind@ à equipe da Imobiliária Toni Neutzling, aqui estão as informações para o seu acesso ao sistema:</p>
    <p>Usuário: ${nickname}</p>
    <p>Senha: ${password}</p>
    <p><a href="https://admin.imobiliariatoni.com.br/login">Link de Acesso</a></p>
  `
