import moment from 'moment'
export const helper = (data, dispatch) => {
  dispatch({
    type: 'store_data',
    payload: {
      id: data.id,

      //Basic
      name: data.name,
      nickname: data.nickname,
      is_legal_person: data.is_legal_person,
      cpf_cnpj: data.cpf_cnpj,
      rg_ie: data.rg_ie,
      birth_date: moment(data.birth_date.substr(0, 10), 'YYYY-MM-DD').format('DD/MM/YYYY'),
      gender: data.gender,
      professions_id: data.professions_id,
      marital_status_list_id: data.marital_status_list_id,
      spouse: data.spouse,
      email: data.email,
      whatsapp: data.whatsapp,
      phones: data.phones,
      status: data.status,
      is_problematic: data.is_problematic,

      //Address
      cep: data.personalLocation.cep,
      state: data.personalLocation.district.city.state.name,
      city: data.personalLocation.district.city.name,
      district: data.personalLocation.district.id,
      address: data.personalLocation.address,
      complement: data.personalLocation.complement,
      number: data.personalLocation.number,
      public_areas_id: data.personalLocation.public_areas_id,

      references: data.references,
      photo: data.photo,
      income: data.income
    }
  })
}
