import styled from 'styled-components'
import { handleSize } from '../Utils'

export const Label = styled.label`
  position: relative;
  display: block;

  & span {
    position: absolute;
    left: 16px;
    top: 24px;
    color: ${props =>
      props.error ? props.theme.input.border_error : props.theme.input.placeholder};
    font-weight: 500;
    transition: 0.3s ease;
  }

  &:hover input {
    border: 1px solid ${props => props.theme.input.border_hover};
  }

  & input:valid + span,
  & input:focus + span {
    transform: translateY(-10px);
    font-size: 13px;
  }

  & input:focus + span {
    color: ${props => props.theme.input.border_active};
  }

  & p {
    margin: 5px 10px 0;
    color: ${props => props.theme.input.border_error};
    font-weight: 500;
    font-size: 13px;
  }

  & input {
    width: ${props => handleSize(props.size)};
    height: 65px;
    padding: 35px 40px 15px 15px;
    border-radius: 5px;
    outline: none;
    border: 1px solid
      ${props => (props.error ? props.theme.input.border_error : props.theme.input.border)};
    transition: 0.4s all;
    color: ${props => props.theme.text.primary};
    font-weight: 500;
    font-size: 14px;
    caret-color: ${props => (props.inactive ? 'white' : '')};

    &:focus {
      border: 1px solid ${props => props.theme.input.border_active};
    }
  }

  & input:disabled {
    border: 1px solid ${props => props.theme.input.border};
    background-color: white;
  }

  & svg {
    position: absolute;
    top: 22px;
    width: ${props => props.theme.input.button_width};
    height: ${props => props.theme.input.button_height};
    color: ${props => props.theme.input.button};
    right: 15px;
    cursor: pointer;
    transition: 0.3s all;
    padding: 5px;
    border-radius: 50%;

    &:hover {
      color: ${props => props.theme.input.button_hover};
      background: ${props => props.theme.input.button_background_hover};
    }
  }

  @media (max-width: 499px) {
    width: 100%;

    & input {
      width: 100%;
    }
  }
`
