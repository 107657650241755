import React, { useReducer, useEffect, useContext } from 'react'
import { RiArrowDropRightLine } from 'react-icons/ri'
import {
  BreadCrumb,
  Address,
  Features,
  Financial,
  Internal,
  Media,
  PropertyBasics,
  ContainerFlexResponsive,
  Steps,
  BreadCrumbLabel
} from '../../components'
import { AuthContext } from '../../context/AuthContext'
import { MultiStepReducer, PropertyState, getInitialPropertyData } from '../../reducers'
import { Main, Section } from './styles'

export const NewProperty = () => {
  const [state, dispatch] = useReducer(MultiStepReducer, PropertyState)
  const { auth_state } = useContext(AuthContext)

  const getData = async () => {
    try {
      const data = await getInitialPropertyData()
      dispatch({ type: 'store_data', payload: data })
    } catch (err) {
      console.log(err)
    }
  }

  const steps_list = [
    {
      name: 'Geral',
      component: <PropertyBasics state={state} dispatch={dispatch} update={false} />
    },
    { name: 'Endereço', component: <Address state={state} dispatch={dispatch} update={false} /> },
    {
      name: 'Financeiro',
      component: <Financial state={state} dispatch={dispatch} update={false} />
    },
    { name: 'Interno', component: <Internal state={state} dispatch={dispatch} update={false} /> },
    {
      name: 'Comodidades',
      component: <Features state={state} dispatch={dispatch} update={false} />
    },
    { name: 'Mídia', component: <Media state={state} dispatch={dispatch} update={false} /> }
  ]

  useEffect(() => {
    getData()
    dispatch({ type: 'set_data', fieldName: 'employee_id', payload: auth_state.id })
    // eslint-disable-next-line
  }, [])

  const { currentStep } = state

  return (
    <Main>
      <ContainerFlexResponsive
        align='center'
        justify='space-between'
        margin='0 0 20px'
        resp_direction='column'
      >
        <BreadCrumb>
          <BreadCrumbLabel>Novo Imóvel</BreadCrumbLabel>
          <RiArrowDropRightLine />
        </BreadCrumb>
        <Steps currentStep={currentStep} list={steps_list} />
      </ContainerFlexResponsive>

      <Section>{steps_list[currentStep].component}</Section>
    </Main>
  )
}
