import React, { useState, useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { RiSearch2Line } from 'react-icons/ri'
import {
  MenuContainer,
  SearchContainer,
  SearchInput,
  ResultsContainer,
  Type,
  TypeOption,
  ResultsData,
  Option,
  OptionNoPhoto,
  MobileContainer,
  MobileMenu
} from './styles'
import { AiOutlineHome, AiOutlineMenu } from 'react-icons/ai'
import { BsPerson } from 'react-icons/bs'
import { IoCloseOutline } from 'react-icons/io5'
import { Nav } from '../'
import { DataContext } from '../../context/DataContext'
import Logo from '../../assets/images/logo.png'

export const Header = () => {
  const [searchInput, setSearchInput] = useState('')
  const [type, setType] = useState('properties')
  const [element, setElement] = useState(null)
  const { data_state } = useContext(DataContext)
  let history = useHistory()

  //mobile menu
  const [menuOpen, setMenuOpen] = useState(false)
  const [searchOpen, setSearchOpen] = useState(false)

  const handleClick = () => {
    setMenuOpen(false)
    setSearchOpen(false)
    setSearchInput('')
  }

  const handleSearch = event => {
    setSearchInput(event.target.value)

    if (event.target.value === '') {
      if (element) document.removeEventListener('click', handleClickOutside)
    } else {
      document.addEventListener('click', handleClickOutside)
    }
  }

  const handleClickOutside = e => {
    if (!element.contains(e.target)) {
      setElement(null)
      setSearchInput('')
      document.removeEventListener('click', handleClickOutside)
    }
  }

  return (
    <MenuContainer ref={node => setElement(node)}>
      <SearchContainer searching={searchInput.length !== 0} open={searchOpen}>
        <SearchInput
          placeholder='Busque por imóveis ou clientes'
          value={searchInput}
          onChange={handleSearch}
        />
        {searchInput.length !== 0 && (
          <Results
            type={type}
            setType={setType}
            searchInput={searchInput}
            history={history}
            data_state={data_state}
            handleClick={handleClick}
          />
        )}
        {searchInput.length !== 0 && (
          <IoCloseOutline id='close' onClick={() => setSearchInput('')} />
        )}
        <RiSearch2Line />
      </SearchContainer>
      <Mobile
        searchOpen={searchOpen}
        setSearchOpen={setSearchOpen}
        menuOpen={menuOpen}
        setMenuOpen={setMenuOpen}
        handleClick={handleClick}
      />
    </MenuContainer>
  )
}

const propertyFilter = (prop, state) => {
  let address = `${prop.address.toLowerCase()}, ${prop.number}}`
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')

  if (prop.id === Number(state)) return prop

  if (
    address.includes(
      state
        .toLowerCase()
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
    )
  )
    return prop

  if (
    prop.client
      .toLowerCase()
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .includes(
        state
          .toLowerCase()
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
      )
  )
    return prop

  return false
}

const clientFilter = (client, state) => {
  if (client.id === Number(state)) return client

  if (
    client.name
      .toLowerCase()
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .includes(
        state
          .toLowerCase()
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
      )
  )
    return client

  //let cpf_cnpj = client.cpf_cnpj.replaceAll('.', '').replaceAll('-', '')
  //if (cpf_cnpj.includes(state)) return client

  return false
}

const Results = ({ type, setType, searchInput, history, data_state, handleClick }) => {
  return (
    <ResultsContainer>
      <Type>
        <TypeOption active={type === 'properties'} onClick={() => setType('properties')}>
          <AiOutlineHome />
          <p>Imóveis</p>
        </TypeOption>
        <TypeOption active={type === 'clients'} onClick={() => setType('clients')}>
          <BsPerson />
          <p>Clientes</p>
        </TypeOption>
      </Type>
      <ResultsData>
        {type === 'properties'
          ? data_state.properties &&
            data_state.properties
              .filter(prop => {
                return propertyFilter(prop, searchInput)
              })
              .map(prop => {
                return (
                  <Option to={`/imovel/${prop.id}`} onClick={handleClick}>
                    {prop.image ? <img src={prop.image} alt='' /> : <OptionNoPhoto />}
                    <div>
                      <h1>
                        {prop.address}, {prop.number}
                      </h1>
                      <p>
                        Código: {prop.id} | Cliente: {prop.client}
                      </p>
                    </div>
                  </Option>
                )
              })
          : data_state.clients &&
            data_state.clients
              .filter(client => {
                return clientFilter(client, searchInput)
              })
              .map(client => {
                return (
                  <Option to={`/cliente/${client.id}`} onClick={handleClick}>
                    {client.photo ? <img src={client.photo} alt='' /> : <OptionNoPhoto />}
                    <div>
                      <h1>{client.name}</h1>
                      <p>Código {client.id}</p>
                    </div>
                  </Option>
                )
              })}
      </ResultsData>
    </ResultsContainer>
  )
}

const Mobile = ({ searchOpen, setSearchOpen, menuOpen, setMenuOpen, handleClick }) => {
  const handleMenuOpen = () => {
    setMenuOpen(!menuOpen)
    setSearchOpen(false)
  }

  const handleSearchOpen = () => {
    setSearchOpen(!searchOpen)
    setMenuOpen(false)
  }

  return (
    <MobileContainer>
      {menuOpen ? (
        <IoCloseOutline onClick={handleMenuOpen} />
      ) : (
        <AiOutlineMenu onClick={handleMenuOpen} />
      )}

      <img src={Logo} alt='Logo' />

      {searchOpen ? (
        <IoCloseOutline onClick={handleSearchOpen} />
      ) : (
        <RiSearch2Line onClick={handleSearchOpen} />
      )}

      {menuOpen && (
        <MobileMenu>
          <Nav handleClick={handleClick} />
        </MobileMenu>
      )}
    </MobileContainer>
  )
}
