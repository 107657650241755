import React from 'react'
import { Routes } from './Routes'
import { AuthContextProvider } from './context/AuthContext'
import { DataContextProvider } from './context/DataContext'
import GlobalStyle, { default_theme } from './assets/styles/global.js'
import { ThemeProvider } from 'styled-components'

function App() {
  return (
    <div className='App'>
      <AuthContextProvider>
        <DataContextProvider>
          <ThemeProvider theme={default_theme}>
            <GlobalStyle />
            <Routes />
          </ThemeProvider>
        </DataContextProvider>
      </AuthContextProvider>
    </div>
  )
}

export default App
