import styled from 'styled-components'
import { Link } from 'react-router-dom'

export const LinkCard = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: column;
  height: 170px;
  width: 170px;
  cursor: pointer;
  border-radius: 20px;
  transition: all 0.4s;
  border: 1px solid ${props => props.theme.cards.border};
  color: ${props => props.theme.cards.text};
  font-size: 14px;
  font-weight: 500;

  & svg {
    height: 50px;
    width: 50px;
    border-radius: 50%;
    padding: 15px;
    color: ${props => props.theme.cards.colors[props.index].icon};
    background: ${props => props.theme.cards.colors[props.index].background};
  }

  &:hover {
    border: 1px solid ${props => props.theme.general.border_hover};
    color: ${props => props.theme.cards.text_hover};
  }

  @media (max-width: 500px) {
    width: 100%;
  }
`
