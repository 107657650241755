import React from 'react'
import { useParams } from 'react-router'
import { RiArrowDropRightLine } from 'react-icons/ri'
import { AiOutlineEdit } from 'react-icons/ai'
import { AiOutlineHistory } from 'react-icons/ai'
import { Main } from './styles'
import {
  BreadCrumb,
  Description,
  ContainerFlexResponsive,
  LinkCard,
  BreadCrumbLabel
} from '../../../components'

export const Employee = props => {
  let { id } = useParams()

  const options_list = [
    { name: 'Editar', icon: <AiOutlineEdit />, link: `/admin/funcionario/${id}/editar/` },
    {
      name: 'Alterações',
      icon: <AiOutlineHistory />,
      link: `/admin/funcionario/${id}/alteracoes/`
    }
  ]

  return (
    <Main>
      <BreadCrumb>
        <BreadCrumbLabel>Funcionário</BreadCrumbLabel>
        <RiArrowDropRightLine />
        <BreadCrumbLabel>{id}</BreadCrumbLabel>
      </BreadCrumb>

      <Description>Selecione a opção desejada abaixo</Description>

      <ContainerFlexResponsive gap='20px' resp_align='center' resp_direction='column'>
        {options_list.map((option, index) => (
          <LinkCard index={index} to={option.link} className='no-select'>
            {option.icon}
            {option.name}
          </LinkCard>
        ))}
      </ContainerFlexResponsive>
    </Main>
  )
}
