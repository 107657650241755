import React, { useEffect, useState, useRef } from 'react'
import axios from 'axios'
/* import { Marker } from '@react-google-maps/api' */
import { Marker } from 'react-leaflet'
import {
  handleSelection,
  handleEvent,
  handleNumber,
  fieldErrorCheck,
  clearField
} from '../handleFunctions'
import {
  Title,
  Description,
  ContainerFlexResponsive,
  TextInput,
  SelectInputValue,
  Button,
  SecondaryButton,
  Maps
} from '../../'
import { getDistricts } from '../../../services/requests'

export const Address = ({ state, dispatch, update }) => {
  const [cepOk, setCepOk] = useState(false)
  const [showMap, setShowMap] = useState(false)
  const [checkError, setCheckError] = useState(false)
  const [loadingCep, setLoadingCep] = useState(false)
  const markerRef = useRef(null)

  useEffect(() => {
    if (update) {
      setCepOk(true)
      setShowMap(true)
    }
    //eslint-disable-next-line
  }, [])

  const handleCep = async event => {
    if (cepOk) setCepOk(false)

    if (event.target.value.length < 8)
      dispatch({ type: 'set_data', fieldName: event.target.name, payload: event.target.value })

    if (event.target.value.length === 8) {
      dispatch({ type: 'set_data', fieldName: event.target.name, payload: event.target.value })
      setLoadingCep(true)
      try {
        //Get address info
        let res_address = await axios.get(`https://viacep.com.br/ws/${event.target.value}/json/`)
        //Get districts
        let res_districts = await getDistricts(res_address.data.localidade)
        dispatch({ type: 'set_data', fieldName: 'districts', payload: res_districts.data })
        setLoadingCep(false)
        setCepOk(true)

        if (event.target.value.slice(5, 8) !== '000') {
          dispatch({ type: 'set_address', payload: res_address.data })
          dispatch({
            type: 'set_data',
            fieldName: 'address',
            payload: res_address.data.logradouro.substr(
              res_address.data.logradouro.indexOf(' ') + 1
            )
          })

          //Find District
          let district = res_districts.data.find(elem => elem.name === res_address.data.bairro)
          if (district !== undefined) {
            dispatch({ type: 'set_data', fieldName: 'district_id', payload: district.id })
          }

          //Find public area
          let p_area = res_address.data.logradouro.substr(
            0,
            res_address.data.logradouro.indexOf(' ')
          )
          let public_area = state.public_area_options.find(elem => elem.name === p_area)
          if (public_area !== undefined) {
            dispatch({ type: 'set_data', fieldName: 'public_areas_id', payload: public_area.id })
          }
        } else {
          dispatch({ type: 'set_address', payload: res_address.data })
        }
      } catch (err) {
        alert('Erro ao encontrar o CEP')
        console.log(err)
      }
    }
  }

  const getLocation = () => {
    if (state.address !== '' && state.number !== '' && state.city !== '' && state.state !== '') {
      axios
        .get(
          /* `https://maps.googleapis.com/maps/api/geocode/json?address=${state.address},${state.number},${state.city},${state.state}&key=AIzaSyBWthUiYc1jOfGwHGV5_wREzwdFLfokAgk` */
          `https://geocode.maps.co/search?street=${state.number} ${state.address}&city=${state.city}&state=${state.state}&country=BR`
        )
        .then(({ data }) => {
          console.log(data)
          dispatch({
            type: 'set_data',
            fieldName: 'latitude',
            payload: data[0].lat
          })
          dispatch({
            type: 'set_data',
            fieldName: 'longitude',
            payload: data[0].lon
          })
        })
    } else alert('Preencha todos os campos primeiro!')
  }

  const eventHandlers = {
    dragend() {
      const marker = markerRef.current
      if (marker != null) {
        dispatch({
          type: 'set_data',
          fieldName: 'latitude',
          payload: marker._latlng.lat
        })
        dispatch({
          type: 'set_data',
          fieldName: 'longitude',
          payload: marker._latlng.lng
        })
      }
    }
  }

  return (
    <>
      <Title>Endereço</Title>
      <Description>
        Após preencher todos os dados necessários para identificação básica do imóvel, selecione a
        opção "Encontrar Coordenadas Automaticamente". Caso queria digitar manualmente, selecione a
        opção "Digitar Coordenadas"
      </Description>

      <ContainerFlexResponsive gap='50px' margin='0 0 45px' resp_direction='column'>
        <TextInput
          text='CEP *'
          name='cep'
          type='text'
          size='small'
          value={state.cep}
          handleChange={handleCep}
          clear={() => {
            dispatch({ type: 'reset_address' })
            setCepOk(false)
          }}
          error={checkError ? state.cep === '' : false}
          loading={loadingCep}
        />

        <TextInput
          text='Estado *'
          name='state'
          size='small'
          value={state.state}
          disabled={!cepOk}
          clear={() => alert('Limpe o campo de CEP e adicione o CEP do estado correto')}
        />

        <TextInput
          text='Cidade *'
          name='city'
          type='text'
          size='small'
          disabled={!cepOk}
          value={state.city}
          clear={() => alert('Limpe o campo de CEP e adicione o CEP da cidade correta')}
        />
        {state.districts && (
          <SelectInputValue
            text='Bairro *'
            name='district_id'
            size='small'
            value={state.district_id}
            options={state.districts}
            handleSelection={(name, selection) => handleSelection(name, selection, dispatch)}
            error={checkError ? state._id === null : false}
            clear={name => clearField(name, dispatch, 2)}
          />
        )}

        <SelectInputValue
          text='Logradouro *'
          name='public_areas_id'
          options={state.public_area_options}
          size='small'
          value={state.public_areas_id}
          handleSelection={(name, selection) => handleSelection(name, selection, dispatch)}
          error={checkError ? state.public_areas_id === null : false}
          clear={name => clearField(name, dispatch, 2)}
        />

        <TextInput
          text='Endereço *'
          name='address'
          type='text'
          size='small'
          disabled={!cepOk}
          value={state.address}
          handleChange={event => handleEvent(event, dispatch)}
          error={checkError ? state.address === '' : false}
          clear={name => clearField(name, dispatch, 1)}
        />

        <TextInput
          text='Número *'
          name='number'
          type='text'
          size='small'
          value={state.number}
          handleChange={event => handleNumber(event, dispatch)}
          error={checkError ? state.number === '' : false}
          clear={name => clearField(name, dispatch, 1)}
        />

        <TextInput
          text='Complemento'
          name='complement'
          type='text'
          size='small'
          value={state.complement}
          handleChange={event => handleEvent(event, dispatch)}
          clear={name => clearField(name, dispatch, 1)}
        />
      </ContainerFlexResponsive>

      <ContainerFlexResponsive
        justify='space-between'
        margin='0 0 45px'
        resp_direction='column'
        resp_gap='20px'
      >
        <SecondaryButton size='medium' onClick={() => setShowMap(true)}>
          Digitar Coordenadas
        </SecondaryButton>
        <Button size='medium' onClick={getLocation}>
          Encontrar Coordenadas Automaticamente
        </Button>
      </ContainerFlexResponsive>

      {(showMap || state.latitude) && (
        <>
          <ContainerFlexResponsive gap='50px' align='center' resp_direction='column'>
            <TextInput
              text='Latitude *'
              name='latitude'
              type='text'
              size='small'
              value={state.latitude}
              handleChange={event => handleEvent(event, dispatch)}
              clear={name => clearField(name, dispatch, 1)}
            />

            <TextInput
              text='Longitude *'
              name='longitude'
              type='text'
              size='small'
              value={state.longitude}
              handleChange={event => handleEvent(event, dispatch)}
              clear={name => clearField(name, dispatch, 1)}
            />
          </ContainerFlexResponsive>

          <ContainerFlexResponsive gap='50px' margin='40px 0 45px' resp_direction='column'>
            <Maps
              position={
                state.latitude && state.longitude
                  ? { lat: parseFloat(state.latitude), lng: parseFloat(state.longitude) }
                  : null
              }
            >
              {state.latitude && state.longitude ? (
                <Marker
                  position={{ lat: parseFloat(state.latitude), lng: parseFloat(state.longitude) }}
                  draggable={true}
                  ref={markerRef}
                  eventHandlers={eventHandlers}
                />
              ) : null}
            </Maps>
          </ContainerFlexResponsive>
        </>
      )}

      <ContainerFlexResponsive justify='space-between' resp_direction='column' resp_gap='20px'>
        <SecondaryButton size='small' onClick={() => dispatch({ type: 'previous' })}>
          Voltar
        </SecondaryButton>

        <Button
          size='small'
          onClick={() =>
            fieldErrorCheck(state, [
              'cep',
              'district_id',
              'public_areas_id',
              'address',
              'number',
              'latitude',
              'longitude'
            ])
              ? setCheckError(true)
              : dispatch({ type: 'next' })
          }
        >
          Próxima Etapa
        </Button>
      </ContainerFlexResponsive>
    </>
  )
}
