import React from 'react'
/* import { GoogleMap, LoadScript } from '@react-google-maps/api' */
import { MapContainer, TileLayer } from 'react-leaflet'

const containerStyle = {
  width: '100%',
  height: '500px'
}

const center = {
  lat: -31.366708,
  lng: -51.97159
}

/* export const Maps = ({ position, children, zoom, style }) => {
  return (
    <LoadScript googleMapsApiKey='AIzaSyBWthUiYc1jOfGwHGV5_wREzwdFLfokAgk'>
      <GoogleMap
        mapContainerStyle={style ? style : containerStyle}
        center={position ? position : center}
        zoom={zoom ? zoom : 14}
      >
        {children}
      </GoogleMap>
    </LoadScript>
  )
} */

export const Maps = ({ position, children, zoom, style }) => {
  return (
    <MapContainer
      center={position ? position : center}
      zoom={zoom ? zoom : 14}
      scrollWheelZoom={true}
      style={style ? style : containerStyle}
    >
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
      />
      {children}
    </MapContainer>
  )
}
